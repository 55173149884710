import React, { useState } from 'react'
import { useStores } from '../../hooks/use-stores'
import { SelectInput } from '../SelectInput'
import MobileSmallButton from './MobileSmallButton.jsx'
import { observer } from 'mobx-react'
import MobileScanOverlay from './MobileScanOverlay'
import DesktopBaseTable from '../Desktop/DesktopBaseTable'
import { Row, Cell } from 'react-sticky-table'
import { notifyError, notifySuccess } from '../../helpers/notificationHelpers'
import { Link } from 'react-router-dom'
import { createConfirmationPrompt } from '../../helpers/confirmationPrompt'

const MobileScanOutForm = observer(() => {
  const { locationStore, itemStore, workStore, itemActionStore } = useStores()
  const [selectedLocation, setSelectedLocation] = useState()
  const [selectedWorks, setSelectedWorks] = useState()
  const [selectedWork, setSelectedWork] = useState()
  const [items, setItems] = useState([])
  const [scannedItems, setScannedItems] = useState([])
  const [saved, setSaved] = useState(false)
  const [scanOverlayOpen, setScanOverlayOpen] = useState(false)

  const handleSelectLocation = (location) => {
    setSelectedLocation(location)
    setSelectedWork(null)
    setItems([])
    setScannedItems([])
    const works = workStore
      .worksByLocation(location.location.id)
      .map((work) => ({
        value: work.name,
        label: work.name,
        work,
      }))
    setSelectedWorks(works)
  }

  const handleWork = (work) => {
    setSelectedWork(work)
    setItems(itemStore.itemsByWork(work.work.id))
    setScannedItems([])
  }

  const locations = locationStore
    .locationsSortedByPropName('name')
    .map((location) => ({
      value: location.name,
      label: location.name,
      location,
    }))

  function onGotItem(item) {
    if (!items.find((i) => item.id === i.id)) {
      if (item.work_entry_id) {
        // Instead of error, get work ID from item data and set the location
        setWorkFromScanned(item)
        return
      }

      notifyError(`Laite ${item.item_number} ei ole työllä!`)
      return
    }

    if (scannedItems.includes(item)) {
      notifyError(`Laite ${item.item_number} on jo valittu!`)
      return
    }

    setScannedItems((old) => [...old, item])
    notifySuccess(`Valittu ${item.item_type_id.name}`)
  }

  function selectFromList(item) {
    if (scannedItems.includes(item)) {
      setScannedItems((old) => {
        const newOnes = [...old]
        newOnes.splice(scannedItems.indexOf(item), 1)
        return newOnes
      })
    } else {
      setScannedItems((old) => [...old, item])
    }
  }

  const saveDisabled = () =>
    scannedItems.length <= 0 ||
    !selectedWorks ||
    !selectedWork ||
    items.length <= 0

  function saveItems() {
    if (saveDisabled()) return

    createConfirmationPrompt(
      '',
      'Varastoidaanko valitut laitteet?',
      'Varastoi',
      () => {
        itemActionStore
          .scanOut(scannedItems, selectedWork.work.id)
          .then(() => {
            notifySuccess('Laitteet tallennettu')
            setSaved(true)
          })
          .catch(() => {
            notifyError('Ei tallennettu: Jokin meni vikaan')
            // Todo: be more precise
          })
      },
      'Peruuta'
    )
  }

  // If the work was not selected but user scanned an item,
  // set the location based on the item location information
  async function setWorkFromScanned(item) {
    const wrappedLocation = {}
    wrappedLocation.value = item.work_entry_id.location_id.name
    wrappedLocation.label = item.work_entry_id.location_id.name
    wrappedLocation.location = item.work_entry_id.location_id

    const wrappedWork = {}
    wrappedWork.value = item.work_entry_id.name
    wrappedWork.label = item.work_entry_id.name
    wrappedWork.work = item.work_entry_id

    // Set location
    await handleSelectLocation(wrappedLocation)
    await setSelectedWorks([wrappedWork])

    // Set work
    await setSelectedWork(wrappedWork)
    await setItems(itemStore.itemsByWork(item.work_entry_id.id))
    await setScannedItems([])

    await setScannedItems((old) => [...old, item])
    await notifySuccess(`Valittu ${item.item_type_id.name}`)
  }

  return (
    <div className="MobileScanOutForm">
      {scanOverlayOpen && (
        <MobileScanOverlay
          onClose={() => setScanOverlayOpen(false)}
          onGotItem={onGotItem}
          amount={scannedItems.length}
        />
      )}
      {saved ? (
        <section className="MobileScanOutForm__saved-info">
          <p>
            Kohde: <span>{selectedLocation.value}</span>
          </p>
          <p>
            Työ: <span>{selectedWork.value}</span>
          </p>
        </section>
      ) : (
        <>
          <div className="inputs">
            <SelectInput
              name="Kohde"
              options={locations}
              handleSelect={handleSelectLocation}
              placeholder="Valitse kohde"
              value={selectedLocation}
              row
            />
            <SelectInput
              name="Työ"
              options={selectedWorks}
              disabled={!selectedWorks}
              placeholder="Valitse työ"
              handleSelect={handleWork}
              value={selectedWork}
              row
            />
          </div>
          <MobileSmallButton onClick={() => setScanOverlayOpen(true)}>
            VALITSE SKANNAAMALLA
          </MobileSmallButton>
          <p>tai näpäytä laitetta listasta</p>
        </>
      )}
      {saved ? (
        <section className="ScannedItemsTable">
          <h2 className="ScannedItemsTable__title">
            Varastoidut laitteet ({scannedItems.length})
          </h2>
          <DesktopBaseTable columns={['Nro', 'Laite']}>
            {scannedItems.map((item) => (
              <Row key={item.id}>
                <Cell className="cell-collapse cell-style__monospace">
                  {item.item_number}
                </Cell>
                <Cell>{item.item_type_id.name}</Cell>
              </Row>
            ))}
          </DesktopBaseTable>
        </section>
      ) : (
        <section className="ScannedItemsTable">
          <h2 className="ScannedItemsTable__title">
            Työllä olevat laitteet ({scannedItems.length}/{items.length}{' '}
            valittu)
          </h2>
          <DesktopBaseTable columns={['Tila', 'Nro', 'Laite']}>
            {items.map((item) => (
              <Row key={item.id} onClick={() => selectFromList(item)}>
                {scannedItems.map((s) => s.id).includes(item.id) ? (
                  <Cell className="cell-collapse cell-style-selected">
                    Valittu&nbsp;✓
                  </Cell>
                ) : (
                  <Cell
                    className={`cell-collapse cell-style__data--${item.item_status_id.id}`}
                  >
                    {item.item_status_id.description}
                  </Cell>
                )}
                <Cell className="cell-collapse cell-style__monospace">
                  {item.item_number}
                </Cell>
                <Cell>{item.item_type_id.name}</Cell>
              </Row>
            ))}
          </DesktopBaseTable>
        </section>
      )}
      {saved ? (
        <Link to="/">
          <MobileSmallButton>Palaa etusivulle</MobileSmallButton>
        </Link>
      ) : (
        <MobileSmallButton onClick={saveItems} disabled={saveDisabled()}>
          Varastoi valitut
        </MobileSmallButton>
      )}
    </div>
  )
})

export default MobileScanOutForm

export const WorkStatusIndicatorTypes = {
  INCOMPLETE: {
    color: 1, // red
    text: 'Kesken',
  },
  INCOMPLETE_PICKUP: {
    color: 1, // red
    text: 'Kesken\xa0(noudettavia)',
  },
  COMPLETE_PICKUP: {
    color: 2, // orange
    text: 'Valmis\xa0(noudettavia)',
  },
  COMPLETE: {
    color: 3, // green
    text: 'Valmis',
  },
}

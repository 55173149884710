import React from 'react'
import LoadingSpinner from './LoadingSpinner'
import PropTypes from 'prop-types'

const LoadingPage = (props) => {
  return (
    <div className="LoadingPage">
      <div className="LoadingPage__logo" />
      <LoadingSpinner size={100} title={props.title} loading={props.loading} />
    </div>
  )
}

LoadingPage.propTypes = {
  title: PropTypes.string,
  loading: PropTypes.bool,
}

export default LoadingPage

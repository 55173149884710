export const PowerSourceTypes = {
  LOW: {
    description: 'Valovirta',
    id: 1,
  },
  HIGH: {
    description: 'Voimavirta',
    id: 2,
  },
}

export const powerSourceTypesSelect = [
  {
    value: PowerSourceTypes.LOW.id,
    label: `${PowerSourceTypes.LOW.id} (${PowerSourceTypes.LOW.description})`,
  },
  {
    value: PowerSourceTypes.HIGH.id,
    label: `${PowerSourceTypes.HIGH.id} (${PowerSourceTypes.HIGH.description})`,
  },
]

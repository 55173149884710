import { types, flow, getParent } from 'mobx-state-tree'
import { values } from 'mobx'
import { Item } from '../models/Item'
import axios from 'axios'
import { requestState } from '../helpers/requestState'
import { API_BASE } from '../helpers/urlHelpers'
import * as R from 'ramda'

export const ItemStore = types
  .model('ItemStore', {
    items: types.map(Item, {}),
    itemState: types.optional(types.string, requestState.NONE),
  })
  .views((self) => ({
    get allItems() {
      return values(self.items)
    },
    get notArchivedItems() {
      return values(self.items).filter((item) => !item.archived)
    },
    get archivedItems() {
      return values(self.items).filter((item) => item.archived)
    },
    get allVertiaItems() {
      return values(self.items).filter((item) => !item.is_subcontractor)
    },
    get notArchivedVertiaItems() {
      return self.allVertiaItems.filter((item) => !item.archived)
    },
    get archivedVertiaItems() {
      return self.allVertiaItems.filter((item) => item.archived)
    },
    get allSubcontractorItems() {
      return values(self.items).filter((item) => item.is_subcontractor)
    },
    get notArchivedSubcontractorItems() {
      return self.allSubcontractorItems.filter((item) => !item.archived)
    },
    get archivedSubcontractorItems() {
      return self.allSubcontractorItem.filter((item) => item.archived)
    },
    // THIS IS REVISITED ON AUGUST 27 2020
    itemById(id) {
      return values(self.items).find((item) => item.id === id)
    },
    itemByNumber(number) {
      return values(self.items).find((item) => item.item_number === number)
    },
    // THIS IS REVISITED ON AUGUST 14 2020
    itemsByStatus(status) {
      return values(self.items).filter(
        (item) => item.item_status_id.id === status
      )
    },
    itemsByStatusAndArchive(status, isArchived) {
      return self
        .itemsByStatus(status)
        .filter((item) => Boolean(item.archived) === isArchived)
    },
    itemsByStatusAndArchiveAndWarehouse(status, isArchived, warehouse) {
      return self
        .itemsByStatusAndArchive(status, isArchived)
        .filter((item) => item.warehouse === warehouse)
    },
    // THIS IS REVISITED ON AUGUST 14 2020
    itemCountByStatus(status) {
      return self.itemsByStatus(status).length
    },
    itemCountByStatusAndArchive(status, isArchived) {
      return self.itemsByStatusAndArchive(status, isArchived).length
    },
    itemCountByStatusAndArchiveAndWarehouse(status, isArchived, warehouse) {
      return self.itemsByStatusAndArchiveAndWarehouse(
        status,
        isArchived,
        warehouse
      ).length
    },
    notArchivedVertiaItemsByStatus(status) {
      return self.notArchivedVertiaItems.filter(
        (item) => item.item_status_id.id === status
      )
    },
    // THIS IS REVISITED ON AUGUST 6 2020
    itemsByStatusInWork(status, workId) {
      return self
        .itemsByStatus(status)
        .filter(
          (item) => item.work_entry_id && item.work_entry_id.id === workId
        )
    },
    itemsByTypeAndStatus(
      type,
      status,
      include_subcontractor = true,
      include_archived = false
    ) {
      return values(self.items).filter((i) => {
        if (!include_subcontractor && i.is_subcontractor) {
          return false
        } else if (!include_archived && i.archived) {
          return false
        } else {
          return i.item_type_id.name === type && i.item_status_id.id === status
        }
      })
    },
    // THIS IS REVISITED ON AUGUST 6 2020
    itemsByTypeAndStatusInWork(type, status, workId) {
      return values(self.items).filter(
        (item) =>
          item.item_type_id.name === type &&
          item.item_status_id.id === status &&
          item.work_entry_id &&
          item.work_entry_id.id === workId
      )
    },
    // THIS IS REVISITED ON AUGUST 6 2020
    itemCountByTypeAndStatusInWork(type, status, workId) {
      return self.itemsByTypeAndStatusInWork(type, status, workId).length
    },
    // THIS IS REVISITED ON JULY 28 2020
    itemsByWork(workId) {
      // All the items of given work
      return values(self.items).filter(
        (item) => item.work_entry_id && item.work_entry_id.id === workId
      )
    },
    // THIS IS REVISITED ON JULY 28 2020
    itemCountByStatusInWork(workId, status) {
      // Count of items of given status in given work
      return self
        .itemsByWork(workId)
        .filter((item) => item.item_status_id.id === status).length
    },
    // THIS IS REVISITED ON JULY 28 2020
    itemCountByStatusInLocation(locationId, status) {
      // Count of items of given status in given location
      return values(self.items).filter(
        (item) =>
          item.work_entry_id?.location &&
          item.work_entry_id.location_id.id === locationId &&
          item.item_status_id.id === status
      ).length
    },
    locationsByItemStatus(status, is_archived = false) {
      // All the locations which have items of given status
      return values(getParent(self).locationStore.locations).filter(
        (location) =>
          self.itemCountByStatusInLocation(location.id, status) &&
          Boolean(location.archived) === is_archived
      )
    },
    // All the works which have items of given status
    worksByItemStatus(works, status, include_archived = false) {
      return works.filter((work) => {
        if (!include_archived && work.archived) {
          return false
        } else {
          return self.itemCountByStatusInWork(work.id, status)
        }
      })
    },
    subcontractorItemsByType(type) {
      return self.allSubcontractorItems.filter(
        (item) => item.item_type_id.id === type
      )
    },
    groupedWorkEntryReferences() {
      const workentryReferences = values(
        getParent(self).itemActionStore.item_actions
      )
        .filter((entry) => {
          return !entry.date_end // do not track returned subcontractor items
        })
        .map((entry) => {
          return {
            itemId: entry.item_id.id,
            workEntry: entry.work_entry_id,
          }
        })
      return R.uniqWith(
        R.eqBy(R.props(['itemId', 'workEntry'])),
        workentryReferences
      )
    },
  }))
  .actions((self) => ({
    updateItem(item) {
      self.items.put(item)

      if (item.is_subcontractor) {
        self.connectSubcontractorItems(item)
      }
    },
    /**
     * Corrects subcontractor relation by replacing null with
     * the real relation that is stored in itemActionStore
     **/
    connectSubcontractorItem: function (item) {
      const referenceTable = self.groupedWorkEntryReferences()

      item.work_entry_id = []
      for (const entry of referenceTable) {
        if (entry.itemId === item.id) {
          item.work_entry_id.push(entry.workEntry)
        }
      }

      // remove array type
      if (item.work_entry_id.length === 1) {
        item.work_entry_id = item.work_entry_id[0]
      } else if (item.work_entry_id.length === 0) {
        item.work_entry_id = null
      }
      self.items.put(item)
    },
    connectSubcontractorItems: function () {
      self.items.forEach((item) => {
        if (item.is_subcontractor) {
          self.connectSubcontractorItem(item)
        }
      })
    },
    fetchItems: flow(function* () {
      self.itemState = requestState.LOADING

      try {
        const response = yield axios.get(API_BASE + '/items')

        if (response.status === 200) {
          response.data.forEach((item) => {
            self.items.put(item)
          })

          self.itemState = requestState.LOADED
        }
      } catch (error) {
        self.itemState = requestState.ERROR
      }
      self.connectSubcontractorItems()
    }),
    createItem: flow(function* (item) {
      self.itemState = requestState.LOADING

      try {
        const response = yield axios.post(API_BASE + '/items', item)

        if (response.status === 200) {
          const createdItem = response.data
          self.items.put(createdItem)
          self.itemState = requestState.CREATED
        }
      } catch (error) {
        self.itemState = requestState.ERROR
      }
    }),
    patchItem: flow(function* (item, id) {
      self.itemState = requestState.LOADING

      const currentItem = self.itemById(id)

      // only changed fields
      const newItem = Object.keys(item).reduce(
        (a, k) => {
          if (currentItem[k] !== item[k]) {
            // handle nested item_type_id case
            if (
              k === 'item_type_id' &&
              currentItem.item_type_id.id === item.item_type_id
            ) {
              return a
            }
            return { ...a, [k]: item[k] }
          }
          return a
        },
        { id: currentItem.id }
      )

      try {
        const response = yield axios.patch(API_BASE + `/items/${id}`, newItem)

        if (response.status === 200) {
          const updatedItem = response.data
          self.updateItem(updatedItem)
          self.itemState = requestState.UPDATED
        }
      } catch (error) {
        self.itemState = requestState.ERROR
      }
    }),
    deleteItem: flow(function* (id) {
      self.itemState = requestState.LOADING

      try {
        const response = yield axios.delete(`${API_BASE}/items/${id}`)

        if (response.status === 200) {
          self.items.delete(id)
          self.itemState = requestState.DELETED
        }
      } catch (error) {
        self.itemState = requestState.ERROR
      }
    }),
  }))

import React from 'react'
import PropTypes from 'prop-types'
import { BounceLoader } from 'react-spinners'

const LoadingSpinner = (props) => {
  return (
    <div className={`LoadingSpinner LoadingSpinner--${props.class}`}>
      <BounceLoader size={props.size} loading={props.loading} color="#73973e" />
      <div className="LoadingSpinner LoadingSpinner__title">{props.title}</div>
    </div>
  )
}

LoadingSpinner.propTypes = {
  class: PropTypes.string,
  loading: PropTypes.bool,
  size: PropTypes.number,
  title: PropTypes.string,
}

export default LoadingSpinner

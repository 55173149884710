import React, { useState } from 'react'
// import { values } from 'mobx'
import { observer } from 'mobx-react'
import { requestState } from '../../helpers/requestState'
import { notifySuccess, notifyError } from '../../helpers/notificationHelpers'
import { useStores } from '../../hooks/use-stores'
import DesktopSearchInput from './DesktopSearchInput'
import DesktopFormButton from './DesktopFormButton'
import DesktopActionButton from './DesktopActionButton'
import DesktopPageHeading from './DesktopPageHeading'
import { ButtonClassTypes } from '../../types/ButtonClassTypes'

const DesktopCreateLocationForm = observer(({ onClose }) => {
  const { locationStore } = useStores()
  const { createLocation } = locationStore
  const [locationName, setLocationName] = useState('')
  // const [locationId, setLocationId] = useState('')
  const [locationAddress, setLocationAddress] = useState('')

  /*const checkDuplicateLocationId = (id) => {
    return values(locationStore.locations).filter(
      (location) => location.location_id === id
    ).length
  }*/

  const sendLocation = async (event) => {
    event.preventDefault()

    if (!locationName) {
      return notifyError('Kohdetta ei lisätty: lisää nimi')
    }

    // Disabled locationId checks for now
    /*if (!locationId) {
      return notifyError('Kohdetta ei lisätty: lisää ID')
    }*/

    if (!locationAddress) {
      return notifyError('Kohdetta ei lisätty: lisää osoite')
    }

    // Disabled locationId checks for now
    /*if (checkDuplicateLocationId(locationId)) {
      return notifyError(
        `Kohdetta ei lisätty: kohde ${locationId} on jo olemassa`
      )
    }*/

    const location = {
      name: locationName,
      // location_id: locationId,
      address: locationAddress,
    }

    const createdId = await createLocation(location)

    if (locationStore.locationState === requestState.CREATED) {
      notifySuccess(`Kohde ${locationName} lisätty`)
      onClose(createdId)
    } else {
      notifyError(`Kohdetta ${locationName} ei voitu lisätä`)
    }
  }

  function cancel(e) {
    e.preventDefault()
    onClose(null) // null to not send id because nothing was created
  }

  return (
    <form className="DesktopCreateLocationForm" onSubmit={sendLocation}>
      <div className="DesktopCreateLocationForm__fields">
        <DesktopPageHeading title="Luo uusi kohde" />
        <DesktopSearchInput
          name="Nimi:"
          eventHandler={setLocationName}
          value={locationName}
          type="text"
          required={true}
        />
        {/* <DesktopSearchInput
          name="ID:"
          eventHandler={setLocationId}
          value={locationId}
          type="text"
          required={true}
        /> */}
        <DesktopSearchInput
          name="Osoite:"
          eventHandler={setLocationAddress}
          value={locationAddress}
          type="text"
          required={true}
        />
        <div className="DesktopCreateLocationForm__buttons">
          <DesktopFormButton title="Luo kohde" />
          <DesktopActionButton
            title="Peruuta"
            action={cancel}
            class={ButtonClassTypes.WARNING}
          />
        </div>
      </div>
    </form>
  )
})

export default DesktopCreateLocationForm

import React from 'react'
import DesktopNewWorkPrompt from '../components/Desktop/DesktopNewWorkPrompt'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'

export const createNewWorkPrompt = (
  title,
  message,
  location,
  closeCb = () => {}
) => {
  confirmAlert({
    customUI: ({ onClose }) => {
      function close(id) {
        onClose()
        closeCb(id)
      }

      return (
        <DesktopNewWorkPrompt
          title={title}
          message={message}
          location={location}
          onClose={(id) => close(id)}
        />
      )
    },
    closeOnClickOutside: false,
  })
}

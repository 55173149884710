import { types, flow } from 'mobx-state-tree'
import { BillingStatus } from '../models/BillingStatus'
import axios from 'axios'
import { requestState } from '../helpers/requestState'
import { API_BASE } from '../helpers/urlHelpers'

export const BillingStatusStore = types
  .model('BillingStatusStore', {
    billing_statuses: types.map(BillingStatus, {}),
    billingStatusState: types.optional(types.string, requestState.NONE),
  })
  .actions((self) => ({
    fetchBillingStatuses: flow(function* () {
      self.billingStatusState = requestState.LOADING

      try {
        const response = yield axios.get(API_BASE + '/billing_statuses')

        if (response.status === 200) {
          response.data.forEach((billing_status) => {
            self.billing_statuses.put(billing_status)
          })

          self.billingStatusState = requestState.LOADED
        }
      } catch (error) {
        self.billingStatusState = requestState.ERROR
      }
    }),
  }))

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useStores } from '../../../../hooks/use-stores'
import { requestState } from '../../../../helpers/requestState'
import {
  checkSuccess,
  notifyError,
} from '../../../../helpers/notificationHelpers'
import { ItemStatusTypes } from '../../../../types/ItemStatusTypes'
import { TableCellContentTypes } from '../../../../types/TableCellContentTypes'
import { ReleaseButtonTypes } from '../../../../types/ReleaseButtonTypes'
import { Row, Cell } from 'react-sticky-table'
import DesktopTableReleaseButton from '../../DesktopTableReleaseButton'
import { createConfirmationPrompt } from '../../../../helpers/confirmationPrompt'

const DesktopSingleWorkItemGroupTableRow = (props) => {
  const { itemActionStore } = useStores()
  const { adminRelease, scanOutSubcontractor } = itemActionStore
  const today = moment(Date.now()).format('yyyy-MM-DD')
  const disabled = props.date_end ? true : false
  const isSubcontractor =
    props.owner === TableCellContentTypes.OWNER.subcontractor ? true : false
  const [quantity, setQuantity] = useState('')

  const validateCommon = () => {
    if (!parseInt(quantity)) {
      return notifyError('Valitse ainakin yksi laite')
    }

    if (props.date_start > today) {
      return notifyError('Lopetuspäivä ei voi olla ennen aloituspäivää')
    }

    return true
  }

  const confirmReleaseItems = () => {
    if (!validateCommon()) {
      return
    }

    const idsToRelease = props.id_list.slice(0, quantity)
    const items = idsToRelease.map((id) => ({
      item_id: id,
      work_entry_id: props.work_id,
    }))

    createConfirmationPrompt(
      'Merkitse noudettavaksi',
      props.count === 1
        ? props.item_type
        : quantity === '1'
        ? `Yksi ${props.item_type} -laite`
        : [`Yhteensä ${quantity} kappaletta`, `${props.item_type} -laitetta`],
      'Kyllä',
      () => releaseItems(items),
      'Peruuta'
    )
  }

  // Admin release, changes item status to "Noudettavissa"
  const releaseItems = async (items) => {
    setQuantity('')

    await adminRelease({ items })
    const isSuccess = itemActionStore.itemActionState === requestState.UPDATED
    return checkSuccess(
      isSuccess,
      'Laiteet merkitty noudettavaksi',
      'Laitteita ei voitu merkitä noudettavaksi'
    )
  }

  const confirmScanOutSubcontractorItems = () => {
    if (!validateCommon()) {
      return
    }

    const idsToRelease = props.item_action_list.slice(0, quantity)
    const items = {
      items: idsToRelease.map((id) => ({ item_action_id: id })),
      date_end: today,
    }

    createConfirmationPrompt(
      'Merkitse varastoon',
      props.count === 1
        ? props.item_type
        : quantity === '1'
        ? `Yksi ${props.item_type} -alihankkijalaite`
        : [
            `Yhteensä ${quantity} kappaletta`,
            `${props.item_type} -alihankkijalaitetta`,
          ],
      'Kyllä',
      () => scanOutSubcontractorItems(items),
      'Peruuta'
    )
  }

  const scanOutSubcontractorItems = async (items) => {
    setQuantity('')

    await scanOutSubcontractor(items)
    const isSuccess = itemActionStore.itemActionState === requestState.UPDATED
    return checkSuccess(
      isSuccess,
      'Alihankkijan laitteet poistettu kohteesta',
      'Alihankkijan laitteita ei voitu poistaa'
    )
  }

  return (
    <Row>
      <Cell>{props.item_type}</Cell>
      <Cell className="cell-collapse">{props.owner}</Cell>
      <Cell className="cell-collapse">{props.count}</Cell>
      <Cell>{moment(props.date_start).format('D.M.Y')}</Cell>
      <Cell>
        {props.date_end ? moment(props.date_end).format('D.M.Y') : ''}
      </Cell>
      <Cell className="cell-collapse cell-full">
        <input
          type="number"
          className="cell-style__input"
          min="0"
          max={props.count}
          disabled={disabled}
          value={quantity}
          onChange={(e) => setQuantity(e.target.value)}
        />
      </Cell>
      <Cell className="cell-collapse">
        {isSubcontractor ? (
          <DesktopTableReleaseButton
            action={confirmScanOutSubcontractorItems}
            item_status={ItemStatusTypes.IN_WAREHOUSE.id}
            text={ReleaseButtonTypes.SCAN_OUT}
            disabled={disabled}
          />
        ) : (
          <DesktopTableReleaseButton
            action={confirmReleaseItems}
            item_status={ItemStatusTypes.PICKUP.id}
            text={ReleaseButtonTypes.ADMIN_RELEASE}
            disabled={disabled}
          />
        )}
      </Cell>
    </Row>
  )
}

DesktopSingleWorkItemGroupTableRow.propTypes = {
  id: PropTypes.number,
  id_list: PropTypes.array,
  item_action_list: PropTypes.array,
  work_id: PropTypes.number,
  item_type: PropTypes.string,
  owner: PropTypes.string,
  count: PropTypes.number,
  date_start: PropTypes.string,
  date_end: PropTypes.string,
}

export default DesktopSingleWorkItemGroupTableRow

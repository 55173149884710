import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const DesktopLinkButton = (props) => {
  return (
    <Link to={props.path}>
      <button
        className={`DesktopLinkButton DesktopLinkButton--${props.class}`}
        type="button"
      >
        {props.title}
      </button>
    </Link>
  )
}

DesktopLinkButton.propTypes = {
  class: PropTypes.string,
  path: PropTypes.string,
  title: PropTypes.string,
}

export default DesktopLinkButton

import React from 'react'
import { persist } from 'mst-persist'
import { RootStore } from '../stores/RootStore'
import { LocalStorageTypes } from '../types/LocalStorageTypes'

const rootStore = RootStore.create({})

persist(LocalStorageTypes.USERS, rootStore.userStore, {
  storage: localStorage,
  jsonify: true,
  whitelist: ['loggedIn', 'user'],
})

export const storesContext = React.createContext({
  billingStatusStore: rootStore.billingStatusStore,
  itemActionStore: rootStore.itemActionStore,
  itemStore: rootStore.itemStore,
  itemStatusStore: rootStore.itemStatusStore,
  itemTypeStore: rootStore.itemTypeStore,
  locationStore: rootStore.locationStore,
  userStore: rootStore.userStore,
  workStore: rootStore.workStore,
  workStatusStore: rootStore.workStatusStore,
})

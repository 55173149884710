export const sortItemActions = [
  {
    prop: 'billing_status_id',
    direction: 1,
  },
  {
    prop: 'date_end',
    direction: 1,
  },
  {
    prop: 'date_start',
    direction: 1,
  },
]

import React from 'react'
import PropTypes from 'prop-types'

const MainButton = (props) => {
  return (
    <button
      className="MainButton"
      onClick={props.action}
      disabled={props.disabled}
    >
      {props.title}
    </button>
  )
}

MainButton.propTypes = {
  title: PropTypes.string,
  action: PropTypes.func,
  disabled: PropTypes.bool,
}

export default MainButton

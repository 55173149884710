import React, { useState } from 'react'
import { observer } from 'mobx-react'
import {
  notifySuccess,
  notifyError,
} from '../../../../helpers/notificationHelpers'
import { requestState } from '../../../../helpers/requestState'
import { SelectInput } from '../../../SelectInput'
import DesktopFormButton from '../../DesktopFormButton'
import DesktopSearchInput from '../../DesktopSearchInput'
import { useStores } from '../../../../hooks/use-stores'
import PropTypes from 'prop-types'

const DesktopAdminUserModify = observer(({ admin }) => {
  const { userStore } = useStores()
  const { updateUser, usersForReactSelect } = userStore
  const [userId, setUserId] = useState(admin ? null : userStore.user?.id)
  const [userName, setUserName] = useState(admin ? null : userStore.user?.name)
  const [initialUserEmail, setInitialUserEmail] = useState(
    admin ? '' : userStore.user?.email
  )
  const [newUserEmail, setNewUserEmail] = useState('')
  const [newUserPassword, setNewUserPassword] = useState('')
  const [retypedPassword, setRetypedPassword] = useState('')
  const [selectValue, setSelectValue] = useState(null)

  const sendUpdatedUser = async (event) => {
    event.preventDefault()

    if (!userId) {
      return notifyError('Käyttäjää ei päivitetty: valitse ensin käyttäjä')
    }

    if (newUserEmail && initialUserEmail === newUserEmail && !newUserPassword) {
      return notifyError(
        `Käyttäjää ${userName} ei päivitetty: sähköposti ei muuttunut`
      )
    }

    if (newUserPassword && !retypedPassword) {
      return notifyError(
        `Käyttäjää ${userName} ei päivitetty: toista uusi salasana`
      )
    }

    if (
      newUserPassword &&
      retypedPassword &&
      newUserPassword !== retypedPassword
    ) {
      return notifyError(
        `Käyttäjää ${userName} ei päivitetty: toistettu salasana ei täsmää`
      )
    }

    const user = {
      name: userName,
    }

    if (newUserEmail && initialUserEmail !== newUserEmail) {
      user.email = newUserEmail
    }

    if (newUserPassword) {
      user.password = newUserPassword
    }

    await updateUser(user, userId)

    if (userStore.userState === requestState.UPDATED) {
      setInitialUserEmail(admin ? '' : userStore.user?.email)
      setNewUserEmail('')
      setUserId(admin ? null : userStore.user?.id)
      setUserName(admin ? null : userStore.user?.name)
      setNewUserPassword('')
      setRetypedPassword('')
      setSelectValue(null)
      notifySuccess(`Käyttäjä ${userName} päivitetty`)
    } else {
      notifyError(`Käyttäjää ${userName} ei voitu päivittää`)
    }
  }

  const handleSelect = (selectedOption) => {
    setSelectValue(selectedOption)
    setUserId(selectedOption.user.id.toString())
    setUserName(selectedOption.user.name)
    setInitialUserEmail(selectedOption.user.email)
  }

  return (
    <form className="DesktopAdminUserModify" onSubmit={sendUpdatedUser}>
      <div className="DesktopAdminUserModify DesktopAdminUserModify__search">
        <h3 className="DesktopAdminUserModify__title">Muokkaa käyttäjää</h3>
        {admin && (
          <SelectInput
            name="Valitse käyttäjä"
            value={selectValue}
            options={usersForReactSelect}
            handleSelect={handleSelect}
          />
        )}
        <DesktopSearchInput
          name="Uusi sähköpostiosoite"
          type="email"
          eventHandler={setNewUserEmail}
          value={newUserEmail}
          disabled={!userId}
        />
        <DesktopSearchInput
          name="Uusi salasana (väh. 8 merkkiä)"
          type="password"
          eventHandler={setNewUserPassword}
          value={newUserPassword}
          disabled={!userId}
        />
        <DesktopSearchInput
          name="Toista uusi salasana"
          type="password"
          eventHandler={setRetypedPassword}
          value={retypedPassword}
          required={!!newUserPassword}
          disabled={!userId}
        />
        <DesktopFormButton
          title="Muokkaa"
          disabled={!userId || (!newUserEmail && !newUserPassword)}
        />
      </div>
    </form>
  )
})

DesktopAdminUserModify.propTypes = {
  admin: PropTypes.bool,
}

export default DesktopAdminUserModify

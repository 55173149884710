import React from 'react'
import PropTypes from 'prop-types'

const DesktopDeleteButtonTable = (props) => {
  return (
    <button className="DesktopDeleteButtonTable" onClick={props.action}>
      <img src="trash_can.png" alt="trash-can" />
    </button>
  )
}

DesktopDeleteButtonTable.propTypes = {
  action: PropTypes.func,
}

export default DesktopDeleteButtonTable

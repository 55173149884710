import React from 'react'
import DesktopPageHeading from '../DesktopPageHeading'
import DesktopItemTable from '../DesktopItemTable'

const DesktopFrontPage = () => {
  return (
    <div className="DesktopFrontPage">
      <DesktopPageHeading
        title="Kaluston hallinta"
        subtitle="Laitteiden tilanne"
      />
      <DesktopItemTable />
    </div>
  )
}

export default DesktopFrontPage

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { useStores } from '../../../../hooks/use-stores'
import {
  notifyError,
  notifySuccess,
} from '../../../../helpers/notificationHelpers'
import { requestState } from '../../../../helpers/requestState'
import DesktopSearchInput from '../../DesktopSearchInput'
import DesktopFormButton from '../../DesktopFormButton'
import DesktopLinkButton from '../../DesktopLinkButton'

const DesktopEditLocationForm = (props) => {
  const history = useHistory()

  const { locationStore } = useStores()
  const { patchLocation, locationByLocationId } = locationStore

  const id = props.location.id
  const initialLocationId = props.location.location_id
  const [locationName, setLocationName] = useState(props.location.name)
  const [locationId, setLocationId] = useState(props.location.location_id)
  const [locationAddress, setLocationAddress] = useState(props.location.address)

  const disableSubmit =
    locationName === props.location.name &&
    locationId === props.location.location_id &&
    locationAddress === props.location.address

  const updatedLocation = {
    name: locationName,
    location_id: locationId,
    address: locationAddress,
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (initialLocationId !== locationId && locationByLocationId(locationId)) {
      return notifyError('Kohdetta ei voitu päivittää: ID on jo käytössä')
    }

    await patchLocation(id, updatedLocation)
    const isSuccess = locationStore.locationState === requestState.UPDATED

    if (isSuccess) {
      notifySuccess(`Kohde ${locationName} päivitetty`)
      return history.push(`/locations/${id}`)
    } else {
      return notifyError('Kohdetta ei voitu päivittää')
    }
  }

  return (
    <form className="DesktopEditLocationForm" onSubmit={handleSubmit}>
      <div className="DesktopEditLocationForm__fields">
        <DesktopSearchInput
          name="Nimi:"
          eventHandler={setLocationName}
          value={locationName}
          type="text"
          required={true}
        />
        <DesktopSearchInput
          name="ID:"
          eventHandler={setLocationId}
          value={locationId}
          type="text"
          required={true}
        />
        <DesktopSearchInput
          name="Osoite:"
          eventHandler={setLocationAddress}
          value={locationAddress}
          type="text"
          required={true}
        />
        <div className="DesktopEditLocationForm__buttons">
          <DesktopFormButton title="Päivitä kohde" disabled={disableSubmit} />
          <DesktopLinkButton title="Peruuta" path={`/locations/${id}`} />
        </div>
      </div>
    </form>
  )
}

DesktopEditLocationForm.propTypes = {
  location: PropTypes.object,
}

export default DesktopEditLocationForm

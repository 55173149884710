import React from 'react'
import { observer } from 'mobx-react'
import { useHistory } from 'react-router-dom'
import { useStores } from '../../hooks/use-stores'
import { createConfirmationPrompt } from '../../helpers/confirmationPrompt'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { LocalStorageTypes } from '../../types/LocalStorageTypes'
import { faUserCircle, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { notifySuccess } from '../../helpers/notificationHelpers'
import { Link } from 'react-router-dom'

const MobileHeader = observer(() => {
  const { userStore } = useStores()
  const { logOut } = userStore
  const history = useHistory()
  let user = null

  if (userStore.user) {
    user = userStore.user.name
  }

  const handleLogOut = () => {
    logOut()
    window.localStorage.removeItem(LocalStorageTypes.USERS)
    notifySuccess('Uloskirjautuminen onnistui')
    return history.push('/')
  }

  const confirmAndLogOut = () =>
    createConfirmationPrompt(
      'Kirjaudu ulos',
      'Haluatko kirjautua ulos?',
      'Kyllä',
      handleLogOut,
      'Ei'
    )

  return (
    <div className="MobileHeader">
      <Link className="MobileHeader__logo" to="/" />
      {user ? (
        <div className="MobileHeader__user-section">
          <button
            className="MobileHeader__log-out-button"
            onClick={confirmAndLogOut}
          >
            <FontAwesomeIcon
              icon={faSignOutAlt}
              size="2x"
              className="sign-out-icon"
            />
          </button>
          <span className="MobileHeader__change-user-section">
            <FontAwesomeIcon
              icon={faUserCircle}
              size="2x"
              className="user-icon"
            />
            {user}
          </span>
        </div>
      ) : null}
    </div>
  )
})

export default MobileHeader

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStores } from '../../../../hooks/use-stores'
import { ButtonClassTypes } from '../../../../types/ButtonClassTypes'
import { ItemStatusTypes } from '../../../../types/ItemStatusTypes'
import { WorkStatusTypes } from '../../../../types/WorkStatusTypes'
import { requestState } from '../../../../helpers/requestState'
import { ItemActionCheckBoxTypes } from '../../../../types/ItemActionCheckBoxTypes'
import { createAddItemToWorkPrompt } from '../../../../helpers/addItemToWorkPrompt'
import { createConfirmationPrompt } from '../../../../helpers/confirmationPrompt'
import {
  checkSuccess,
  notifyError,
  notifySuccess,
} from '../../../../helpers/notificationHelpers'
import { workStateIndicator } from '../../../../helpers/workStateHelper'
import DesktopPageHeading from '../../DesktopPageHeading'
import DesktopInformationBox from '../../DesktopInformationBox'
import DesktopDeviceStatusInformationBox from '../../DesktopDeviceStatusInformationBox'
import DesktopActionButton from '../../DesktopActionButton'
import DesktopLinkButton from '../../DesktopLinkButton'
import DesktopCheckboxContainer from '../../DesktopCheckboxContainer'
import DesktopSingleWorkItemTable from './DesktopSingleWorkItemTable'
import DesktopSingleWorkItemGroupTable from './DesktopSingleWorkItemGroupTable'
import DesktopNoResultMessage from '../../DesktopNoResultMessage'

const DesktopSingleWorkPage = observer(({ work }) => {
  const history = useHistory()
  const { itemStore, workStore, itemActionStore } = useStores()
  const {
    itemsByStatusInWork,
    itemCountByTypeAndStatusInWork,
    itemsByWork,
  } = itemStore
  const { updateWork } = workStore
  const {
    subcontractorItemActionsByWork,
    openItemActionsCountByWork,
  } = itemActionStore
  const itemsToPickUp = itemsByStatusInWork(ItemStatusTypes.PICKUP.id, work.id)

  const isInActive = work.work_status_id.id === WorkStatusTypes.INACTIVE.id
  const isArchived = work.archived
  const today = moment(Date.now()).format('yyyy-MM-DD')

  const uniqueItemTypesToPickUp = [
    ...new Set(itemsToPickUp.map((item) => item.item_type_id.name)),
  ]

  // This only shows Vertia items because subcontractor item is never in state of pick up
  const uniqueItemTypesToPickUpWithCount = uniqueItemTypesToPickUp.map(
    (type) => ({
      type: type,
      count: itemCountByTypeAndStatusInWork(
        type,
        ItemStatusTypes.PICKUP.id,
        work.id
      ),
      status: ItemStatusTypes.PICKUP.id,
    })
  )

  const itemsReserved = itemsByStatusInWork(
    ItemStatusTypes.RESERVED.id,
    work.id
  )
  const uniqueItemTypesReserved = [
    ...new Set(itemsReserved.map((item) => item.item_type_id.name)),
  ]

  // This only shows Vertia items because subcontractor item is never reserved
  const uniqueItemTypesReservedWithCount = uniqueItemTypesReserved.map(
    (type) => ({
      type: type,
      count: itemCountByTypeAndStatusInWork(
        type,
        ItemStatusTypes.RESERVED.id,
        work.id
      ),
      status: ItemStatusTypes.RESERVED.id,
    })
  )

  const groupUniqueSubcontractorItems = () => {
    const openSubcontractorItemActions = subcontractorItemActionsByWork(
      work.id
    ).filter((action) => !action.date_end)

    const subcontractorItemsWithCount = openSubcontractorItemActions.map(
      (action) => ({
        type: action.item_id.item_type_id.name,
        count: openSubcontractorItemActions.filter(
          (actionToCompare) =>
            actionToCompare.item_id.item_type_id.id ===
            action.item_id.item_type_id.id
        ).length,
      })
    )

    return R.uniqWith(
      R.eqBy(R.props(['type', 'count'])),
      subcontractorItemsWithCount
    )
  }

  const uniqueSubcontractorItemTypesWithCount = groupUniqueSubcontractorItems()

  const [tableToShow, setTableToShow] = useState(1)

  const options = [
    {
      title: 'Kohde',
      data: work.location_id.name,
      path: `locations/${work.location_id.id}`,
    },
    {
      title: 'Osoite',
      data: work.location_id.address,
    },
    {
      title: 'Työ',
      data: work.name,
    },
    {
      title: 'Tila',
      data: workStateIndicator(work, itemStore).text,
    },
  ]

  const checkBoxItems = [
    {
      name: ItemActionCheckBoxTypes.BY_TYPE,
      action: () => setTableToShow(1),
    },
    {
      name: ItemActionCheckBoxTypes.BY_ITEM,
      action: () => setTableToShow(2),
    },
  ]

  const confirmAndEndWork = () => {
    if (work.work_status_id.id === WorkStatusTypes.INACTIVE.id) {
      return notifyError('Työ on jo lopetettu')
    }

    if (openItemActionsCountByWork(work.id)) {
      return notifyError(
        'Työtä ei voitu lopettaa: vapauta ensin kaikki laitteet'
      )
    }

    createConfirmationPrompt(
      'Päätä työ',
      ['Oletko varma, että haluat päättää työn:', work.name],
      'Kyllä',
      () => endWork(work.id),
      'Ei'
    )
  }

  const endWork = async (workId) => {
    const updatedWork = {
      work_status_id: WorkStatusTypes.INACTIVE.id,
    }

    await updateWork(workId, updatedWork)

    const isSuccess = workStore.workState === requestState.UPDATED

    return checkSuccess(
      isSuccess,
      `Työ ${work.name} suljettu`,
      `Työtä ${work.name} ei voitu sulkea`
    )
  }

  const sendArchiveWork = async (workId) => {
    const archivedWork = { archived: today }

    await updateWork(workId, archivedWork)
    const isSuccess = workStore.workState === requestState.UPDATED

    if (isSuccess) {
      notifySuccess(`Työ ${work.name} arkistoitu`)
      return history.push(`/locations/${work.location_id.id}`)
    } else {
      return notifyError(`Työtä ${work.name} ei voitu arkistoida`)
    }
  }

  const confirmAndArchive = () => {
    if (itemsByWork(work.id).length) {
      return notifyError('Työtä ei voitu arkistoida: nouda ensin laitteet')
    }

    createConfirmationPrompt(
      'Arkistoi työ',
      `Oletko varma, että haluat arkistoida työn ${work.name}?`,
      'Kyllä',
      () => sendArchiveWork(work.id),
      'Ei'
    )
  }

  // Send request to unarchive work
  const sendUnArchiveWork = async (workId) => {
    const unArchivedWork = { archived: null }

    await updateWork(workId, unArchivedWork)
    const isSuccess = workStore.workState === requestState.UPDATED

    if (isSuccess) {
      notifySuccess(`Työ ${work.name} palautettu arkistosta`)
      return history.push(`/locations/${work.location_id.id}`)
    } else {
      return notifyError(`Työtä ${work.name} ei voitu palauttaa arkistosta`)
    }
  }

  // Confirm before request to unarchive work
  const confirmAndUnArchive = () => {
    createConfirmationPrompt(
      'Palauta työ arkistosta',
      `Oletko varma, että haluat palauttaa työn ${work.name} arkistosta?`,
      'Kyllä',
      () => sendUnArchiveWork(work.id),
      'Ei'
    )
  }

  return (
    <div className="DesktopSingleWorkPage">
      <DesktopPageHeading
        title={'Työ - ' + work.name}
        subtitle="Hallitse työtä"
      />
      <div className="DesktopSingleWorkPage__information">
        <DesktopInformationBox options={options} />
        {isArchived ? null : (
          <div className="DesktopSingleWorkPage__statuses">
            <DesktopDeviceStatusInformationBox
              title="Käytössä (Alihankkija)"
              no_result_text="Ei alihankkijan laitteita käytössä"
              options={uniqueSubcontractorItemTypesWithCount}
              visible={uniqueSubcontractorItemTypesWithCount.length}
            />
            <DesktopDeviceStatusInformationBox
              title="Käytössä (Vertia)"
              no_result_text="Ei laitteita käytössä"
              options={uniqueItemTypesReservedWithCount}
              visible={uniqueItemTypesReservedWithCount.length}
            />
            <DesktopDeviceStatusInformationBox
              title="Noudettavissa (Vertia)"
              no_result_text="Ei noudettavia laitteita"
              options={uniqueItemTypesToPickUpWithCount}
              visible={uniqueItemTypesToPickUpWithCount.length}
            />
          </div>
        )}
      </div>
      {isInActive && isArchived ? (
        <div className="DesktopSingleWorkPage__buttons">
          <DesktopActionButton title="Palauta" action={confirmAndUnArchive} />
          <DesktopNoResultMessage
            message={`Työ on arkistoitu ${moment(isArchived).format('D.M.Y')}`}
          />
        </div>
      ) : isInActive && !isArchived ? (
        <div className="DesktopSingleWorkPage__buttons">
          <DesktopActionButton
            title="Arkistoi"
            action={confirmAndArchive}
            class={ButtonClassTypes.ARCHIVE}
          />
        </div>
      ) : (
        <div className="DesktopSingleWorkPage__buttons">
          <DesktopActionButton
            title="Lisää laite"
            action={() =>
              createAddItemToWorkPrompt(
                'Lisää laite',
                `Lisää laite työlle ${work.name}`,
                work.id
              )
            }
          />
          <DesktopLinkButton
            title="Muokkaa"
            path={`/works/${work.id}/edit`}
            class={ButtonClassTypes.MODIFY}
          />
          <DesktopActionButton
            title="Päätä työ"
            action={confirmAndEndWork}
            class={ButtonClassTypes.WARNING}
          />
        </div>
      )}
      <DesktopCheckboxContainer
        labels_and_actions={checkBoxItems}
        radio_group="actions_by_group"
        checked={ItemActionCheckBoxTypes.BY_TYPE}
      />
      {tableToShow === 1 ? (
        <DesktopSingleWorkItemGroupTable work={work} />
      ) : (
        <DesktopSingleWorkItemTable work={work} />
      )}
    </div>
  )
})

DesktopSingleWorkPage.propTypes = {
  work: PropTypes.object,
}

export default DesktopSingleWorkPage

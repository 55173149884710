import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { useStores } from '../../../../../hooks/use-stores'
import { ItemActionCheckBoxTypes } from '../../../../../types/ItemActionCheckBoxTypes'
import { BillingStatusTypes } from '../../../../../types/BillingStatusTypes'
import { sortItemActions } from '../../../../../helpers/sortHelpers'
import { findValue } from '../../../../../helpers/filterHelpers'
import { compareByMultiplePropNames } from '../../../../../utils/sortUtils'
import DesktopSearchInput from '../../../DesktopSearchInput'
import DesktopCheckboxContainer from '../../../DesktopCheckboxContainer'
import DesktopNoResultsMessage from '../../../DesktopNoResultMessage'
import DesktopSingleWorkInvoiceTableRow from './DesktopSingleWorkInvoiceTableRow'
import DesktopBaseTable from '../../../DesktopBaseTable'

const DesktopSingleWorkInvoiceTable = observer((props) => {
  const { itemActionStore } = useStores()
  const {
    itemActionsByWork,
    itemActionsCountByWork,
    itemActionsByBillingStatusAndWork,
    itemActionCountByBillingStatusAndWork,
  } = itemActionStore

  const [itemSearchWord, setItemSearchWord] = useState('')
  const [arrayToFilter, setArrayToFilter] = useState(() => () =>
    itemActionsByWork(props.work.id)
  )

  const checkBoxItems = [
    {
      name: ItemActionCheckBoxTypes.ALL,
      count: itemActionsCountByWork(props.work.id),
      action: () =>
        setArrayToFilter(() => () => itemActionsByWork(props.work.id)),
    },
    {
      name: ItemActionCheckBoxTypes.BILLED,
      count: itemActionCountByBillingStatusAndWork(
        BillingStatusTypes.BILLED.id,
        props.work.id
      ),
      action: () =>
        setArrayToFilter(() => () =>
          itemActionsByBillingStatusAndWork(
            BillingStatusTypes.BILLED.id,
            props.work.id
          )
        ),
    },
    {
      name: ItemActionCheckBoxTypes.NOT_BILLED,
      count: itemActionCountByBillingStatusAndWork(
        BillingStatusTypes.NOT_BILLED.id,
        props.work.id
      ),
      action: () =>
        setArrayToFilter(() => () =>
          itemActionsByBillingStatusAndWork(
            BillingStatusTypes.NOT_BILLED.id,
            props.work.id
          )
        ),
    },
  ]

  return (
    <div className="DesktopSingleWorkInvoiceTable">
      <div className="DesktopSingleWorkInvoiceTable__search">
        <DesktopSearchInput
          name="Hae laitteen nimellä"
          type="text"
          eventHandler={setItemSearchWord}
          value={itemSearchWord}
          required={false}
        />
        <DesktopCheckboxContainer
          labels_and_actions={checkBoxItems}
          radio_group="actions_by_status"
          checked={ItemActionCheckBoxTypes.ALL}
        />
      </div>
      {arrayToFilter().filter((action) =>
        findValue(action.item_id.item_type_id.name, itemSearchWord)
      ).length ? (
        <DesktopBaseTable
          columns={[
            'Laite',
            'Lisännyt työlle',
            'Aloitus',
            'Lopetus',
            'Laskutettu',
            '',
          ]}
        >
          {arrayToFilter()
            .filter((action) =>
              findValue(action.item_id.item_type_id.name, itemSearchWord)
            )
            .sort(compareByMultiplePropNames(sortItemActions))
            .map((action) => (
              <DesktopSingleWorkInvoiceTableRow
                key={action.id}
                item_action_id={action.id}
                billing_status={action.billing_status_id.id}
                item_type={action.item_id.item_type_id.name}
                date_start={action.date_start}
                date_end={action.date_end}
                updated_at={action.updated_at}
                added_by={action.added_by}
              />
            ))}
        </DesktopBaseTable>
      ) : (
        <DesktopNoResultsMessage
          message={`Laskutustapahtumia ei löydetty hakuehdoilla ${itemSearchWord}`}
        />
      )}
    </div>
  )
})

DesktopSingleWorkInvoiceTable.propTypes = {
  work: PropTypes.object,
}

export default DesktopSingleWorkInvoiceTable

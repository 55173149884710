import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { useStores } from '../../../../hooks/use-stores'
import {
  notifyError,
  notifySuccess,
} from '../../../../helpers/notificationHelpers'
import { requestState } from '../../../../helpers/requestState'
import DesktopSearchInput from '../../DesktopSearchInput'
import DesktopFormButton from '../../DesktopFormButton'
import DesktopLinkButton from '../../DesktopLinkButton'

const DesktopEditWorkForm = (props) => {
  const history = useHistory()

  const { workStore } = useStores()
  const { updateWork, worksByLocation } = workStore

  const id = props.work.id
  const [workName, setWorkName] = useState(props.work.name)

  const disableSubmit = workName === props.work.name

  const updatedWork = {
    name: workName,
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    const nameExists = worksByLocation(props.work.location_id.id).filter(
      (work) => work.id !== props.work.id && work.name === workName
    ).length

    if (nameExists) {
      return notifyError('Työtä ei voitu päivittää: tarkenne on jo käytössä')
    }

    await updateWork(id, updatedWork)
    const isSuccess = workStore.workState === requestState.UPDATED

    if (isSuccess) {
      notifySuccess(`Työ ${workName} päivitetty`)
      return history.push(`/works/${id}`)
    } else {
      return notifyError('Työtä ei voitu päivittää')
    }
  }

  return (
    <form className="DesktopEditWorkForm" onSubmit={handleSubmit}>
      <div className="DesktopEditWorkForm__fields">
        <DesktopSearchInput
          name="Tarkenne:"
          eventHandler={setWorkName}
          value={workName}
          type="text"
          required={true}
        />
        <div className="DesktopEditWorkForm__buttons">
          <DesktopFormButton title="Päivitä työ" disabled={disableSubmit} />
          <DesktopLinkButton title="Peruuta" path={`/works/${id}`} />
        </div>
      </div>
    </form>
  )
}

DesktopEditWorkForm.propTypes = {
  work: PropTypes.object,
}

export default DesktopEditWorkForm

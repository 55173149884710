import { types } from 'mobx-state-tree'
import { User } from './User'
import { Location } from './Location'
import { WorkStatus } from './WorkStatus'

export const Work = types.model('Work', {
  id: types.identifierNumber,
  archived: types.maybeNull(types.string),
  user_id: types.reference(types.late(() => User)),
  location_id: types.reference(types.late(() => Location)),
  work_status_id: types.reference(types.late(() => WorkStatus)),
  name: types.string,
})

import { types, flow } from 'mobx-state-tree'
import { values } from 'mobx'
import { API_BASE, CSRF_URL } from '../helpers/urlHelpers'
import { findValue } from '../helpers/filterHelpers'
import { requestState } from '../helpers/requestState'
import { compareByPropName } from '../utils/sortUtils'
import { User } from '../models/User'
import axios from 'axios'

export const UserStore = types
  .model('UserStore', {
    loggedIn: types.optional(types.boolean, false),
    user: types.maybeNull(User),
    userState: types.optional(types.string, requestState.NONE),
    users: types.map(User, {}),
  })
  .views((self) => ({
    get sortedUsers() {
      return values(self.users).sort(compareByPropName('name'))
    },
    get usersForReactSelect() {
      return values(self.users)
        .map((user) => ({
          value: user.name,
          label: user.name,
          user,
        }))
        .sort(compareByPropName('value'))
    },
    filteredUsers(searchWord) {
      return self.sortedUsers.filter((user) => findValue(user.name, searchWord))
    },
  }))
  .actions((self) => ({
    setLogin: (boolean) => {
      self.loggedIn(boolean)
    },
    setUser: (user) => {
      self.user = user
    },
    login: flow(function* (email, password) {
      self.userState = requestState.LOADING
      yield axios.get(CSRF_URL) // get csrf cookie
      axios.defaults.withCredentials = true

      try {
        const response = yield axios(API_BASE + '/login', {
          method: 'POST',
          withCredentials: true,
          data: {
            email,
            password,
          },
        })
        axios.defaults.headers.common = {
          Authorization: `Bearer ${response.data.token}`,
        }

        localStorage.setItem('auth-token', response.data.token)
        const user = {
          id: Number(response.data.id),
          name: response.data.name,
          email: response.data.email,
          admin: response.data.admin,
        }
        self.user = user

        self.loggedIn = true
        self.userState = requestState.NONE
      } catch (error) {
        console.log(error)
        self.loggedIn = false
        self.userState = requestState.ERROR
        throw error
      }
    }),
    logOut: () => {
      self.loggedIn = false
      self.user = null
      self.users.clear()
      self.userState = requestState.NONE
      localStorage.clear()
    },
    populateUsersWithSelfOnly: () => {
      self.users.clear()
      if (self.user) {
        self.users.put({ ...self.user })
      }
    },
    fetchUsers: flow(function* fetchUsers() {
      self.userState = requestState.LOADING

      try {
        const response = yield axios.get(API_BASE + '/users', {
          withCredentials: true,
        })

        if (response.status === 200) {
          self.users.clear()

          response.data.forEach((user) => {
            self.users.put({
              id: Number(user.id),
              name: user.name,
              email: user.email,
              admin: false, // shouldn't really matter for users list
            })
          })

          self.userState = requestState.LOADED
        }
      } catch (error) {
        self.userState = requestState.ERROR
      }
    }),
    createUser: flow(function* createUser(user) {
      self.userState = requestState.LOADING

      try {
        const response = yield axios.post(API_BASE + '/users', user)

        if (response.status === 200) {
          self.users.put({
            id: Number(response.data.id),
            name: response.data.name,
            email: response.data.email,
            admin: false, // not accurate but doesn't matter
          })
          self.userState = requestState.CREATED
        }
      } catch (error) {
        self.userState = requestState.ERROR
      }
    }),
    updateUser: flow(function* updatedUser(user, userId) {
      self.userState = requestState.LOADING

      try {
        const response = yield axios.put(API_BASE + '/users/' + userId, user)

        if (response.status === 200) {
          self.users.put({
            id: Number(response.data.id),
            name: response.data.name,
            email: response.data.email,
            admin: false, // not accurate but doesn't matter
          })

          if (Number(response.data.id) === self.user.id) {
            self.user = {
              ...self.user,
              email: response.data.email,
            }
          }
          self.userState = requestState.UPDATED
        }
      } catch (error) {
        self.userState = requestState.ERROR
      }
    }),
    deleteUser: flow(function* deleteUser(userId) {
      self.userState = requestState.LOADING

      try {
        const response = yield axios.delete(`${API_BASE}/users/${userId}`)

        if (response.status === 200) {
          self.users.delete(userId)
          self.userState = requestState.DELETED
        }
      } catch (error) {
        self.userState = requestState.ERROR
      }
    }),
  }))

import React, { useState } from 'react'
import { useStores } from '../../../../hooks/use-stores'
import { compareByMultiplePropNames } from '../../../../utils/sortUtils'
import { ItemActionCheckBoxTypes } from '../../../../types/ItemActionCheckBoxTypes'
import { BillingStatusTypes } from '../../../../types/BillingStatusTypes'
import DesktopDateInput from '../../DesktopDateInput'
import DesktopSearchInput from '../../DesktopSearchInput'
import DesktopCheckboxContainer from '../../DesktopCheckboxContainer'
import DesktopNoResultsMessage from '../../DesktopNoResultMessage'
import DesktopBaseTable from '../../DesktopBaseTable'
import { Row, Cell } from 'react-sticky-table'
import { Link } from 'react-router-dom'
import moment from 'moment'

const DesktopInvoiceTable = () => {
  const { itemActionStore } = useStores()

  const [searchWord, setSearchWord] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')

  const clearFields = () => {
    setSearchWord('')
    setStartDate('')
    setEndDate('')
  }

  const findInvoices = (action, searchWord) => {
    const trimmedSearchWord = searchWord.trim().toLowerCase()
    const location = action.location.toLowerCase()
    const address = action.address.toLowerCase()
    const workEntry = action.work.toLowerCase()
    const item = action.item_type.toLowerCase()

    return (
      location.includes(trimmedSearchWord) ||
      address.includes(trimmedSearchWord) ||
      workEntry.includes(trimmedSearchWord) ||
      item.includes(trimmedSearchWord)
    )
  }

  const getInvoices = (status) => (searchWord, startDate, endDate) => {
    const dateSortedActions = itemActionStore
      .itemActionsInGroup(status)
      .filter(
        (action) =>
          action.date_start >= startDate &&
          (endDate && action.date_end
            ? action.date_end <= endDate
            : action.date_end >= endDate)
      )
    return dateSortedActions.filter((action) =>
      findInvoices(action, searchWord)
    )
  }
  const [arrayToFilter, setArrayToFilter] = useState(() => getInvoices())

  const sortBy = [
    {
      prop: 'billing_status_code',
      direction: 1,
    },
    {
      prop: 'location',
      direction: 1,
    },
    {
      prop: 'work',
      direction: 1,
    },
  ]

  const checkBoxItems = [
    {
      name: ItemActionCheckBoxTypes.ALL,
      count: getInvoices()('', startDate, endDate).length,
      action: () => setArrayToFilter(() => getInvoices()),
    },
    {
      name: ItemActionCheckBoxTypes.BILLED,
      count: getInvoices(BillingStatusTypes.BILLED)('', startDate, endDate)
        .length,
      action: () =>
        setArrayToFilter(() => getInvoices(BillingStatusTypes.BILLED)),
    },
    {
      name: ItemActionCheckBoxTypes.NOT_BILLED,
      count: getInvoices(BillingStatusTypes.NOT_BILLED)('', startDate, endDate)
        .length,
      action: () =>
        setArrayToFilter(() => getInvoices(BillingStatusTypes.NOT_BILLED)),
    },
  ]

  return (
    <div className="DesktopInvoiceTable">
      <div className="DesktopInvoiceTable__search">
        <div className="DesktopInvoiceTable__search-fields">
          <DesktopDateInput
            name="Aloituspäivä"
            eventHandler={setStartDate}
            value={startDate}
            max={endDate}
          />
          <DesktopDateInput
            name="Lopetuspäivä"
            eventHandler={setEndDate}
            value={endDate}
            min={startDate}
          />
          <DesktopSearchInput
            name="Hae kohteen, työn tai laitteen nimellä/osoitteella"
            type="text"
            eventHandler={setSearchWord}
            value={searchWord}
            required={false}
          />
          <span
            className="DesktopInvoiceTable__clear-search"
            onClick={() => clearFields()}
          >
            Tyhjennä hakuehdot
          </span>
        </div>
        <DesktopCheckboxContainer
          labels_and_actions={checkBoxItems}
          radio_group="billing_actions_by_status"
          checked={ItemActionCheckBoxTypes.ALL}
        />
      </div>
      {arrayToFilter(searchWord, startDate, endDate).length ? (
        <DesktopBaseTable
          columns={[
            'Laskutettava',
            'Kohde',
            'Tila',
            'Laite',
            'Määrä',
            'Lisännyt työlle',
          ]}
        >
          {arrayToFilter(searchWord, startDate, endDate)
            .sort(compareByMultiplePropNames(sortBy))
            .map((action) => (
              <Row key={action.id}>
                <Cell>
                  <Link to={`/invoicing/${action.work_id}`}>{action.work}</Link>
                </Cell>
                <Cell>
                  {action.location}, {action.address},{' '}
                  {moment(action.date_start).format('D.M.Y')} -{' '}
                  {!action.date_end
                    ? ''
                    : moment(action.date_end).format('D.M.Y')}
                </Cell>
                <Cell
                  className={`cell-collapse cell-style__data--inv${action.billing_status_code}`}
                >
                  {action.billing_status_description}
                </Cell>
                <Cell>{action.item_type}</Cell>
                <Cell classname="cell-collapse">{action.count}</Cell>
                <Cell>{action.added_by}</Cell>
              </Row>
            ))}
        </DesktopBaseTable>
      ) : (
        <DesktopNoResultsMessage
          message={`Tuloksia ei löydetty hakuehdoilla ${searchWord}`}
        />
      )}
    </div>
  )
}

export default DesktopInvoiceTable

import React from 'react'
import PropTypes from 'prop-types'

const DesktopSingleWorkInvoiceIndicator = (props) => {
  return (
    <div
      className={`DesktopSingleWorkInvoiceIndicator DesktopSingleWorkInvoiceIndicator--${props.class}`}
    >
      {props.text}
    </div>
  )
}

DesktopSingleWorkInvoiceIndicator.propTypes = {
  class: PropTypes.string,
  text: PropTypes.string,
}

export default DesktopSingleWorkInvoiceIndicator

import { observer } from 'mobx-react'
import React from 'react'
import { Cell } from 'react-sticky-table'
import { workStateIndicator } from '../../../../helpers/workStateHelper'
import { useStores } from '../../../../hooks/use-stores'

const DesktopSingleLocationWorkTableStatusCell = observer(({ work }) => {
  const { itemStore } = useStores()

  const status = workStateIndicator(work, itemStore)

  return (
    <Cell
      style={{ textAlign: 'center' }}
      className={`cell-collapse cell-style__data--${status.color}`}
    >
      {status.text}
    </Cell>
  )
})

export default DesktopSingleLocationWorkTableStatusCell

import React from 'react'
import DesktopAdminUserModify from './DesktopAdminUserModify'
import DesktopAdminUserCreate from './DesktopAdminUserCreate'
import DesktopAdminUserTable from './DesktopAdminUserTable'
import DesktopPageHeading from '../../DesktopPageHeading'
import { useStores } from '../../../../hooks/use-stores'

const DesktopAdminUsersPage = () => {
  const { userStore } = useStores()
  const isAdmin = !!userStore.user?.admin

  return (
    <div className="DesktopAdminUsersPage">
      <DesktopPageHeading
        title={userStore.user?.admin ? 'Käyttäjät' : 'Käyttäjä'}
        subtitle={isAdmin ? 'Hallitse käyttäjiä' : 'Hallitse käyttäjätiliäsi'}
      />
      <section className="DesktopAdminUsersPage__forms">
        <DesktopAdminUserModify admin={isAdmin} />
        {isAdmin && <DesktopAdminUserCreate />}
      </section>
      <DesktopAdminUserTable admin={isAdmin} />
    </div>
  )
}

export default DesktopAdminUsersPage

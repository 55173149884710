import React from 'react'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { PropTypes } from 'prop-types'

export const createConfirmationPrompt = (
  title,
  message,
  buttonLabelPositive,
  buttonActionPositive,
  buttonLabelNegative
) => {
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className="ConfirmationPrompt">
          <h1 className="ConfirmationPrompt__title">{title}</h1>
          {typeof message === 'string' ? (
            <p className="ConfirmationPrompt__body">{message}</p>
          ) : (
            message.map((messageLine, index) => (
              <p key={`message${index}`} className="ConfirmationPrompt__body">
                {messageLine}
              </p>
            ))
          )}
          <div className="ConfirmationPrompt__buttons">
            <button
              className="DesktopPromptButton DesktopPromptButton--positive"
              onClick={() => {
                buttonActionPositive()
                onClose()
              }}
            >
              {buttonLabelPositive}
            </button>
            <button
              className="DesktopPromptButton DesktopPromptButton--negative"
              onClick={onClose}
            >
              {buttonLabelNegative}
            </button>
          </div>
        </div>
      )
    },
  })
}

createConfirmationPrompt.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string | PropTypes.arrayOf(PropTypes.string),
  buttonLabelPositive: PropTypes.string,
  buttonActionPositive: PropTypes.func,
  buttonLabelNegative: PropTypes.func,
}

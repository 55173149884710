import React, { useState } from 'react'
import { useStores } from '../../hooks/use-stores'
import { SelectInput } from '../SelectInput'
import MobileSmallButton from './MobileSmallButton'
import { observer } from 'mobx-react'
import MobileScanOverlay from './MobileScanOverlay'
import { notifySuccess, notifyError } from '../../helpers/notificationHelpers'
import DesktopBaseTable from '../Desktop/DesktopBaseTable'
import { Row, Cell } from 'react-sticky-table'
import { Link } from 'react-router-dom'
import { ItemStatusTypes } from '../../types/ItemStatusTypes'
import { createConfirmationPrompt } from '../../helpers/confirmationPrompt'
import { createNewLocationPrompt } from '../../helpers/newLocationPrompt'
import { createNewWorkPrompt } from '../../helpers/newWorkPrompt'

const MobileScanInForm = observer(() => {
  const { locationStore, workStore, itemActionStore } = useStores()
  const [selectedLocation, setSelectedLocation] = useState()
  const [selectedWorks, setSelectedWorks] = useState()
  const [selectedWork, setSelectedWork] = useState()
  const [scanOverlayOpen, setScanOverlayOpen] = useState(false)
  const [scannedItems, setScannedItems] = useState([])
  const [saved, setSaved] = useState()

  const handleSelectLocation = (location) => {
    if (location.value === 'new_location') {
      createNewLocationPrompt((id) => {
        if (!id) {
          return
        }
        const newLocation = locationStore.locationById(id)
        handleSelectLocation({
          value: newLocation.name,
          label: newLocation.name,
          location: newLocation,
        })
      })
      if (!selectedLocation) {
        setSelectedLocation(null)
      }
      return
    }
    setSelectedLocation(location)
    setSelectedWork(null)
    const works = workStore
      .worksByLocation(location.location.id)
      .map((work) => ({
        value: work.name,
        label: work.name,
        work,
      }))
      .concat({
        value: 'new_work',
        label: '+ Luo uusi työ',
        style: {
          color: '#617f34',
          borderTop: '1px dashed lightgrey',
        },
      })
    setSelectedWorks(works)
  }

  const locations = locationStore
    .locationsSortedByPropName('name')
    .map((location) => ({
      value: location.name,
      label: location.name,
      location,
    }))
    .concat({
      value: 'new_location',
      label: '+ Luo uusi kohde',
      style: {
        color: '#617f34',
        borderTop: '1px dashed lightgrey',
      },
    })

  function handleSelectWork(work) {
    if (work.value === 'new_work') {
      createNewWorkPrompt(
        'Luo uusi työ',
        `kohteeseen ${selectedLocation.location.name}`,
        selectedLocation.location.id,
        (id) => {
          if (!id) return
          const newWork = workStore.workById(id)
          handleSelectWork({
            value: newWork.name,
            label: newWork.name,
            work: newWork,
          })
        }
      )
      return
    }
    setSelectedWork(work)
  }

  function onGotItem(item) {
    if (item.item_status_id.id === ItemStatusTypes.RESERVED.id) {
      notifyError(`Laite ${item.item_number} on varattu!`)
      return
    }

    if (item.archived) {
      notifyError(`Laite ${item.item_number} on arkistoitu!`)
      return
    }

    if (scannedItems.includes(item)) {
      notifyError(`Laite ${item.item_number} on jo listassa!`)
      return
    }

    setScannedItems((old) => [...old, item])
    notifySuccess(`Lisätty ${item.item_type_id.name}`)
  }

  const saveDisabled = () =>
    scannedItems.length <= 0 || !selectedWorks || !selectedWork

  function saveItems() {
    if (saveDisabled()) return

    createConfirmationPrompt(
      '',
      'Tallennetaanko laitteet työlle?',
      'Tallenna',
      () => {
        itemActionStore
          .scanIn(scannedItems, selectedWork.work.id)
          .then(() => {
            notifySuccess('Laitteet tallennettu')
            setSaved(true)
          })
          .catch(() => {
            notifyError('Ei tallennettu: Jokin meni vikaan')
            // Todo: be more precise
          })
      },
      'Peruuta'
    )
  }

  return (
    <div className="MobileScanInForm">
      {scanOverlayOpen && (
        <MobileScanOverlay
          onClose={() => setScanOverlayOpen(false)}
          onGotItem={onGotItem}
          amount={scannedItems.length}
        />
      )}
      {saved ? (
        <section className="MobileScanInForm__saved-info">
          <p>
            Kohde: <span>{selectedLocation.value}</span>
          </p>
          <p>
            Työ: <span>{selectedWork.value}</span>
          </p>
        </section>
      ) : (
        <>
          <section className="inputs">
            <SelectInput
              name="Kohde"
              options={locations}
              handleSelect={handleSelectLocation}
              placeholder="Valitse kohde"
              value={selectedLocation}
              row
            />
            <SelectInput
              name="Työ"
              options={selectedWorks}
              handleSelect={handleSelectWork}
              placeholder="Valitse työ"
              disabled={!selectedWorks}
              value={selectedWork}
              row
            />
          </section>
          <MobileSmallButton onClick={() => setScanOverlayOpen(true)}>
            SKANNAA / SYÖTÄ LAITE
          </MobileSmallButton>
        </>
      )}
      <section className="ScannedItemsTable">
        <h2 className="ScannedItemsTable__title">
          {saved ? 'Tallennetut' : 'Syötetyt'} laitteet ({scannedItems.length})
        </h2>
        <DesktopBaseTable columns={['Nro', 'Laite']}>
          {scannedItems.map((item) => (
            <Row key={item.item_number}>
              <Cell className="cell-collapse cell-style__monospace">
                {item.item_number}
              </Cell>
              <Cell>{item.item_type_id.name}</Cell>
            </Row>
          ))}
        </DesktopBaseTable>
      </section>
      {saved ? (
        <Link to="/">
          <MobileSmallButton>Palaa etusivulle</MobileSmallButton>
        </Link>
      ) : (
        <MobileSmallButton onClick={saveItems} disabled={saveDisabled()}>
          Tallenna
        </MobileSmallButton>
      )}
    </div>
  )
})

export default MobileScanInForm

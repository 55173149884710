import React, { useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { values } from 'mobx'
import { useStores } from '../../hooks/use-stores'
import { powerSourceTypesSelect } from '../../types/PowerSourceTypes'
import { ItemSpecsTypes } from '../../types/ItemSpecsTypes'
import { notifyError } from '../../helpers/notificationHelpers'
import DesktopSearchInput from './DesktopSearchInput'
import { SelectInput } from '../SelectInput'
import DesktopDateInput from './DesktopDateInput'
import DesktopLinkButton from './DesktopLinkButton'
import DesktopFormButton from './DesktopFormButton'
import { warehouseOptionsSelect } from '../../types/WarehouseTypes'

const DesktopCreateItemForm = (props) => {
  const today = moment(Date.now()).format('yyyy-MM-DD')

  const { itemTypeStore, itemStore } = useStores()
  const { itemByNumber } = itemStore

  const [itemNumber, setItemNumber] = useState(
    !props.item ? '' : props.item.item_number
  )
  const [itemSerial, setItemSerial] = useState(
    !props.item ? '' : props.item.item_serial
  )
  const [itemModel, setItemModel] = useState(
    !props.item ? '' : props.item.item_model
  )
  const [itemType, setItemType] = useState(
    !props.item
      ? ''
      : {
          value: props.item.item_type_id.id,
          label: props.item.item_type_id.name,
        }
  )
  const [powerSource, setPowerSource] = useState(
    !props.item
      ? ''
      : {
          value: props.item.power_source,
          label: powerSourceTypesSelect.find(
            (type) => type.value === props.item.power_source
          ).label,
        }
  )
  const [maxPower, setMaxPower] = useState(
    !props.item ? '' : props.item.power_consume
  )
  const [buyDate, setBuyDate] = useState(!props.item ? '' : props.item.buy_date)
  const [buyPrice, setBuyPrice] = useState(
    !props.item ? '' : props.item.buy_price
  )

  const [warehouse, setWarehouse] = useState(
    !props.item
      ? warehouseOptionsSelect[0]
      : { value: props.item.warehouse, label: props.item.warehouse }
  )

  const disableSubmit = () => {
    if (props.item) {
      const disableSubmit =
        itemNumber === props.item.item_number &&
        itemSerial === props.item.item_serial &&
        itemModel === props.item.item_model &&
        itemType.value === props.item.item_type_id.id &&
        powerSource.value === props.item.power_source &&
        maxPower === props.item.power_consume &&
        buyDate === props.item.buy_date &&
        buyPrice === props.item.buy_price &&
        warehouse.value === props.item.warehouse
      return disableSubmit
    }
  }

  const item = {
    item_number: itemNumber,
    item_serial: itemSerial,
    item_model: itemModel,
    buy_date: buyDate,
    buy_price: buyPrice,
    item_type_id: itemType.value,
    power_source: powerSource.value,
    power_consume: maxPower,
    warehouse: warehouse.value,
  }

  const types = values(itemTypeStore.item_types).map((type) => ({
    value: type.id,
    label: type.name,
    type,
  }))

  const handleItemTypeSelect = (selectedOption) => {
    setItemType(selectedOption)
  }

  const handlePowerSourceSelect = (selectedOption) => {
    setPowerSource(selectedOption)
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    const existingItem = itemByNumber(itemNumber)

    if (existingItem && props.item && existingItem.id !== props.item.id) {
      return notifyError('Laitetta ei päivitetty: laitenumero on jo käytössä')
    }

    if (existingItem && !props.item) {
      return notifyError('Laitetta ei lisätty: laitenumero on jo käytössä')
    }

    if (!itemType) {
      return notifyError('Laitetta ei käsitelty: lisää laitetyyppi')
    }

    if (!powerSource) {
      return notifyError('Laitetta ei käsitelty: lisää virtalähde')
    }

    if (!buyDate) {
      return notifyError('Laitetta ei käsitelty: lisää hankintapäivä')
    }

    if (!warehouse) {
      return notifyError('Laitetta ei käsitelty: valitse varastosijainti')
    }

    return props.action(item)
  }

  return (
    <form className="DesktopCreateItemForm" onSubmit={handleSubmit}>
      <div className="DesktopCreateItemForm__fields">
        <SelectInput
          name={`${ItemSpecsTypes.warehouse}:`}
          options={warehouseOptionsSelect}
          handleSelect={setWarehouse}
          value={warehouse}
        />
        <DesktopSearchInput
          name={`${ItemSpecsTypes.item_number}:`}
          eventHandler={setItemNumber}
          value={itemNumber}
          type="text"
          required={true}
        />
        <DesktopSearchInput
          name={`${ItemSpecsTypes.item_serial}:`}
          eventHandler={setItemSerial}
          value={itemSerial}
          type="text"
          required={true}
        />
        <DesktopSearchInput
          name={`${ItemSpecsTypes.item_model}:`}
          eventHandler={setItemModel}
          value={itemModel}
          type="text"
          required={true}
        />
        <SelectInput
          name={`${ItemSpecsTypes.item_type}:`}
          options={types}
          handleSelect={handleItemTypeSelect}
          value={itemType}
        />
        <SelectInput
          name={`${ItemSpecsTypes.power_source}:`}
          options={powerSourceTypesSelect}
          handleSelect={handlePowerSourceSelect}
          value={powerSource}
        />
        <DesktopSearchInput
          name={`${ItemSpecsTypes.power_consume}:`}
          eventHandler={setMaxPower}
          value={maxPower}
          type="number"
          min="0"
          required={true}
        />
        <DesktopDateInput
          name={`${ItemSpecsTypes.buy_date}:`}
          eventHandler={setBuyDate}
          value={buyDate}
          max={today}
        />
        <DesktopSearchInput
          name={`${ItemSpecsTypes.buy_price}:`}
          eventHandler={setBuyPrice}
          value={buyPrice}
          type="number"
          min="0"
          required={true}
        />
        <div className="DesktopCreateItemForm__buttons">
          <DesktopFormButton title={props.title} disabled={disableSubmit()} />
          <DesktopLinkButton title="Peruuta" path="/items" />
        </div>
      </div>
    </form>
  )
}

DesktopCreateItemForm.propTypes = {
  action: PropTypes.func,
  title: PropTypes.string,
  item: PropTypes.object,
}

export default DesktopCreateItemForm

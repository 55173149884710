import React from 'react'
import { NavLink } from 'react-router-dom'
import DesktopInvoiceIndicatorCounter from './DesktopNavigation/DesktopInvoiceIndicatorCounter'
import { useStores } from '../../hooks/use-stores'

const DesktopMobileNav = () => {
  const closeMenu = () => {
    setTimeout(() => {
      const menu = document.querySelector('#toggle')
      menu.click()
    })
  }

  const inWorksOrLocations = (match, location) =>
    /^\/(works)|(locations)/.test(location.pathname)
  const inItemsNotItemTypesOrUtilization = (match, location) =>
    /.*(?:\/items)(?!.*types)(?!.*utilization)/.test(location.pathname)

  const { userStore } = useStores()

  return (
    <nav className="DesktopMobileNav" role="navigation">
      <div id="menuToggle">
        <input type="checkbox" id="toggle" />
        <span></span>
        <span></span>
        <span></span>
        <ul id="menu">
          <NavLink
            to="/"
            className="desktop-mobile-navigation-item"
            activeClassName="desktop-mobile-navigation-item--active"
            exact
            onClick={closeMenu}
          >
            Etusivu
          </NavLink>
          <NavLink
            to="/locations"
            className="desktop-mobile-navigation-item"
            activeClassName="desktop-mobile-navigation-item--active"
            isActive={inWorksOrLocations}
            onClick={closeMenu}
          >
            Kohteet
          </NavLink>
          <NavLink
            to="/invoicing"
            className="desktop-mobile-navigation-item"
            activeClassName="desktop-mobile-navigation-item--active"
            onClick={closeMenu}
          >
            Laskutus
            <DesktopInvoiceIndicatorCounter mobile />
          </NavLink>
          <NavLink
            to="/users"
            className="desktop-mobile-navigation-item"
            activeClassName="desktop-mobile-navigation-item--active"
            onClick={closeMenu}
          >
            Käyttäjä{userStore.user?.admin ? 't' : ''}
          </NavLink>
          <NavLink
            to="/items"
            className="desktop-mobile-navigation-item"
            activeClassName={'desktop-mobile-navigation-item--active'}
            isActive={inItemsNotItemTypesOrUtilization}
            onClick={closeMenu}
          >
            Laitteet
          </NavLink>
          <NavLink
            to="/items/utilization"
            className="desktop-mobile-navigation-item"
            activeClassName="desktop-mobile-navigation-item--active"
            onClick={closeMenu}
          >
            Käyttöaste
          </NavLink>
          <NavLink
            to="/items/types"
            className="desktop-mobile-navigation-item"
            activeClassName="desktop-mobile-navigation-item--active"
            onClick={closeMenu}
          >
            Laitetyypit
          </NavLink>
        </ul>
      </div>
    </nav>
  )
}

export default DesktopMobileNav

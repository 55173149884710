import React from 'react'
import DesktopPageHeading from '../../DesktopPageHeading'
import DesktopActionButton from '../../DesktopActionButton'
import DesktopLocationTable from './DesktopLocationTable'
import { createNewLocationPrompt } from '../../../../helpers/newLocationPrompt'
import { useHistory } from 'react-router-dom'

const DesktopLocationPage = () => {
  const history = useHistory()
  return (
    <div className="DesktopLocationPage">
      <DesktopPageHeading
        title="Kohteet"
        subtitle="Hallitse ja lisää kohteita"
      />
      <div className="DesktopLocationPage__buttons">
        <DesktopActionButton
          title="Lisää kohde"
          action={() =>
            createNewLocationPrompt((id) => {
              id && history.push('/locations/' + id)
            })
          }
        />
      </div>
      <DesktopLocationTable />
    </div>
  )
}

export default DesktopLocationPage

import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { useStores } from '../../../../hooks/use-stores'
import { findValue } from '../../../../helpers/filterHelpers'
import { WorkStatusTypes } from '../../../../types/WorkStatusTypes'
import { ItemStatusTypes } from '../../../../types/ItemStatusTypes'
import { LocationCheckBoxTypes } from '../../../../types/LocationCheckBoxTypes'
import { compareByPropName } from '../../../../utils/sortUtils'
import DesktopSearchInput from '../../DesktopSearchInput'
import DesktopCheckboxContainer from '../../DesktopCheckboxContainer'
import DesktopNoResultsMessage from '../../DesktopNoResultMessage'
import DesktopBaseTable from '../../DesktopBaseTable'
import { Row, Cell } from 'react-sticky-table'
import { Link } from 'react-router-dom'
import DesktopProgressStatusCell from './DesktopProgressStatusCell'
import DesktopTableArchiveCell from '../../DesktopTableArchiveCell'

const DesktopLocationTable = observer(() => {
  const { itemStore, locationStore, workStore } = useStores()
  const {
    locationsByWorkStatus,
    locationsInactive,
    worksByLocation,
  } = workStore
  const {
    itemCountByStatusInLocation,
    locationsByItemStatus,
    itemCountByStatusInWork,
  } = itemStore
  const { archivedLocations, notArchivedLocations } = locationStore
  const [arrayToFilter, setArrayToFilter] = useState(notArchivedLocations)
  const [locationSearchWord, setlocationSearchWord] = useState('')

  const checkBoxItems = [
    {
      name: LocationCheckBoxTypes.ACTIVE,
      count: locationsByWorkStatus(WorkStatusTypes.ACTIVE.id, false).length,
      action: () =>
        setArrayToFilter(
          locationsByWorkStatus(WorkStatusTypes.ACTIVE.id, false)
        ),
    },
    {
      name: LocationCheckBoxTypes.INACTIVE,
      count: locationsInactive.length,
      action: () => setArrayToFilter(locationsInactive),
    },
    {
      name: LocationCheckBoxTypes.ALL,
      count: notArchivedLocations.length,
      action: () => setArrayToFilter(notArchivedLocations),
    },
    {
      name: LocationCheckBoxTypes.PICKUP,
      count: locationsByItemStatus(ItemStatusTypes.PICKUP.id, false).length,
      action: () =>
        setArrayToFilter(
          locationsByItemStatus(ItemStatusTypes.PICKUP.id, false)
        ),
    },
    {
      name: LocationCheckBoxTypes.ARCHIVED,
      count: archivedLocations.length,
      action: () => setArrayToFilter(archivedLocations),
    },
  ]

  const filteredLocations = () => {
    const filteredArray = arrayToFilter.filter(
      (location) =>
        findValue(location.name, locationSearchWord) ||
        findValue(location.address, locationSearchWord)
    )
    return filteredArray.sort(compareByPropName('address'))
  }

  const getLocationStatus = (location) => {
    if (location.archived) {
      return [null, null]
    }

    const works = worksByLocation(location.id)
    if (works.length === 0) {
      return ['Ei töitä', 0]
    }
    const pickup = works.filter(
      (work) =>
        itemCountByStatusInWork(work.id, ItemStatusTypes.PICKUP.id) > 0 &&
        work.work_status_id.id === WorkStatusTypes.INACTIVE.id
    )
    const done = works.filter(
      (work) => work.work_status_id.id === WorkStatusTypes.INACTIVE.id
    )
    const status =
      Math.round((100 * (done.length - pickup.length)) / works.length) || 0
    const secondaryStatus =
      Math.round((100 * pickup.length) / works.length) || 0

    return [status, secondaryStatus]
  }

  return (
    <div className="DesktopLocationTable">
      <div className="DesktopLocationTable__search">
        <DesktopSearchInput
          name="Hae kohteen osoitteella tai nimellä"
          type="text"
          eventHandler={setlocationSearchWord}
          value={locationSearchWord}
          required={false}
        />
        <DesktopCheckboxContainer
          labels_and_actions={checkBoxItems}
          checked={LocationCheckBoxTypes.ALL}
          radio_group="locations_by_status"
        />
      </div>

      {filteredLocations().length ? (
        <DesktopBaseTable
          columns={[
            'Kohde',
            'Tila',
            'Varatut laitteet',
            'Noudettavat laitteet',
          ]}
        >
          {filteredLocations().map((location) => {
            const [status, secondaryStatus] = getLocationStatus(location)
            return (
              <Row key={location.id}>
                <Cell>
                  <Link to={`/locations/${location.id}`}>
                    {location.address} – {location.name}
                  </Link>
                </Cell>
                {status === null ? (
                  <DesktopTableArchiveCell text="Arkistoitu" />
                ) : (
                  <DesktopProgressStatusCell
                    status={status}
                    secondaryStatus={secondaryStatus}
                  />
                )}
                <Cell className="cell-collapse">
                  {itemCountByStatusInLocation(
                    location.id,
                    ItemStatusTypes.RESERVED.id
                  )}
                </Cell>
                <Cell className="cell-collapse">
                  {itemCountByStatusInLocation(
                    location.id,
                    ItemStatusTypes.PICKUP.id
                  )}
                </Cell>
              </Row>
            )
          })}
        </DesktopBaseTable>
      ) : (
        <DesktopNoResultsMessage
          message={`Kohteita ei löydetty hakuehdoilla ${locationSearchWord}`}
        />
      )}
    </div>
  )
})

export default DesktopLocationTable

import React from 'react'
import { useStores } from '../../../hooks/use-stores'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'

const DesktopInvoiceIndicatorCounter = observer((props) => {
  const { itemActionStore } = useStores()

  const counter = itemActionStore.itemActionsInGroupCountUnbilled()

  if (counter > 0) {
    return (
      <div
        className={`DesktopInvoiceIndicatorCounter DesktopInvoiceIndicatorCounter--billing`}
      >
        {counter}
      </div>
    )
  } else {
    return null
  }
})

DesktopInvoiceIndicatorCounter.propTypes = {
  mobile: PropTypes.bool,
}

export default DesktopInvoiceIndicatorCounter

import React from 'react'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { observer } from 'mobx-react'
import { MobileContainer } from './components/Mobile/MobileContainer'
import DesktopContainer from './components/Desktop/DesktopContainer'
import { useStores } from './hooks/use-stores'
import LoginPage from './components/LoginPage/LoginPage'
import axios from 'axios'

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('auth-token')
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token
    }
    // config.headers['Content-Type'] = 'application/json';
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

/**
 * We are using react context to provide stores to components.
 * Using this rather than ye olde method of injecting stores.
 */

toast.configure()

const App = observer(() => {
  const { userStore } = useStores()

  if (!userStore.loggedIn || !userStore.user) {
    return <LoginPage />
  }

  const hostName = window.location.hostname

  return (
    <div className="App">
      {hostName === process.env.REACT_APP_MANAGEMENT_DOMAIN ? (
        <DesktopContainer />
      ) : hostName === process.env.REACT_APP_SCANNING_DOMAIN ? (
        <MobileContainer />
      ) : null}
    </div>
  )
})

export default App

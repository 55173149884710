import React, { useState } from 'react'
import { observer } from 'mobx-react'
import MainButton from './MainButton'
import { notifyError } from '../../helpers/notificationHelpers'
import { requestState } from '../../helpers/requestState'
import { useStores } from '../../hooks/use-stores'

const LoginForm = observer(() => {
  const { userStore } = useStores()
  const { userState, login } = userStore

  const [email, setEmail] = useState()
  const [password, setPassword] = useState()

  const handleLogin = (event) => {
    event.preventDefault()
    login(email, password).catch((error) => {
      notifyError('Väärä sähköposti tai salasana')
    })
  }

  return (
    <form onSubmit={handleLogin} className="LoginForm">
      <label>
        Sähköpostiosoite
        <input
          onChange={(event) => {
            setEmail(event.target.value)
          }}
          type="text"
          required
        />
      </label>
      <label>
        Salasana
        <input
          onChange={(event) => {
            setPassword(event.target.value)
          }}
          type="password"
          required
        />
        <MainButton
          disabled={userState === requestState.LOADING}
          title="Kirjaudu sisään"
        />
      </label>
    </form>
  )
})

export default LoginForm

import React, { useState } from 'react'
import { useStores } from '../../../../hooks/use-stores'
import { observer } from 'mobx-react'
import DesktopSearchInput from '../../DesktopSearchInput'
import DesktopNoResultsMessage from '../../DesktopNoResultMessage'
import { Row, Cell } from 'react-sticky-table'
import DesktopDeleteButtonTable from '../../DesktopDeleteButtonTable'
import DesktopBaseTable from '../../DesktopBaseTable'
import {
  notifySuccess,
  notifyError,
} from '../../../../helpers/notificationHelpers'
import { requestState } from '../../../../helpers/requestState'
import { UserIdTypes } from '../../../../types/UserIdTypes'
import { createConfirmationPrompt } from '../../../../helpers/confirmationPrompt'
import PropTypes from 'prop-types'

const DesktopAdminUserTable = observer(({ admin }) => {
  const { userStore } = useStores()
  const { filteredUsers, deleteUser } = userStore
  const [userSearchWord, setUserSearchWord] = useState('')

  const sendDeleteUser = async (id, name) => {
    if (id === UserIdTypes.ADMIN) {
      return notifyError(`Käyttäjää ${name} ei ole mahdollista poistaa`)
    }

    await deleteUser(id)

    if (userStore.userState === requestState.DELETED) {
      notifySuccess(`Käyttäjä ${name} poistettu`)
    } else {
      notifyError(`Käyttäjää ${name} ei voitu poistaa`)
    }
  }

  const confirmAndDelete = (id, name) =>
    createConfirmationPrompt(
      'Poista käyttäjä',
      `Oletko varma, että haluat poistaa käyttäjän ${name}?`,
      'Kyllä',
      () => sendDeleteUser(id, name),
      'Ei'
    )

  return (
    <div className="DesktopAdminUserTable">
      {admin && (
        <DesktopSearchInput
          name="Hae käyttäjiä nimellä"
          type="text"
          eventHandler={setUserSearchWord}
          value={userSearchWord}
          required={false}
        />
      )}
      {filteredUsers(userSearchWord).length ? (
        <div className="DesktopAdminUserTable__table">
          <DesktopBaseTable
            columns={
              admin ? ['Poista', 'Nimi', 'Sähköposti'] : ['Nimi', 'Sähköposti']
            }
          >
            {filteredUsers(userSearchWord).map((user) => (
              <Row key={user.id}>
                {admin && (
                  <Cell className="cell-collapse delete-user">
                    <DesktopDeleteButtonTable
                      action={() => confirmAndDelete(user.id, user.name)}
                    />
                  </Cell>
                )}
                <Cell>{user.name}</Cell>
                <Cell>{user.email}</Cell>
              </Row>
            ))}
          </DesktopBaseTable>
        </div>
      ) : (
        <DesktopNoResultsMessage
          message={`Käyttäjiä ei löydetty hakusanalla ${userSearchWord}`}
        />
      )}
    </div>
  )
})

DesktopAdminUserTable.propTypes = {
  admin: PropTypes.bool,
}

export default DesktopAdminUserTable

import React from 'react'
import PropTypes from 'prop-types'
import DesktopPageHeading from '../../DesktopPageHeading'
import DesktopEditWorkForm from './DesktopEditWorkForm'

const DesktopWorkEditPage = (props) => {
  return (
    <div className="DesktopWorkEditPage">
      <DesktopPageHeading title={props.work.name} subtitle="Muokkaa työtä" />
      <DesktopEditWorkForm work={props.work} />
    </div>
  )
}

DesktopWorkEditPage.propTypes = {
  work: PropTypes.object,
}

export default DesktopWorkEditPage

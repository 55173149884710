import React, { useState } from 'react'
import DesktopPageHeading from '../../DesktopPageHeading'
import { useHistory } from 'react-router-dom'
import { requestState } from '../../../../helpers/requestState'
import {
  notifyError,
  notifySuccess,
} from '../../../../helpers/notificationHelpers'
import { useStores } from '../../../../hooks/use-stores'
import DesktopCreateItemForm from '../../DesktopCreateItemForm'
import DesktopCreateSubcontractorItemForm from './DesktopCreateSubcontractorItemForm'
import { CreateItemCheckBoxTypes } from '../../../../types/CreateItemCheckBoxTypes'
import DesktopCheckboxContainer from '../../DesktopCheckboxContainer'

const DesktopCreateItemPage = () => {
  const history = useHistory()
  const [formToShow, setFormToShow] = useState(1)

  const { itemStore } = useStores()
  const { createItem } = itemStore

  const checkBoxItems = [
    {
      name: CreateItemCheckBoxTypes.OWN,
      count: null,
      action: () => setFormToShow(1),
    },
    {
      name: CreateItemCheckBoxTypes.SUBCONTRACTOR,
      count: null,
      action: () => setFormToShow(2),
    },
  ]

  const createNewItem = async (item) => {
    await createItem(item)

    if (itemStore.itemState === requestState.CREATED) {
      notifySuccess(`Laite ${item.item_model} lisätty`)
      return history.push('/items')
    } else {
      return notifyError(`Laitetta ${item.item_model} ei voitu lisätä`)
    }
  }

  return (
    <div className="DesktopCreateItemPage">
      <DesktopPageHeading
        title="Luo uusi laite"
        subtitle="Luo ja lisää uusi laite varastoon"
      />
      <DesktopCheckboxContainer
        labels_and_actions={checkBoxItems}
        radio_group="items_to_create"
        checked={CreateItemCheckBoxTypes.OWN}
      />
      {formToShow === 1 ? (
        <DesktopCreateItemForm action={createNewItem} title="Luo laite" />
      ) : (
        <DesktopCreateSubcontractorItemForm />
      )}
    </div>
  )
}

export default DesktopCreateItemPage

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import moment from 'moment'
import DesktopPageHeading from '../DesktopPageHeading'
import { useHistory } from 'react-router-dom'
import { useStores } from '../../../hooks/use-stores'
import { createConfirmationPrompt } from '../../../helpers/confirmationPrompt'
import { requestState } from '../../../helpers/requestState'
import {
  checkSuccess,
  notifyError,
  notifySuccess,
} from '../../../helpers/notificationHelpers'
import { ButtonClassTypes } from '../../../types/ButtonClassTypes'
import { ItemSpecsTypes } from '../../../types/ItemSpecsTypes'
import { ItemStatusTypes } from '../../../types/ItemStatusTypes'
import DesktopInformationBox from '../DesktopInformationBox'
import DesktopLinkButton from '../DesktopLinkButton'
import DesktopActionButton from '../DesktopActionButton'
import DesktopNoResultsMessage from '../DesktopNoResultMessage'
import LoadingSpinner from '../../LoadingSpinner'
import QRSticker from '../../QRSticker'
import { values } from 'mobx'

const DesktopSingleItemPage = observer((props) => {
  const history = useHistory()
  const { itemActionStore, itemStore } = useStores()
  const {
    latestItemActionByItem,
    scanOut,
    openItemActionsByItem,
  } = itemActionStore
  const { patchItem } = itemStore
  const today = moment(Date.now()).format('yyyy-MM-DD')

  const [isArchiving, setIsArchiving] = useState(false)
  const [loadingText, setLoadingText] = useState('')

  if (isArchiving) {
    return (
      <LoadingSpinner
        size={100}
        title={loadingText}
        class="centered"
        loading={isArchiving}
      />
    )
  }

  // props related variables
  const latestItemAction = latestItemActionByItem(props.item.id)
  const isArchived = props.item.archived
  const itemId = props.item.id
  const isSubcontractorItem = props.item.is_subcontractor
  const itemNumber = isSubcontractorItem
    ? ItemStatusTypes.SUBCONTRACTOR.description
    : props.item.item_number
  const itemSerial = isSubcontractorItem
    ? ItemStatusTypes.SUBCONTRACTOR.description
    : props.item.item_serial
  const itemModel = isSubcontractorItem
    ? ItemStatusTypes.SUBCONTRACTOR.description
    : props.item.item_model
  const itemType = props.item.item_type_id.name
  const itemStatus = props.item.item_status_id.id
  const itemBuyDate = props.item.buy_date
  const workId = props.item.work_entry_id
    ? props.item.work_entry_id.id ??
      values(props.item.work_entry_id).map((entry) => entry.id)
    : ''
  const workName = props.item.work_entry_id
    ? props.item.work_entry_id.name ??
      values(props.item.work_entry_id).map((entry) => entry.name)
    : ''
  const warehouse = props.item.warehouse

  // Send request to archive item
  const sendArchiveItem = async () => {
    setIsArchiving(true)
    setLoadingText('Arkistoidaan laitetta')

    const archivedItem = { archived: today }

    await patchItem(archivedItem, itemId)
    const isSuccess = itemStore.itemState === requestState.UPDATED

    if (isSuccess) {
      notifySuccess(`Laite ${itemNumber} - ${itemType} arkistoitu`)
      return history.push('/items')
    } else {
      setIsArchiving(false)
      return notifyError(
        `Laitetta ${itemNumber} - ${itemType} ei voitu arkistoida`
      )
    }
  }

  // Confirm before request to archive item
  const confirmAndArchive = () =>
    createConfirmationPrompt(
      'Arkistoi laite',
      `Oletko varma, että haluat arkistoida laitteen ${itemNumber} - ${itemType}?`,
      'Kyllä',
      sendArchiveItem,
      'Ei'
    )

  // Validate item before opening prompt
  const validateArchiving = () => {
    if (isSubcontractorItem) {
      const openActionsForItem = openItemActionsByItem(itemId)

      if (openActionsForItem.length) {
        return notifyError(
          `Alihankkijan laitetta ei voitu arkistoida: vapauta ensin laite työltä ${openActionsForItem[0].work_entry_id.name}`
        )
      }
    }

    if (itemStatus !== ItemStatusTypes.IN_WAREHOUSE.id) {
      return notifyError(
        'Laitetta ei voitu arkistoida: vapauta ensin laite työltä'
      )
    }

    return confirmAndArchive()
  }

  // Send request to unarchive item
  const sendUnArchiveItem = async () => {
    setIsArchiving(true)
    setLoadingText('Palautetaan laitetta arkistosta')

    const archivedItem = { archived: null }

    await patchItem(archivedItem, itemId)
    const isSuccess = itemStore.itemState === requestState.UPDATED

    if (isSuccess) {
      notifySuccess(`Laite ${itemNumber} - ${itemType} palautettu arkistosta`)
      return history.push('/items')
    } else {
      setIsArchiving(false)
      return notifyError(
        `Laitetta ${itemNumber} - ${itemType} ei voitu palauttaa arkistosta`
      )
    }
  }

  // Confirm before request to unarchive item
  const confirmAndUnArchive = () => {
    createConfirmationPrompt(
      'Palauta laite arkistosta',
      `Oletko varma, että haluat palauttaa laitteen ${itemNumber} - ${itemType} arkistosta?`,
      'Kyllä',
      sendUnArchiveItem,
      'Ei'
    )
  }

  const confirmAndScanOut = () =>
    createConfirmationPrompt(
      'Siirrä laite varastoon',
      `Siirretäänkö laite ${itemNumber} - ${itemType} varastoon työltä ${workName}?`,
      'Kyllä',
      scanOutItem,
      'Ei'
    )

  const scanOutItem = async () => {
    if (latestItemAction.date_start > today) {
      return notifyError(
        `Laitetta ei voitu merkitä noudetuksi: aloituspäivä työllä on ${moment(
          latestItemAction.date_start
        ).format('D.M.Y')}`,
        4000
      )
    }

    const items = [props.item]

    await scanOut(items, workId)
    const isSuccess = itemActionStore.itemActionState === requestState.UPDATED

    return checkSuccess(
      isSuccess,
      'Laite noudettu työmaalta',
      'Laitetta ei voitu merkitä noudetuksi'
    )
  }

  let owner
  try {
    owner = props.item.user_id.name
  } catch (error) {
    owner = 'käyttäjä poistettu'
  }

  const options = [
    {
      title: ItemSpecsTypes.warehouse,
      data: warehouse,
    },
    {
      title: ItemSpecsTypes.item_number,
      data: itemNumber,
    },
    {
      title: ItemSpecsTypes.item_serial,
      data: itemSerial,
    },
    {
      title: ItemSpecsTypes.item_model,
      data: itemModel,
    },
    {
      title: ItemSpecsTypes.handling_date,
      data: moment(latestItemAction.updated_at).format('D.M.Y'),
    },
    {
      title: ItemSpecsTypes.handled_by,
      data: owner,
    },
    {
      title: ItemSpecsTypes.work_entry,
      data: workName,
      path: Array.isArray(workId)
        ? workId.map((id) => `works/${id}`)
        : `works/${workId}`,
    },
    {
      title: ItemSpecsTypes.item_status,
      data: props.item.item_status_id.description,
    },
    {
      title: ItemSpecsTypes.item_type,
      data: itemType,
    },
    {
      title: ItemSpecsTypes.power_source,
      data: props.item.power_source,
    },
    {
      title: ItemSpecsTypes.power_consume,
      data: props.item.power_consume,
    },
    {
      title: ItemSpecsTypes.buy_date,
      data: itemBuyDate ? moment(props.item.buy_date).format('D.M.Y') : '',
    },
    {
      title: ItemSpecsTypes.buy_price,
      data: props.item.buy_price,
    },
  ]

  return (
    <div className="DesktopSingleItemPage">
      <DesktopPageHeading
        title={props.item.item_type_id.name}
        subtitle="Laitteen tiedot"
      />
      <DesktopInformationBox options={options} />
      {isArchived ? (
        <div className="DesktopSingleItemPage__buttons">
          <DesktopActionButton title="Palauta" action={confirmAndUnArchive} />
          <DesktopNoResultsMessage
            message={`Laite on arkistoitu ${moment(isArchived).format(
              'D.M.Y'
            )}`}
          />
        </div>
      ) : (
        <div className="DesktopSingleItemPage__buttons">
          {workId && !workId.length ? (
            <DesktopActionButton title="Varastoon" action={confirmAndScanOut} />
          ) : null}
          {isSubcontractorItem ? null : (
            <DesktopLinkButton
              title="Muokkaa"
              path={`/items/${props.item.id}/edit`}
              class={ButtonClassTypes.MODIFY}
            />
          )}
          <DesktopActionButton
            title="Arkistoi"
            action={validateArchiving}
            class={ButtonClassTypes.ARCHIVE}
          />
        </div>
      )}
      <QRSticker item_number={props.item.item_number} />
    </div>
  )
})

DesktopSingleItemPage.propTypes = {
  item: PropTypes.object,
}

export default DesktopSingleItemPage

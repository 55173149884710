import React from 'react'
import { Cell } from 'react-sticky-table'
import PropTypes from 'prop-types'

const DesktopProgressStatusCell = ({ status, secondaryStatus = 0 }) => {
  return (
    <Cell
      style={{
        textAlign: 'center',
        backgroundImage:
          typeof status == 'number'
            ? 'linear-gradient( to right, #617f34, #617f34 ' +
              status +
              '%, #ae6d04 ' +
              status +
              '%, #ae6d04 ' +
              (secondaryStatus + status) +
              '%, #c32235 ' +
              (secondaryStatus + status) +
              '%, #c32235)'
            : '',
        width: '7em',
      }}
      className={
        typeof status == 'number'
          ? 'cell-style__data--' + (status === 1 ? 3 : 2)
          : ''
      }
    >
      {typeof status == 'number' ? (
        <div>{status}&nbsp;%</div>
      ) : (
        <div>{status}</div>
      )}
    </Cell>
  )
}

DesktopProgressStatusCell.propTypes = {
  status: PropTypes.number.isRequired,
  secondaryStatus: PropTypes.number,
}

export default DesktopProgressStatusCell

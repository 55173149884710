import { types, flow, getParent } from 'mobx-state-tree'
import { values } from 'mobx'
import axios from 'axios'
import { API_BASE } from '../helpers/urlHelpers'
import { requestState } from '../helpers/requestState'
import { WorkStatusTypes } from '../types/WorkStatusTypes'
import { Work } from '../models/Work'

export const WorkStore = types
  .model('WorkStore', {
    works: types.map(Work, {}),
    workState: types.optional(types.string, requestState.NONE),
  })
  .views((self) => ({
    get locationsInactive() {
      // All the locations which do not have ANY active works && are not archived
      return values(getParent(self).locationStore.locations).filter(
        (location) =>
          !self.worksByStatusInLocation(location.id, WorkStatusTypes.ACTIVE.id)
            .length && !location.archived
      )
    },
    workById(id) {
      return values(self.works).find((work) => work.id === id)
    },
    // Works of location: not archived or archived
    worksByLocation(locationId, is_archived = false) {
      return values(self.works).filter(
        (work) =>
          work.location_id.id === locationId &&
          Boolean(work.archived) === is_archived
      )
    },
    // All the works of given status in location
    worksByStatusInLocation(locationId, status, include_archived = false) {
      return values(self.works).filter((work) => {
        if (!include_archived && work.archived) {
          return false
        } else {
          return (
            work.location_id.id === locationId &&
            work.work_status_id.id === status
          )
        }
      })
    },
    locationsByWorkStatus(status, is_archived = false) {
      // All the locations which have works of given status
      return values(getParent(self).locationStore.locations).filter(
        (location) =>
          self.worksByStatusInLocation(location.id, status).length &&
          Boolean(location.is_archived) === is_archived
      )
    },
  }))
  .actions((self) => ({
    fetchWorks: flow(function* () {
      self.workState = requestState.LOADING

      try {
        const response = yield axios.get(API_BASE + '/work_entries')

        if (response.status === 200) {
          response.data.forEach((work) => {
            self.works.put(work)
          })
          self.workState = requestState.LOADED
        }
      } catch (error) {
        self.workState = requestState.ERROR
      }
    }),
    createWork: flow(function* createWork(work) {
      self.workState = requestState.LOADING

      try {
        const response = yield axios.post(API_BASE + '/work_entries', work)

        if (response.status === 201) {
          self.works.put(response.data)
          self.workState = requestState.CREATED
          return response.data.id
        }
      } catch (error) {
        self.workState = requestState.ERROR
      }
    }),
    updateWork: flow(function* updateWork(workId, work) {
      self.workState = requestState.LOADING

      try {
        const response = yield axios.patch(
          API_BASE + `/work_entries/${workId}`,
          work
        )

        if (response.status === 200) {
          self.works.put(response.data)
          self.workState = requestState.UPDATED
        }
      } catch (error) {
        self.workState = requestState.ERROR
      }
    }),
  }))

import React from 'react'
import PropTypes from 'prop-types'

const DesktopSearchInput = (props) => {
  const randomInt = Math.floor(Math.random() * 100)

  return (
    <div className="DesktopSearchInput">
      <label
        className="DesktopSearchInput__label"
        htmlFor={props.name + randomInt}
      >
        {props.name}
      </label>
      <input
        className="DesktopSearchInput__input"
        type={props.type}
        id={props.name + randomInt}
        value={props.value}
        required={props.required}
        onChange={(e) => props.eventHandler(e.target.value)}
        min={props.min}
        max={props.max}
        disabled={props.disabled}
      />
    </div>
  )
}

DesktopSearchInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  min: PropTypes.string,
  max: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.bool,
  eventHandler: PropTypes.func,
  disabled: PropTypes.bool,
}

export default DesktopSearchInput

import React from 'react'
import PropTypes from 'prop-types'

const DesktopTableReleaseButton = (props) => {
  return (
    <button
      className={`DesktopTableReleaseButton DesktopTableReleaseButton--${props.item_status}`}
      onClick={() => props.action()}
      disabled={props.disabled}
    >
      {props.text}
    </button>
  )
}

DesktopTableReleaseButton.propTypes = {
  action: PropTypes.func,
  disabled: PropTypes.bool,
  item_status: PropTypes.number,
  text: PropTypes.string,
}

export default DesktopTableReleaseButton

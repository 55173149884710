import React from 'react'
import Select, { components } from 'react-select'
import PropTypes from 'prop-types'

export const SelectInput = (props) => {
  const Option = (props) => {
    return (
      <div style={props.data.style}>
        <components.Option {...props} />
      </div>
    )
  }

  return (
    <div className={'SelectInput' + (props.row ? ' SelectInput--row' : '')}>
      <label className="SelectInput__label" htmlFor={props.name}>
        {props.name}
      </label>
      <Select
        options={props.options}
        id={props.name}
        isMulti={props.multi}
        closeMenuOnSelect={!props.multi}
        onChange={props.handleSelect}
        value={props.value}
        placeholder={props.placeholder ?? ''}
        isDisabled={props.disabled}
        className="react-select-container"
        classNamePrefix="react-select"
        components={{ Option }}
      />
    </div>
  )
}

SelectInput.propTypes = {
  name: PropTypes.string,
  options: PropTypes.array,
  handleSelect: PropTypes.func,
  value: PropTypes.any,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  row: PropTypes.bool,
  multi: PropTypes.bool,
}

export default SelectInput

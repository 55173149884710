import React from 'react'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { PropTypes } from 'prop-types'
import DesktopDateInput from '../components/Desktop/DesktopDateInput'

export const createHandlePrompt = (
  title,
  message,
  buttonLabelRelease,
  buttonActionRelease,
  buttonLabelScanout,
  buttonActionScanout
) => {
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className="ConfirmationPrompt">
          <h1 className="ConfirmationPrompt__title">{title}</h1>
          {typeof message === 'string' ? (
            <p className="ConfirmationPrompt__body">{message}</p>
          ) : (
            message.map((messageLine, index) => (
              <p key={`message${index}`} className="ConfirmationPrompt__body">
                {messageLine}
              </p>
            ))
          )}
          <div className="ConfirmationPrompt__buttons">
            <button
              className="DesktopPromptButton DesktopPromptButton--positive"
              onClick={() => {
                buttonActionRelease('release')
                onClose()
              }}
            >
              {buttonLabelRelease}
            </button>
            <button
              className="DesktopPromptButton DesktopPromptButton--negative"
              onClick={() => {
                buttonActionScanout('scanout')
                onClose()
              }}
            >
              {buttonLabelScanout}
            </button>
          </div>
        </div>
      )
    },
  })
}

export const createScanoutPrompt = (
  title,
  message,
  buttonLabelRelease,
  buttonActionRelease,
  buttonLabelCancel,
  date_min
) => {
  let selectedDate
  const setItemStartDate = (e) => {
    selectedDate = e
  }
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className="ConfirmationPrompt">
          <h1 className="ConfirmationPrompt__title">{title}</h1>
          <p className="ConfirmationPrompt__body">{message}</p>
          <DesktopDateInput
            name="Valitse lopetuspäivä"
            max=""
            min={date_min}
            value={selectedDate}
            eventHandler={setItemStartDate}
          />
          <div className="ConfirmationPrompt__buttons">
            <button
              className="DesktopPromptButton DesktopPromptButton--positive"
              onClick={() => {
                buttonActionRelease(selectedDate)
                onClose()
              }}
            >
              {buttonLabelRelease}
            </button>
            <button
              className="DesktopPromptButton DesktopPromptButton--negative"
              onClick={() => {
                onClose()
              }}
            >
              {buttonLabelCancel}
            </button>
          </div>
        </div>
      )
    },
  })
}

createHandlePrompt.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string | PropTypes.arrayOf(PropTypes.string),
  buttonLabelRelease: PropTypes.string,
  buttonActionRelease: PropTypes.func,
  buttonLabelScanout: PropTypes.string,
  buttonActionScanout: PropTypes.func,
}

createScanoutPrompt.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  buttonLabelRelease: PropTypes.string,
  buttonActionRelease: PropTypes.func,
  buttonLabelCancel: PropTypes.string,
  date_min: PropTypes.date,
}

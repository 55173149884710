export const WorkStatusTypes = {
  ACTIVE: {
    description: 'Avoin',
    id: 1,
  },
  INACTIVE: {
    description: 'Suljettu',
    id: 2,
  },
}

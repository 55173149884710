import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const DesktopInformationBox = (props) => {
  return (
    <div className="DesktopInformationBox">
      {props.options.map((option) => (
        <div key={option.title}>
          <span className="DesktopInformationBox__title">{option.title}:</span>
          {Array.isArray(option.path) ? (
            option.path.map((path, index) => (
              <Link
                to={`/${path}`}
                className="DesktopInformationBox__link"
                key={path}
              >
                {option.data[index]}
                {index !== option.data.length - 1 && ', '}
              </Link>
            ))
          ) : option.path ? (
            <Link
              to={`/${option.path}`}
              className="DesktopInformationBox__link"
            >
              {option.data}
            </Link>
          ) : (
            option.data
          )}
        </div>
      ))}
    </div>
  )
}
DesktopInformationBox.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
      ]),
      data: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.arrayOf(PropTypes.string),
      ]),
    })
  ),
}

export default DesktopInformationBox

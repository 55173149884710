import React from 'react'
import PropTypes from 'prop-types'
import { StickyTable, Row, Cell } from 'react-sticky-table'

const DesktopBaseTable = (props) => {
  return (
    <StickyTable className="DesktopBaseTable" leftStickyColumnCount={0}>
      <Row className="DesktopBaseTable__header">
        {props.columns.map((col) => (
          <Cell key={col}>{col}</Cell>
        ))}
      </Row>
      {props.children}
    </StickyTable>
  )
}

DesktopBaseTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.string),
}

export default DesktopBaseTable

import { types } from 'mobx-state-tree'
import { ItemActionStore } from './ItemActionStore'
import { ItemStore } from './ItemStore'
import { ItemTypeStore } from './ItemTypeStore'
import { ItemStatusStore } from './ItemStatusStore'
import { LocationStore } from './LocationStore'
import { WorkStore } from './WorkStore'
import { UserStore } from './UserStore'
import { WorkStatusStore } from './WorkStatusStore'
import { BillingStatusStore } from './BillingStatusStore'

export const RootStore = types.model('RootStore', {
  billingStatusStore: types.optional(BillingStatusStore, {}),
  itemActionStore: types.optional(ItemActionStore, {}),
  itemStore: types.optional(ItemStore, {}),
  itemStatusStore: types.optional(ItemStatusStore, {}),
  itemTypeStore: types.optional(ItemTypeStore, {}),
  locationStore: types.optional(LocationStore, {}),
  workStore: types.optional(WorkStore, {}),
  userStore: types.optional(UserStore, {}),
  workStatusStore: types.optional(WorkStatusStore, {}),
})

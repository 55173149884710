import { types, flow } from 'mobx-state-tree'
import { values } from 'mobx'
import axios from 'axios'
import { API_BASE } from '../helpers/urlHelpers'
import { requestState } from '../helpers/requestState'
import { Location } from '../models/Location'
import { compareByPropName } from '../utils/sortUtils'

export const LocationStore = types
  .model('LocationStore', {
    locations: types.map(Location, {}),
    locationState: types.optional(types.string, requestState.NONE),
  })
  .views((self) => ({
    get archivedLocations() {
      return values(self.locations).filter((location) => location.archived)
    },
    get notArchivedLocations() {
      return values(self.locations).filter((location) => !location.archived)
    },
    locationById(id) {
      return values(self.locations).find((location) => location.id === id)
    },
    locationByLocationId(locationId) {
      return values(self.locations).find(
        (location) => location.location_id === locationId
      )
    },
    locationsSortedByPropName(sort) {
      return [...values(self.locations)].sort(compareByPropName(sort))
    },
  }))
  .actions((self) => ({
    fetchLocations: flow(function* () {
      self.locationState = requestState.LOADING

      try {
        const response = yield axios.get(API_BASE + '/locations')

        if (response.status === 200) {
          response.data.forEach((location) => {
            self.locations.put(location)
          })

          self.locationState = requestState.LOADED
        }
      } catch (error) {
        self.locationState = requestState.ERROR
      }
    }),
    createLocation: flow(function* createLocation(location) {
      self.locationState = requestState.LOADING

      try {
        const response = yield axios.post(API_BASE + '/locations', location)

        if (response.status === 201) {
          self.locations.put(response.data)
          self.locationState = requestState.CREATED
          return response.data.id
        }
      } catch (error) {
        self.userState = requestState.ERROR
      }
    }),
    patchLocation: flow(function* (id, location) {
      self.locationState = requestState.LOADING
      const currentLocation = self.locationById(id)

      // only changed fields
      const newLocation = Object.keys(location).reduce(
        (a, k) => {
          if (currentLocation[k] !== location[k]) {
            return { ...a, [k]: location[k] }
          }
          return a
        },
        { id: currentLocation.id }
      )

      try {
        const response = yield axios.patch(
          API_BASE + `/locations/${id}`,
          newLocation
        )

        if (response.status === 200) {
          const updatedLocation = response.data
          self.locations.put(updatedLocation)
          self.locationState = requestState.UPDATED
        }
      } catch (error) {
        self.locationState = requestState.ERROR
      }
    }),
  }))

import React from 'react'
import DesktopPageHeading from '../../DesktopPageHeading'
import DesktopAdminItemTypeCreate from './DesktopAdminItemTypeCreate.jsx'
import DesktopAdminItemTypeTable from './DesktopAdminItemTypeTable.jsx'

const DesktopItemTypePage = () => {
  return (
    <div className="DesktopAdminUsersPage">
      <DesktopPageHeading
        title="Laitetyypit"
        subtitle="Hallitse laitetyyppejä"
      />
      <section className="DesktopAdminUsersPage__forms">
        <DesktopAdminItemTypeCreate />
      </section>
      <DesktopAdminItemTypeTable />
    </div>
  )
}

export default DesktopItemTypePage

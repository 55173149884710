import React, { useState } from 'react'
import { values } from 'mobx'
import { observer } from 'mobx-react'
import { requestState } from '../../../../helpers/requestState'
import {
  notifySuccess,
  notifyError,
} from '../../../../helpers/notificationHelpers'
import DesktopFormButton from '../../DesktopFormButton'
import DesktopSearchInput from '../../DesktopSearchInput'
import { useStores } from '../../../../hooks/use-stores'

const DesktopAdminUserCreate = observer(() => {
  const { userStore } = useStores()
  const { createUser } = userStore
  const [userName, setUserName] = useState('')
  const [userEmail, setUserEmail] = useState('')
  const [userPassword, setUserPassword] = useState('')
  const [retypedPassword, setRetypedPassword] = useState('')

  const checkDuplicateEmail = (email) => {
    return values(userStore.users).filter((user) => user.email === email).length
  }

  const sendUser = async (event) => {
    event.preventDefault()

    if (!userName) {
      return notifyError('Käyttäjää ei lisätty: lisää käyttäjänimi')
    }

    if (!userEmail) {
      return notifyError('Käyttäjää ei lisätty: lisää sähköposti')
    }

    if (!userPassword) {
      return notifyError('Käyttäjää ei lisätty: lisää salasana')
    }

    if (!retypedPassword) {
      return notifyError('Käyttäjää ei lisätty: toista salasana')
    }

    if (userPassword && retypedPassword && userPassword !== retypedPassword) {
      return notifyError('Käyttäjää ei lisätty: toistettu salasana ei täsmää')
    }

    if (checkDuplicateEmail(userEmail)) {
      return notifyError('Käyttäjää ei lisätty: sähköposti on jo käytössä')
    }

    const user = {
      name: userName,
      email: userEmail,
      password: userPassword,
    }
    await createUser(user)

    if (userStore.userState === requestState.CREATED) {
      notifySuccess(`Käyttäjä ${userName} lisätty`)
    } else {
      notifyError(`Käyttäjää ${userName} ei voitu lisätä`)
    }

    setUserName('')
    setUserEmail('')
    setUserPassword('')
    setRetypedPassword('')
  }

  return (
    <form className="DesktopAdminUserCreate" onSubmit={sendUser}>
      <div className="DesktopAdminUserCreate DesktopAdminUserCreate__create">
        <h3 className="DesktopAdminUserCreate__title">Luo uusi käyttäjä</h3>
        <DesktopSearchInput
          name="Nimi"
          eventHandler={setUserName}
          value={userName}
          type="text"
          required={true}
        />
        <DesktopSearchInput
          name="Sähköpostiosoite"
          eventHandler={setUserEmail}
          value={userEmail}
          type="email"
          required={true}
        />
        <DesktopSearchInput
          name="Salasana (väh. 8 merkkiä)"
          eventHandler={setUserPassword}
          value={userPassword}
          type="password"
          required={true}
        />
        <DesktopSearchInput
          name="Toista salasana"
          eventHandler={setRetypedPassword}
          value={retypedPassword}
          type="password"
          required={true}
        />
        <DesktopFormButton
          title="Luo käyttäjä"
          disabled={!userName || !userEmail}
        />
      </div>
    </form>
  )
})

export default DesktopAdminUserCreate

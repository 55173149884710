import React from 'react'
import PropTypes from 'prop-types'

const MobileMainTitle = (props) => {
  return <div className="MobileMainTitle">{props.title}</div>
}

MobileMainTitle.propTypes = {
  title: PropTypes.string,
}

export default MobileMainTitle

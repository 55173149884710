import { types, flow, getParent } from 'mobx-state-tree'
import axios from 'axios'
import { ItemAction } from '../models/ItemAction'
import { requestState } from '../helpers/requestState'
import { API_BASE } from '../helpers/urlHelpers'
import { values } from 'mobx'
import * as R from 'ramda'
import { BillingStatusTypes } from '../types/BillingStatusTypes'

export const ItemActionStore = types
  .model('ItemActionStore', {
    item_actions: types.map(ItemAction, {}),
    itemActionState: types.optional(types.string, requestState.NONE),
  })
  .views((self) => ({
    itemActionsByWork(workId) {
      return values(self.item_actions).filter(
        (action) => action.work_entry_id.id === workId
      )
    },
    itemActionsCountByWork(workId) {
      return self.itemActionsByWork(workId).length
    },
    openItemActionsByWork(workId) {
      return values(self.item_actions).filter(
        (action) => action.work_entry_id.id === workId && !action.date_end
      )
    },
    openItemActionsCountByWork(workId) {
      return self.openItemActionsByWork(workId).length
    },
    subcontractorItemActionsByWork(workId) {
      return self
        .itemActionsByWork(workId)
        .filter((action) => action.item_id.is_subcontractor)
    },
    closedItemActionsByWork(workId) {
      return values(self.item_actions).filter(
        (action) => action.work_entry_id.id === workId && action.date_end
      )
    },
    closedItemActionsCountByWork(workId) {
      return self.closedItemActionsByWork(workId).length
    },
    itemActionsByBillingStatus(status) {
      return values(self.item_actions).filter(
        (action) => action.billing_status_id.id === status
      )
    },
    itemActionsByBillingStatusAndWork(status, workId) {
      return values(self.item_actions).filter(
        (action) =>
          action.billing_status_id.id === status &&
          action.work_entry_id.id === workId
      )
    },
    itemActionCountByBillingStatusAndWork(status, workId) {
      return self.itemActionsByBillingStatusAndWork(status, workId).length
    },
    itemActionsInGroup(status) {
      const itemActions = !status
        ? values(self.item_actions)
        : self.itemActionsByBillingStatus(status.id)
      let actions = []
      if (!itemActions) return []
      itemActions.forEach((action) => {
        const actionObject = {
          id: action.id,
          location: action.work_entry_id.location_id.name,
          address: action.work_entry_id.location_id.address,
          work: action.work_entry_id.name,
          work_id: action.work_entry_id.id,
          item_type: action.item_id.item_type_id.name,
          billing_status_code: action.billing_status_id.id,
          billing_status_description: action.billing_status_id.description,
          item_id: action.item_id.id,
          date_start: action.date_start,
          date_end: action.date_end === null ? '' : action.date_end,
          added_by: action.added_by,
        }

        actions.push(actionObject)
      })

      actions.forEach((thisAction) => {
        const similarActions = actions.filter(
          (action) =>
            action.location === thisAction.location &&
            action.address === thisAction.address &&
            action.work === thisAction.work &&
            action.work_id === thisAction.work_id &&
            action.item_type === thisAction.item_type &&
            action.billing_status_code === thisAction.billing_status_code &&
            action.billing_status_description ===
              thisAction.billing_status_description &&
            action.date_start === thisAction.date_start &&
            action.date_end === thisAction.date_end &&
            action.added_by === thisAction.added_by
        )

        thisAction.count = similarActions.length
        thisAction.id_list = similarActions.map((action) => action.item_id)
      })

      return R.uniqWith(
        R.eqBy(
          R.props([
            'location',
            'address',
            'work',
            'work_id',
            'item_type',
            'billing_status_code',
            'billing_status_description',
            'date_start',
            'date_end',
            'added_by',
            'count',
          ])
        ),
        actions
      )
    },
    itemActionsInGroupCountUnbilled() {
      const unbilled = self.itemActionsInGroup(BillingStatusTypes.NOT_BILLED)
        .length
      return unbilled
    },
    itemActionsByItem(itemId) {
      return values(self.item_actions).filter(
        (action) => action.item_id.id === itemId
      )
    },
    openItemActionsByItem(itemId) {
      return self.itemActionsByItem(itemId).filter((action) => !action.date_end)
    },
    latestItemActionByItem(itemId) {
      return values(self.item_actions)
        .filter((action) => action.item_id.id === itemId)
        .reduce(
          (prev, current) =>
            prev.updated_at > current.updated_at ? prev : current,
          0
        )
    },
  }))
  .actions((self) => ({
    fetchItemActions: flow(function* () {
      self.itemActionState = requestState.LOADING

      try {
        const response = yield axios.get(API_BASE + '/item_actions')

        if (response.status === 200) {
          response.data.forEach((item_action) => {
            self.item_actions.put({
              ...item_action,
              added_by: item_action.added_by
                ? item_action.added_by.name
                : 'Poistunut käyttäjä',
            })
          })

          self.itemActionState = requestState.LOADED
        }
      } catch (error) {
        self.itemActionState = requestState.ERROR
      }
    }),
    createItemAction: flow(function* (items) {
      self.itemActionState = requestState.LOADING

      try {
        const response = yield axios.post(
          API_BASE + '/item_actions/admin_add',
          items
        )

        if (response.status === 200) {
          response.data.item_actions.forEach((item_action) => {
            self.item_actions.put({
              ...item_action,
              added_by: item_action.added_by.name,
            })
          })

          response.data.items.forEach((item) => {
            getParent(self).itemStore.updateItem(item)
          })

          self.itemActionState = requestState.CREATED
        }
      } catch (error) {
        self.itemActionState = requestState.ERROR
      }
    }),
    createSubcontractorItemAction: flow(function* (items) {
      self.itemActionState = requestState.LOADING

      try {
        const response = yield axios.post(
          API_BASE + '/item_actions/bulk_add',
          items
        )

        if (response.status === 200) {
          response.data.item_actions.forEach((item_action) => {
            self.item_actions.put({
              ...item_action,
              added_by: item_action.added_by.name,
            })
          })

          getParent(self).itemStore.connectSubcontractorItems()

          self.itemActionState = requestState.CREATED
        }
      } catch (error) {
        console.log(error)
        self.itemActionState = requestState.ERROR
      }
    }),
    adminRelease: flow(function* (items, date_end = null) {
      self.itemActionState = requestState.LOADING
      // Add date_end to admin release where items get "scanned out"
      const body = items
      if (date_end !== null) {
        body.date_end = date_end
      }

      try {
        const response = yield axios.post(
          API_BASE + '/item_actions/admin_release',
          body
        )

        if (response.status === 200) {
          response.data.item_actions.forEach((item_action) => {
            self.item_actions.put({
              ...item_action,
              added_by: item_action.added_by.name,
            })
          })

          response.data.items.forEach((item) => {
            getParent(self).itemStore.updateItem(item)
          })

          self.itemActionState = requestState.UPDATED
        }
      } catch (error) {
        self.itemActionState = requestState.ERROR
      }
    }),
    adminScanout: flow(function* (items, date_end = null) {
      self.itemActionState = requestState.LOADING
      // Add date_end to admin release where items get "scanned out"
      const body = items
      if (date_end !== null) {
        body.date_end = date_end
      }

      try {
        const response = yield axios.post(
          API_BASE + '/item_actions/admin_scanout',
          body
        )

        if (response.status === 200) {
          response.data.item_actions.forEach((item_action) => {
            self.item_actions.put({
              ...item_action,
              added_by: item_action.added_by.name,
            })
          })

          response.data.items.forEach((item) => {
            getParent(self).itemStore.updateItem(item)
          })

          self.itemActionState = requestState.UPDATED
        }
      } catch (error) {
        self.itemActionState = requestState.ERROR
      }
    }),
    scanIn: flow(function* (items, work_entry_id) {
      self.itemActionState = requestState.LOADING

      // Massage data
      const data = {
        items: items.map((item) => {
          return {
            item_id: item.id,
            work_entry_id,
          }
        }),
      }

      try {
        const response = yield axios.post(
          API_BASE + '/item_actions/scan_in',
          data
        )

        if (response.status === 200) {
          response.data.item_actions.forEach((item_action) => {
            self.item_actions.put({
              ...item_action,
              added_by: item_action.added_by.name,
            })
          })

          response.data.items.forEach((item) => {
            getParent(self).itemStore.updateItem(item)
          })
        }
      } catch (error) {
        self.itemActionState = requestState.ERROR
        throw error
      }
    }),
    scanOut: flow(function* (items, work_entry_id) {
      self.itemActionState = requestState.LOADING

      // Massage data
      const data = {
        items: items.map((item) => {
          return {
            item_id: item.id,
            work_entry_id,
          }
        }),
      }

      try {
        const response = yield axios.post(
          API_BASE + '/item_actions/scan_out',
          data
        )

        if (response.status === 200) {
          response.data.item_actions.forEach((item_action) => {
            self.item_actions.put({
              ...item_action,
              added_by: item_action.added_by.name,
            })
          })

          response.data.items.forEach((item) => {
            getParent(self).itemStore.updateItem(item)
          })

          self.itemActionState = requestState.UPDATED
        }
      } catch (error) {
        self.itemActionState = requestState.ERROR
        throw error
      }
    }),
    scanOutSubcontractor: flow(function* (items) {
      self.itemActionState = requestState.LOADING

      try {
        const response = yield axios.post(
          API_BASE + '/item_actions/bulk_remove',
          items
        )

        if (response.status === 200) {
          response.data.item_actions.forEach((item_action) => {
            self.item_actions.put({
              ...item_action,
              added_by: item_action.added_by.name,
            })
          })

          getParent(self).itemStore.connectSubcontractorItems()

          self.itemActionState = requestState.UPDATED
        }
      } catch (error) {
        self.itemActionState = requestState.ERROR
      }
    }),
    invoiceItemsFinal: flow(function* (item_actions) {
      self.itemActionState = requestState.LOADING

      try {
        const response = yield axios.post(
          API_BASE + '/billing_actions/do_billing',
          item_actions
        )

        if (response.status === 200) {
          response.data.item_actions.forEach((item_action) => {
            self.item_actions.put({
              ...item_action,
              added_by: item_action.added_by.name,
            })
          })

          self.itemActionState = requestState.UPDATED
        }
      } catch (error) {
        self.itemActionState = requestState.ERROR
      }
    }),
    invoiceItemsPartial: flow(function* (item_actions) {
      self.itemActionState = requestState.LOADING

      try {
        const response = yield axios.post(
          API_BASE + '/billing_actions/partial_billing',
          item_actions
        )

        if (response.status === 200) {
          response.data.billed_item_actions.forEach((item_action) => {
            self.item_actions.put({
              ...item_action,
              added_by: item_action.added_by.name,
            })
          })

          response.data.new_item_action.forEach((item_action) => {
            self.item_actions.put({
              ...item_action,
              added_by: item_action.added_by.name,
            })
          })

          self.itemActionState = requestState.UPDATED
        }
      } catch (error) {
        self.itemActionState = requestState.ERROR
      }
    }),
  }))

import React from 'react'
import MobileMainTitle from '../MobileMainTitle'
import MobileMainButton from './MobileMainButton'
import { useStores } from '../../../hooks/use-stores'

const MobileHome = () => {
  const { userStore } = useStores()
  const userTitle = `Hei ${userStore.user.name}`

  return (
    <div className="MobileHome">
      <MobileMainTitle title={userTitle} />
      <MobileMainButton title="Skannaa sisään" path="/scan-in" />
      <MobileMainButton title="Skannaa ulos" path="/scan-out" />
    </div>
  )
}

export default MobileHome

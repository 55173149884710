import { types } from 'mobx-state-tree'
import { Item } from './Item'
import { Work } from './Work'
import { BillingStatus } from './BillingStatus'

export const ItemAction = types.model('ItemAction', {
  id: types.identifierNumber,
  item_id: types.reference(types.late(() => Item)),
  work_entry_id: types.reference(types.late(() => Work)),
  billing_status_id: types.reference(types.late(() => BillingStatus)),
  date_start: types.string,
  date_end: types.maybeNull(types.string),
  updated_at: types.string,
  added_by: types.string,
})

export const ItemSpecsTypes = {
  item_number: 'Laitenumero',
  item_serial: 'Sarjanumero',
  item_model: 'Malli',
  handling_date: 'Viimeksi käsitelty',
  handled_by: 'Käsittelijä',
  item_status: 'Käyttötilanne',
  item_type: 'Laitetyyppi',
  power_source: 'Virtalähde',
  power_consume: 'Sähköteho, max (W)',
  buy_date: 'Hankintapäivä',
  buy_price: 'Hankintahinta',
  work_entry: 'Työ',
  warehouse: 'Varastosijainti',
}

import React from 'react'
import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useStores } from '../../hooks/use-stores'
import { createConfirmationPrompt } from '../../helpers/confirmationPrompt'
import { faUserCircle, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import DesktopMobileNav from './DesktopMobileNav'
import { LocalStorageTypes } from '../../types/LocalStorageTypes'
import { observer } from 'mobx-react'
import { notifySuccess } from '../../helpers/notificationHelpers'

const DesktopHeader = observer(() => {
  const { userStore } = useStores()
  const { logOut } = userStore
  const history = useHistory()
  let user = null

  if (userStore.user) {
    user = userStore.user.name
  }

  const handleLogOut = () => {
    logOut()
    window.localStorage.removeItem(LocalStorageTypes.USERS)
    notifySuccess('Uloskirjautuminen onnistui')
    return history.push('/')
  }

  const confirmAndLogOut = () =>
    createConfirmationPrompt(
      'Kirjaudu ulos',
      'Haluatko kirjautua ulos?',
      'Kyllä',
      handleLogOut,
      'Ei'
    )

  return (
    <div className="DesktopHeader">
      <DesktopMobileNav />
      <div className="DesktopHeader__logo" />
      {user ? (
        <div className="DesktopHeader__user-section">
          <button
            className="DesktopHeader__log-out-button"
            onClick={confirmAndLogOut}
          >
            <FontAwesomeIcon
              icon={faSignOutAlt}
              size="2x"
              className="sign-out-icon"
            />
          </button>
          <span className="DesktopHeader__change-user-section">
            <FontAwesomeIcon
              icon={faUserCircle}
              size="2x"
              className="user-icon"
            />
            {user}
          </span>
        </div>
      ) : null}
    </div>
  )
})

export default DesktopHeader

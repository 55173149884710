import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { confirmAlert } from 'react-confirm-alert'
import DesktopSearchInput from '../../DesktopSearchInput'
import DesktopFormButton from '../../DesktopFormButton'
import DesktopActionButton from '../../DesktopActionButton'
import { ButtonClassTypes } from '../../../../types/ButtonClassTypes'
import { useStores } from '../../../../hooks/use-stores'
import {
  notifyError,
  notifySuccess,
} from '../../../../helpers/notificationHelpers'

// extract this to a separate file along with the alert showing logic if needed elsewhere
const NameChangePrompt = ({ onClose, id, oldName }) => {
  const [newName, setNewName] = useState(oldName)
  const { itemTypeStore } = useStores()

  const changeName = (event) => {
    event.preventDefault()
    if (
      itemTypeStore.allItemTypes.find((itemType) => {
        // if (itemType.id === id) return false // would like to do this but back blocks if only changing letter casing
        return (
          itemType.name.toLowerCase().replace(/\s/g, '') ===
          newName.toLowerCase().replace(/\s/g, '')
        )
      })
    ) {
      notifyError('Ei tallennettu: Laitetyyppi samalla nimellä on jo olemassa!')
      return
    }
    itemTypeStore
      .updateItemTypeName(id, newName)
      .then(() => {
        notifySuccess(`Laitetyypin nimeksi vaihdettu ${newName}`)
        onClose()
      })
      .catch(() => {
        notifyError('Nimeä ei voitu muuttaa.')
      })
  }

  return (
    <form className="DesktopItemTypeEditButton__form" onSubmit={changeName}>
      <h2>Muokkaa tyypin nimeä</h2>
      <DesktopSearchInput
        name="Uusi nimi"
        eventHandler={setNewName}
        value={newName}
        required={true}
      />
      <div className="DesktopItemTypeEditButton__form__controls">
        <DesktopFormButton
          title="Tallenna"
          disabled={!newName || newName === oldName}
        />
        <DesktopActionButton
          title="Peruuta"
          action={onClose}
          class={ButtonClassTypes.WARNING}
        />
      </div>
    </form>
  )
}

const DesktopItemTypeEditButton = ({ id, oldName }) => {
  const showNameChangePrompt = () => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <NameChangePrompt onClose={onClose} id={id} oldName={oldName} />
      ),
      closeOnClickOutside: false,
    })
  }

  return (
    <div className="DesktopItemTypeEditButton" onClick={showNameChangePrompt}>
      <FontAwesomeIcon
        className="DesktopItemTypeEditButton__icon"
        icon={faPencilAlt}
      />
      <span className="DesktopItemTypeEditButton__text">Muokkaa nimeä</span>
    </div>
  )
}

export default DesktopItemTypeEditButton

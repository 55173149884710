import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import QrReader from 'react-qr-reader'
import { notifyError } from '../../helpers/notificationHelpers'
import { useStores } from '../../hooks/use-stores'
import { observer } from 'mobx-react'
import MobileSmallButton from './MobileSmallButton.jsx'

const MobileScanOverlay = observer((props) => {
  const [manualCode, setManualCode] = useState('')
  const [scannerError, setScannerError] = useState(false)
  const [cooldownActive, setCooldownActive] = useState(false)
  const [timeOutHandle, setTimeOutHandle] = useState()
  const { itemStore } = useStores()

  useEffect(() => {
    // create fake history page
    window.history.pushState(null, document.title, window.location.pathname)
    // when going back, close overlay
    window.addEventListener(
      'popstate',
      () => {
        props.onClose()
      },
      { once: true }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    return () => {
      window.clearTimeout(timeOutHandle)
    }
  }, [timeOutHandle])

  function onScan(scan) {
    if (scan) {
      if (cooldownActive) return
      const item = itemStore.itemByNumber(scan)
      if (item) {
        props.onGotItem(item)
      } else {
        notifyError(`Laitetta koodilla ${scan} ei löytynyt.`, 2000)
      }
      cooldown()
    }
  }

  function cooldown() {
    setCooldownActive(true)
    const handle = window.setTimeout(() => {
      setCooldownActive(false)
    }, 2000)
    setTimeOutHandle(handle)
  }

  function manualInput() {
    if (manualCode) {
      onScan(manualCode)
      setManualCode('')
    }
  }

  function close() {
    window.history.back()
  }

  return (
    <div className="MobileScanOverlay">
      <h6 className="MobileScanOverlay__title">Skannaa laitteen QR-koodi</h6>
      {scannerError ? (
        <div className="MobileScanOverlay__error">
          Skannausta ei tueta laitteellasi. Syötä laitteen koodi käsin.
        </div>
      ) : (
        <QrReader
          className="MobileScanOverlay__QrReader"
          onScan={onScan}
          resolution={1200}
          onError={() => setScannerError(true)}
        />
      )}
      <h6 className="MobileScanOverlay__title">tai syötä laitteen numero</h6>
      <div className="MobileScanOverlay__manual">
        <input
          className="DesktopSearchInput__input MobileScanOverlay__input"
          type="text"
          placeholder="Laitteen numero"
          onChange={(e) => setManualCode(e.target.value)}
          value={manualCode}
          onKeyUp={(e) => {
            e.keyCode === 13 && manualInput()
          }}
        />
        <MobileSmallButton onClick={manualInput}>SYÖTÄ</MobileSmallButton>
      </div>
      <div className="MobileScanOverlay__exit">
        <MobileSmallButton onClick={close}>
          POISTU
          {!!props.amount && (
            <span className="MobileScanOverlay__counter">{props.amount}</span>
          )}
        </MobileSmallButton>
      </div>
    </div>
  )
})

MobileScanOverlay.propTypes = {
  onGotItem: PropTypes.func,
  onClose: PropTypes.func,
  amount: PropTypes.number,
}

export default MobileScanOverlay

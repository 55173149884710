import React from 'react'
import PropTypes from 'prop-types'

const DesktopActionButton = (props) => {
  return (
    <button
      className={`DesktopActionButton DesktopActionButton--${props.class}`}
      onClick={props.action}
    >
      {props.title}
    </button>
  )
}

DesktopActionButton.propTypes = {
  action: PropTypes.func,
  class: PropTypes.string,
  title: PropTypes.string,
}

export default DesktopActionButton

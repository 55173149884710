import React from 'react'
import { confirmAlert } from 'react-confirm-alert'
import DesktopCreateLocationForm from '../components/Desktop/DesktopCreateLocationForm'
import 'react-confirm-alert/src/react-confirm-alert.css'

export const createNewLocationPrompt = (closeCb = () => {}) => {
  confirmAlert({
    customUI: ({ onClose }) => {
      function close(id) {
        onClose()
        closeCb(id)
      }

      return <DesktopCreateLocationForm onClose={(id) => close(id)} />
    },
    closeOnClickOutside: false,
  })
}

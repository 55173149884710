import React, { useState } from 'react'
import { useStores } from '../../../../hooks/use-stores'
import { observer } from 'mobx-react'
import DesktopSearchInput from '../../DesktopSearchInput'
import DesktopNoResultsMessage from '../../DesktopNoResultMessage'
import { Row, Cell } from 'react-sticky-table'
import DesktopBaseTable from '../../DesktopBaseTable'
import DesktopAdminItemTypeEditButton from './DesktopItemTypeEditButton'

const DesktopAdminItemTypeTable = observer(() => {
  const { itemTypeStore } = useStores()
  const { filteredItemTypes } = itemTypeStore
  const [itemTypeSearchWord, setUserSearchWord] = useState('')

  return (
    <div className="DesktopAdminUserTable">
      <DesktopSearchInput
        name="Hae laitetyyppin nimellä"
        type="text"
        eventHandler={setUserSearchWord}
        value={itemTypeSearchWord}
        required={false}
      />
      {filteredItemTypes(itemTypeSearchWord).length ? (
        <div className="DesktopAdminUserTable__table">
          <DesktopBaseTable columns={['Nimi', 'Id']}>
            {filteredItemTypes(itemTypeSearchWord).map((itemType) => (
              <Row key={itemType.id}>
                <Cell>
                  {itemType.name}{' '}
                  <DesktopAdminItemTypeEditButton
                    id={itemType.id}
                    oldName={itemType.name}
                  />
                </Cell>
                <Cell>{itemType.id}</Cell>
              </Row>
            ))}
          </DesktopBaseTable>
        </div>
      ) : (
        <DesktopNoResultsMessage
          message={`Laitetyyppejä ei löydetty hakusanalla ${itemTypeSearchWord}`}
        />
      )}
    </div>
  )
})

export default DesktopAdminItemTypeTable

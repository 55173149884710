import React from 'react'
import PropTypes from 'prop-types'
import { Cell } from 'react-sticky-table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons'

const DesktopTableArchiveCell = ({ text }) => {
  return (
    <Cell className="cell-collapse cell-style__data--archived">
      <div className="archived-icon-container">
        <FontAwesomeIcon icon={faFolderOpen} className="folder-icon" />
        {text}
      </div>
    </Cell>
  )
}

DesktopTableArchiveCell.propTypes = {
  text: PropTypes.string,
}

export default DesktopTableArchiveCell

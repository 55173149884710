import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { useStores } from '../../../../hooks/use-stores'
import DesktopPageHeading from '../../DesktopPageHeading'
import { BillingStatusTypes } from '../../../../types/BillingStatusTypes'
import { ItemActionCheckBoxTypes } from '../../../../types/ItemActionCheckBoxTypes'
import DesktopInformationBox from '../../DesktopInformationBox'
import DesktopCheckboxContainer from '../../DesktopCheckboxContainer'
import DesktopSingleWorkInvoiceIndicator from './ DesktopSingleWorkInvoiceIndicatior'
import DesktopSingleWorkInvoiceGroupTable from './DesktopSingleWorkInvoiceGroupTable/ DesktopSingleWorkInvoiceGroupTable'
import DesktopSingleWorkInvoiceTable from './DesktopSingleWorkInvoiceTable/DesktopSingleWorkInvoiceTable'

const DesktopSingleWorkInvoicePage = observer((props) => {
  const { itemActionStore } = useStores()
  const { itemActionCountByBillingStatusAndWork } = itemActionStore
  const [tableToShow, setTableToShow] = useState(1)

  const billOrNot = itemActionCountByBillingStatusAndWork(
    BillingStatusTypes.NOT_BILLED.id,
    props.work.id
  )

  const billingClass = billOrNot ? 'billing' : 'no-billing'

  const billingText = billOrNot
    ? BillingStatusTypes.NOT_BILLED.description
    : BillingStatusTypes.BILLED.description

  const options = [
    {
      title: 'Kohde',
      data: props.work.location_id.name,
      path: `locations/${props.work.location_id.id}`,
    },
    {
      title: 'Työ',
      data: props.work.name,
      path: `works/${props.work.id}`,
    },
    {
      title: 'Tila',
      data: props.work.work_status_id.description,
    },
  ]

  const checkBoxItems = [
    {
      name: ItemActionCheckBoxTypes.BY_TYPE,
      action: () => setTableToShow(1),
    },
    {
      name: ItemActionCheckBoxTypes.BY_ITEM,
      action: () => setTableToShow(2),
    },
  ]

  return (
    <div className="DesktopSingleWorkInvoicePage">
      <DesktopPageHeading
        title={`Laskutus - ${props.work.location_id.name} (${props.work.name})`}
        subtitle={'Laskuta työn laitteita'}
      />
      <div className="DesktopSingleWorkInvoicePage__information">
        <DesktopInformationBox options={options} />
        <DesktopSingleWorkInvoiceIndicator
          class={billingClass}
          text={billingText}
        />
      </div>
      <div className="DesktopSingleWorkInvoicePage__actions">
        <DesktopCheckboxContainer
          labels_and_actions={checkBoxItems}
          radio_group="billing_actions_by_group"
          checked={ItemActionCheckBoxTypes.BY_TYPE}
        />
      </div>
      {tableToShow === 1 ? (
        <DesktopSingleWorkInvoiceGroupTable work={props.work} />
      ) : (
        <DesktopSingleWorkInvoiceTable work={props.work} />
      )}
    </div>
  )
})

DesktopSingleWorkInvoicePage.propTypes = {
  work: PropTypes.object,
}

export default DesktopSingleWorkInvoicePage

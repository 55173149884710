import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { useStores } from '../../../../../hooks/use-stores'
import { ItemActionCheckBoxTypes } from '../../../../../types/ItemActionCheckBoxTypes'
import { sortItemActions } from '../../../../../helpers/sortHelpers'
import { findValue } from '../../../../../helpers/filterHelpers'
import { compareByMultiplePropNames } from '../../../../../utils/sortUtils'
import { BillingStatusTypes } from '../../../../../types/BillingStatusTypes'
import DesktopSearchInput from '../../../DesktopSearchInput'
import DesktopCheckboxContainer from '../../../DesktopCheckboxContainer'
import DesktopNoResultsMessage from '../../../DesktopNoResultMessage'
import DesktopSingleWorkInvoiceGroupTableRow from './DesktopSingleWorkInvoiceGroupTableRow'
import * as R from 'ramda'
import DesktopBaseTable from '../../../DesktopBaseTable'

const DesktopSingleWorkInvoiceGroupTable = observer((props) => {
  const { itemActionStore } = useStores()
  const {
    itemActionsByWork,
    itemActionsByBillingStatusAndWork,
  } = itemActionStore
  const [arrayToFilter, setArrayToFilter] = useState(() => () =>
    itemActionsByWork(props.work.id)
  )
  const [itemSearchWord, setItemSearchWord] = useState('')

  const itemActionsInGroup = (itemActions) => {
    let actions = []
    itemActions.forEach((action) => {
      const actionObject = {
        id: action.id,
        item_type: action.item_id.item_type_id.name,
        billing_status_id: action.billing_status_id.id,
        billing_status_description: action.billing_status_id.description,
        item_id: action.item_id.id,
        date_start: action.date_start,
        date_end: !action.date_end ? '' : action.date_end,
        updated_at: action.updated_at.substring(0, 10), // Use format 20XX-XX-XX for the date
        added_by: action.added_by,
      }

      actions.push(actionObject)
    })

    actions.forEach((thisAction) => {
      const similarActions = actions.filter(
        (action) =>
          action.item_type === thisAction.item_type &&
          action.billing_status_id === thisAction.billing_status_id &&
          action.billing_status_description ===
            thisAction.billing_status_description &&
          action.date_start === thisAction.date_start &&
          action.date_end === thisAction.date_end &&
          action.updated_at === thisAction.updated_at &&
          action.added_by === thisAction.added_by
      )

      thisAction.count = similarActions.length
      thisAction.item_action_id_list = similarActions.map((action) => action.id)
    })

    const uniqueGroupedActions = R.uniqWith(
      R.eqBy(
        R.props([
          'item_type',
          'billing_status_id',
          'billing_status_description',
          'date_start',
          'date_end',
          'updated_at',
          'count',
          'added_by',
        ])
      ),
      actions
    )
    return uniqueGroupedActions
  }

  const checkBoxItems = [
    {
      name: ItemActionCheckBoxTypes.ALL,
      count: itemActionsInGroup(itemActionsByWork(props.work.id)).length,
      action: () =>
        setArrayToFilter(() => () => itemActionsByWork(props.work.id)),
    },
    {
      name: ItemActionCheckBoxTypes.BILLED,
      count: itemActionsInGroup(
        itemActionsByBillingStatusAndWork(
          BillingStatusTypes.BILLED.id,
          props.work.id
        )
      ).length,
      action: () =>
        setArrayToFilter(() => () =>
          itemActionsByBillingStatusAndWork(
            BillingStatusTypes.BILLED.id,
            props.work.id
          )
        ),
    },
    {
      name: ItemActionCheckBoxTypes.NOT_BILLED,
      count: itemActionsInGroup(
        itemActionsByBillingStatusAndWork(
          BillingStatusTypes.NOT_BILLED.id,
          props.work.id
        )
      ).length,
      action: () =>
        setArrayToFilter(() => () =>
          itemActionsByBillingStatusAndWork(
            BillingStatusTypes.NOT_BILLED.id,
            props.work.id
          )
        ),
    },
  ]

  return (
    <div className="DesktopSingleWorkInvoiceGroupTable">
      <div className="DesktopSingleWorkInvoiceGroupTable__search">
        <DesktopSearchInput
          name="Hae laitteen nimellä"
          type="text"
          eventHandler={setItemSearchWord}
          value={itemSearchWord}
          required={false}
        />
        <DesktopCheckboxContainer
          labels_and_actions={checkBoxItems}
          radio_group="billing_actions_by_status"
          checked={ItemActionCheckBoxTypes.ALL}
        />
      </div>
      {itemActionsInGroup(arrayToFilter()).filter((action) =>
        findValue(action.item_type, itemSearchWord)
      ).length ? (
        <DesktopBaseTable
          columns={[
            'Laite',
            'Määrä',
            'Lisännyt työlle',
            'Aloitus',
            'Lopetus',
            'Laskutettu',
            'Käsittele',
            '',
          ]}
        >
          {itemActionsInGroup(arrayToFilter())
            .filter((action) => findValue(action.item_type, itemSearchWord))
            .sort(compareByMultiplePropNames(sortItemActions))
            .map((action) => (
              <DesktopSingleWorkInvoiceGroupTableRow
                key={action.id}
                billing_status={action.billing_status_id}
                item_type={action.item_type}
                count={action.count}
                date_start={action.date_start}
                date_end={action.date_end}
                item_action_id_list={action.item_action_id_list}
                updated_at={action.updated_at}
                added_by={action.added_by}
              />
            ))}
        </DesktopBaseTable>
      ) : (
        <DesktopNoResultsMessage
          message={`Laskutustapahtumia ei löydetty hakuehdoilla ${itemSearchWord}`}
        />
      )}
    </div>
  )
})

DesktopSingleWorkInvoiceGroupTable.propTypes = {
  work: PropTypes.object,
}

export default DesktopSingleWorkInvoiceGroupTable

import { toast } from 'react-toastify'

export const notifySuccess = (message, time = 2000) => {
  toast.success(message, {
    position: toast.POSITION.BOTTOM_RIGHT,
    autoClose: time,
  })
}

export const notifyError = (message, time = 2000) => {
  toast.error(message, {
    position: toast.POSITION.BOTTOM_RIGHT,
    autoClose: time,
  })
}

export const checkSuccess = (condition, successMessage, errorMessage) => {
  if (condition) {
    return notifySuccess(successMessage)
  } else {
    return notifyError(errorMessage)
  }
}

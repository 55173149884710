import { types, flow } from 'mobx-state-tree'
import { ItemStatus } from '../models/ItemStatus'
import axios from 'axios'
import { requestState } from '../helpers/requestState'
import { API_BASE } from '../helpers/urlHelpers'

export const ItemStatusStore = types
  .model('ItemStatusStore', {
    item_statuses: types.map(ItemStatus, {}),
    itemStatusState: types.optional(types.string, requestState.NONE),
  })
  .actions((self) => ({
    fetchItemStatuses: flow(function* () {
      self.itemStatusState = requestState.LOADING

      try {
        const response = yield axios.get(API_BASE + '/item_statuses')

        if (response.status === 200) {
          response.data.forEach((item_status) => {
            self.item_statuses.put(item_status)
          })

          self.itemStatusState = requestState.LOADED
        }
      } catch (error) {
        self.itemStatusState = requestState.ERROR
      }
    }),
  }))

import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const MobileMainButton = (props) => {
  return (
    <Link to={props.path}>
      <button className="MobileMainButton">{props.title}</button>
    </Link>
  )
}

MobileMainButton.propTypes = {
  path: PropTypes.string,
  title: PropTypes.string,
}

export default MobileMainButton

import { types, flow } from 'mobx-state-tree'
import axios from 'axios'
import { API_BASE } from '../helpers/urlHelpers'
import { requestState } from '../helpers/requestState'
import { WorkStatus } from '../models/WorkStatus'

export const WorkStatusStore = types
  .model('WorkStatusStore', {
    statuses: types.map(WorkStatus, {}),
    statusState: types.optional(types.string, requestState.NONE),
  })
  .actions((self) => ({
    fetchWorkStatuses: flow(function* () {
      self.statusState = requestState.LOADING

      try {
        const response = yield axios.get(API_BASE + '/work_statuses')

        if (response.status === 200) {
          response.data.forEach((status) => {
            self.statuses.put(status)
          })
          self.statusState = requestState.LOADED
        }
      } catch (error) {
        self.statusState = requestState.ERROR
      }
    }),
  }))

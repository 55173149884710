import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import DesktopPageHeading from '../../DesktopPageHeading'
import { createNewWorkPrompt } from '../../../../helpers/newWorkPrompt'
import DesktopInformationBox from '../../DesktopInformationBox'
import DesktopActionButton from '../../DesktopActionButton'
import DesktopLinkButton from '../../DesktopLinkButton'
import DesktopNoResultMessage from '../../DesktopNoResultMessage'
import DesktopSingleLocationWorkTable from './DesktopSingleLocationWorkTable'
import { useStores } from '../../../../hooks/use-stores'
import { ButtonClassTypes } from '../../../../types/ButtonClassTypes'
import { requestState } from '../../../../helpers/requestState'
import { createConfirmationPrompt } from '../../../../helpers/confirmationPrompt'
import {
  notifyError,
  notifySuccess,
} from '../../../../helpers/notificationHelpers'

const DesktopSingleLocationPage = ({ location }) => {
  const history = useHistory()

  const isArchived = location.archived
  const { workStore, locationStore } = useStores()
  const { worksByLocation } = workStore
  const { patchLocation } = locationStore

  const today = moment(Date.now()).format('yyyy-MM-DD')

  const options = [
    {
      title: 'Kohde',
      data: location.name,
    },
    {
      title: 'ID',
      data: location.location_id,
    },
    {
      title: 'Osoite',
      data: location.address,
    },
  ]

  const sendArchiveLocation = async (locationId) => {
    const archivedLocation = { archived: today }

    await patchLocation(locationId, archivedLocation)
    const isSuccess = locationStore.locationState === requestState.UPDATED

    if (isSuccess) {
      notifySuccess(`Kohde ${location.name} arkistoitu`)
      return history.push(`/locations`)
    } else {
      return notifyError(`Kohdetta ${location.name} ei voitu arkistoida`)
    }
  }

  const confirmAndArchive = () => {
    // Check if location has unarchived works
    if (worksByLocation(location.id, false).length) {
      return notifyError(
        'Kohdetta ei voitu arkistoida: arkistoi ensin kohteen työt'
      )
    }

    createConfirmationPrompt(
      'Arkistoi kohde',
      `Oletko varma, että haluat arkistoida kohteen ${location.name}?`,
      'Kyllä',
      () => sendArchiveLocation(location.id),
      'Ei'
    )
  }

  const sendUnArchiveLocation = async (locationId) => {
    const archivedLocation = { archived: null }

    await patchLocation(locationId, archivedLocation)
    const isSuccess = locationStore.locationState === requestState.UPDATED

    if (isSuccess) {
      notifySuccess(`Kohde ${location.name} palautettu arkistosta`)
      return history.push('/locations')
    } else {
      return notifyError(
        `Kohdetta ${location.name} ei voitu palauttaa arkistosta`
      )
    }
  }

  const confirmAndUnArchive = () => {
    createConfirmationPrompt(
      'Palauta kohde arkistosta',
      `Oletko varma, että haluat palauttaa kohteen ${location.name} arkistosta?`,
      'Kyllä',
      () => sendUnArchiveLocation(location.id),
      'Ei'
    )
  }

  return (
    <div className="DesktopSingleLocationPage">
      <DesktopPageHeading
        title={location.name + ' ' + location.location_id}
        subtitle="Hallitse kohteen töitä"
      />
      <DesktopInformationBox options={options} />
      {isArchived ? (
        <div className="DesktopSingleLocationPage__buttons">
          <DesktopActionButton title="Palauta" action={confirmAndUnArchive} />
          <DesktopNoResultMessage
            message={`Kohde on arkistoitu ${moment(isArchived).format(
              'D.M.Y'
            )}`}
          />
        </div>
      ) : (
        <div className="DesktopSingleLocationPage__buttons">
          <DesktopActionButton
            title="Lisää työ"
            action={() =>
              createNewWorkPrompt(
                'Lisää työ',
                `Lisää työ kohteelle ${location.address} - ${location.name}`,
                location.id
              )
            }
          />
          <DesktopLinkButton
            title="Muokkaa"
            path={`/locations/${location.id}/edit`}
            class={ButtonClassTypes.MODIFY}
          />
          <DesktopActionButton
            title="Arkistoi"
            action={confirmAndArchive}
            class={ButtonClassTypes.ARCHIVE}
          />
        </div>
      )}
      <DesktopSingleLocationWorkTable location={location} />
    </div>
  )
}

DesktopSingleLocationPage.propTypes = {
  location: PropTypes.object,
}

export default DesktopSingleLocationPage

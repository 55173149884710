import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { useStores } from '../../hooks/use-stores'
import { ItemStatusCheckBoxTypes } from '../../types/ItemStatusCheckBoxTypes'
import { ItemStatusTypes } from '../../types/ItemStatusTypes'
import { compareByPropName } from '../../utils/sortUtils'
import DesktopNoResultsMessage from './DesktopNoResultMessage'
import DesktopSearchInput from './DesktopSearchInput'
import DesktopCheckboxContainer from './DesktopCheckboxContainer'
import DesktopBaseTable from './DesktopBaseTable'
import DesktopTableArchiveCell from './DesktopTableArchiveCell'
import { Row, Cell } from 'react-sticky-table'
import { Link } from 'react-router-dom'
import { WarehouseTypes, warehouseShortName } from '../../types/WarehouseTypes'

const DesktopItemTable = observer(() => {
  const { itemStore } = useStores()
  const {
    itemsByStatusAndArchive,
    itemCountByStatusAndArchive,
    itemsByStatusAndArchiveAndWarehouse,
    itemCountByStatusAndArchiveAndWarehouse,
    notArchivedSubcontractorItems,
    archivedItems,
    notArchivedItems,
  } = itemStore
  const [searchWord, setSearchWord] = useState('')

  const findItems = (item, searchWord) => {
    const trimmedSearchWord = searchWord.trim().toLowerCase()
    const itemType = item.item_type_id.name.toLowerCase()
    const itemNumber = item.item_number.toLowerCase()
    const location = item.work_entry_id?.location_id
      ? item.work_entry_id.location_id.name.toLowerCase()
      : item.work_entry_id?.length
      ? item.work_entry_id
          .map((entry) => entry.location_id.name.toLowerCase())
          .join(',')
      : ''
    const workEntry = item.work_entry_id?.name
      ? item.work_entry_id.name.toLowerCase()
      : item.work_entry_id?.length
      ? item.work_entry_id.map((entry) => entry.name.toLowerCase()).join(',')
      : ''

    return (
      itemType.includes(trimmedSearchWord) ||
      itemNumber.includes(trimmedSearchWord) ||
      location.includes(trimmedSearchWord) ||
      workEntry.includes(trimmedSearchWord)
    )
  }

  const [arrayToFilter, setArrayToFilter] = useState(() => () =>
    notArchivedItems
  )

  const checkBoxItems = [
    {
      name: ItemStatusCheckBoxTypes.ALL,
      count: notArchivedItems.length,
      action: () => setArrayToFilter(() => () => notArchivedItems),
    },
    {
      name: ItemStatusCheckBoxTypes.IN_WAREHOUSE_ALL,
      count: itemCountByStatusAndArchive(
        ItemStatusTypes.IN_WAREHOUSE.id,
        false
      ),
      action: () =>
        setArrayToFilter(() => () =>
          itemsByStatusAndArchive(ItemStatusTypes.IN_WAREHOUSE.id, false)
        ),
    },
    {
      name: ItemStatusCheckBoxTypes.IN_WAREHOUSE_HEL,
      count: itemCountByStatusAndArchiveAndWarehouse(
        ItemStatusTypes.IN_WAREHOUSE.id,
        false,
        WarehouseTypes.HELSINKI
      ),
      action: () =>
        setArrayToFilter(() => () =>
          itemsByStatusAndArchiveAndWarehouse(
            ItemStatusTypes.IN_WAREHOUSE.id,
            false,
            WarehouseTypes.HELSINKI
          )
        ),
    },
    {
      name: ItemStatusCheckBoxTypes.IN_WAREHOUSE_TUR,
      count: itemCountByStatusAndArchiveAndWarehouse(
        ItemStatusTypes.IN_WAREHOUSE.id,
        false,
        WarehouseTypes.TURKU
      ),
      action: () =>
        setArrayToFilter(() => () =>
          itemsByStatusAndArchiveAndWarehouse(
            ItemStatusTypes.IN_WAREHOUSE.id,
            false,
            WarehouseTypes.TURKU
          )
        ),
    },
    {
      name: ItemStatusCheckBoxTypes.RESERVED,
      count: itemCountByStatusAndArchive(ItemStatusTypes.RESERVED.id, false),
      action: () =>
        setArrayToFilter(() => () =>
          itemsByStatusAndArchive(ItemStatusTypes.RESERVED.id, false)
        ),
    },
    {
      name: ItemStatusCheckBoxTypes.PICKUP,
      count: itemCountByStatusAndArchive(ItemStatusTypes.PICKUP.id, false),
      action: () =>
        setArrayToFilter(() => () =>
          itemsByStatusAndArchive(ItemStatusTypes.PICKUP.id, false)
        ),
    },
    {
      name: ItemStatusCheckBoxTypes.SUBCONTRACTOR,
      count: notArchivedSubcontractorItems.length,
      action: () => setArrayToFilter(() => () => notArchivedSubcontractorItems),
    },
    {
      name: ItemStatusCheckBoxTypes.ARCHIVED,
      count: archivedItems.length,
      action: () => setArrayToFilter(() => () => archivedItems),
    },
  ]

  return (
    <div className="DesktopItemTable">
      <div className="DesktopItemTable__search">
        <DesktopSearchInput
          name="Hae laitenimellä, -numerolla, kohteella tai työllä"
          type="text"
          eventHandler={setSearchWord}
          value={searchWord}
          required={false}
        />
        <DesktopCheckboxContainer
          labels_and_actions={checkBoxItems}
          radio_group="actions_by_status"
          checked={ItemStatusCheckBoxTypes.ALL}
        />
      </div>

      {arrayToFilter().filter((item) => findItems(item, searchWord)).length ? (
        <DesktopBaseTable
          columns={['Laite', 'Laitenumero', 'Tila', 'Kohde', 'Työ']}
        >
          {arrayToFilter()
            .filter((item) => findItems(item, searchWord))
            .sort(compareByPropName('item_type_id', 'name'))
            .map((item) => (
              <Row key={item.id}>
                <Cell>
                  <Link
                    to={`/items/${item.id}`}
                    className="DesktopItemTableRow__link"
                  >
                    {item.item_type_id.name}
                  </Link>{' '}
                </Cell>
                <Cell className="cell-collapse cell-style__monospace">
                  {!item.is_subcontractor
                    ? item.item_number
                    : ItemStatusTypes.SUBCONTRACTOR.description}
                </Cell>
                {!item.archived ? (
                  <Cell
                    className={`cell-collapse cell-center cell-style__data--${item.item_status_id.id} warehouse-color--${item.warehouse}`}
                  >
                    {item.item_status_id.description}
                    {item.item_status_id.id ===
                      ItemStatusTypes.IN_WAREHOUSE.id &&
                    warehouseShortName[item.warehouse]
                      ? `\xa0(${warehouseShortName[item.warehouse]})`
                      : ''}
                  </Cell>
                ) : (
                  <DesktopTableArchiveCell text="Arkistoitu" />
                )}
                <Cell>
                  {item?.work_entry_id?.location_id?.name && (
                    <Link
                      to={`/locations/${item.work_entry_id.location_id.id}`}
                      className="DesktopItemTableRow__link"
                    >
                      {item.work_entry_id.location_id.name}
                    </Link>
                  )}
                  {item?.work_entry_id?.length &&
                    item.work_entry_id.map((entry) => (
                      <div key={entry.location_id}>
                        <Link
                          to={`/locations/${entry.location_id.id}`}
                          className="DesktopItemTableRow__link"
                        >
                          {entry.location_id.name}
                        </Link>
                      </div>
                    ))}
                </Cell>
                <Cell>
                  {item?.work_entry_id?.name && (
                    <Link
                      to={`/works/${item.work_entry_id.id}`}
                      className="DesktopItemTableRow__link"
                    >
                      {item.work_entry_id.name}
                    </Link>
                  )}
                  {item?.work_entry_id?.length &&
                    item.work_entry_id.map((entry) => (
                      <div key={entry.id}>
                        <Link
                          to={`/works/${entry.id}`}
                          className="DesktopItemTableRow__link"
                        >
                          {entry.name}
                        </Link>
                      </div>
                    ))}
                </Cell>
              </Row>
            ))}
        </DesktopBaseTable>
      ) : (
        <DesktopNoResultsMessage
          message={`Tuloksia ei löydetty hakuehdoilla ${searchWord}`}
        />
      )}
    </div>
  )
})

export default DesktopItemTable

import React from 'react'
import PropTypes from 'prop-types'
import QRCode from 'qrcode.react'

export default function QRSticker({ item_number }) {
  return (
    <div className="QRSticker">
      <div className="QRSticker__inner">
        <QRCode
          value={item_number}
          renderAs="svg"
          size={180}
          level="H"
          imageSettings={{
            src: '/logo64.png',
            x: null,
            y: null,
            height: 32,
            width: 32,
            excavate: true,
          }}
        />
        <p>{item_number}</p>
      </div>
    </div>
  )
}

QRSticker.propTypes = {
  item_number: PropTypes.string,
}

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import { SelectInput } from '../SelectInput'
import DesktopSearchInput from './DesktopSearchInput'
import DesktopDateInput from './DesktopDateInput'
import DesktopCheckboxContainer from './DesktopCheckboxContainer'
import { ItemStatusTypes } from '../../types/ItemStatusTypes'
import { CreateItemCheckBoxTypes } from '../../types/CreateItemCheckBoxTypes'
import { useStores } from '../../hooks/use-stores'
import { checkSuccess, notifyError } from '../../helpers/notificationHelpers'
import { requestState } from '../../helpers/requestState'

const DesktopAddItemToWorkPrompt = (props) => {
  const { itemStore, itemActionStore } = useStores()
  const {
    notArchivedVertiaItemsByStatus,
    notArchivedSubcontractorItems,
  } = itemStore
  const { createItemAction, createSubcontractorItemAction } = itemActionStore

  const [isVertia, setIsVertia] = useState(true)
  const [itemType, setItemType] = useState('')
  const [itemNumbersOfType, setItemNumbersOfType] = useState([])
  const [selectedItemNumbers, setSelectedItemNumbers] = useState([])
  const [itemQuantity, setItemQuantity] = useState('1')
  const [itemStartDate, setItemStartDate] = useState('')

  const clearFields = () => {
    setItemType('')
    setItemNumbersOfType([])
    setItemQuantity('1')
    setSelectedItemNumbers([])
    setItemStartDate('')
  }

  // Find available Vertia items
  const availableVertiaItems = notArchivedVertiaItemsByStatus(
    ItemStatusTypes.IN_WAREHOUSE.id
  )

  // Group available Vertia items and add count of similar types. Extract unique objects from array by type and count
  const groupUniqueVertiaItemTypesWithCount = () => {
    const vertiaItemsWithCount = availableVertiaItems.map((item) => ({
      name: item.item_type_id.name,
      count: availableVertiaItems.filter(
        (itemToCompare) =>
          itemToCompare.item_type_id.name === item.item_type_id.name
      ).length,
    }))

    return R.uniqWith(R.eqBy(R.props(['name', 'count'])), vertiaItemsWithCount)
  }

  // Make grouped Vertia items suitable for react-select component
  const vertiaSelectOptions = groupUniqueVertiaItemTypesWithCount().map(
    (type) => ({
      value: type.name,
      label: `${type.name} (${type.count} kpl vapaana)`,
      type,
    })
  )

  // Make subcontractor items suitable for react-select component
  const subcontractorSelectOptions = notArchivedSubcontractorItems.map(
    (item) => ({
      value: item.item_type_id.name,
      label: item.item_type_id.name,
      type: { id: item.item_type_id.id, name: item.item_type_id.name },
    })
  )

  const handleSelect = (selectedOption) => {
    setItemType(selectedOption)
    setItemQuantity('1')
    setSelectedItemNumbers([])
    setItemNumbersOfType(
      itemStore
        .itemsByTypeAndStatus(
          selectedOption.type.name,
          ItemStatusTypes.IN_WAREHOUSE.id,
          false
        )
        .map((item) => ({
          label: item.item_number,
          value: item.item_number,
          item,
        }))
    )
  }

  // Validation for both cases

  const validateCommon = () => {
    if (!itemType) {
      return notifyError('Laitteita ei lisätty: valitse laite')
    }

    if (!isVertia && (!itemQuantity || itemQuantity <= 0)) {
      return notifyError('Laitteita ei lisätty: lisää ainakin 1 laite')
    }

    if (isVertia && selectedItemNumbers.length <= 0) {
      return notifyError(
        'Laitteita ei lisätty: Valitse ainakin yksi laitenumero'
      )
    }

    if (!itemStartDate) {
      return notifyError('Laitteita ei lisätty: lisää aloituspäivä')
    }

    return true
  }

  // Add Vertia items
  const sendVertiaItems = async () => {
    if (!validateCommon()) {
      return
    }

    const selectedItemsForScan = selectedItemNumbers.map((i) => i.item)

    const items = selectedItemsForScan.map((item) => ({
      item_id: item.id,
      work_entry_id: props.work,
      date_start: itemStartDate,
    }))

    await createItemAction({ items })

    const isSuccess = itemActionStore.itemActionState === requestState.CREATED

    checkSuccess(
      isSuccess,
      `${selectedItemsForScan.length} kpl ${itemType.type.name} lisätty`,
      `Laitteita ${itemType.type.name} ei voitu lisätä`
    )

    return props.onClose()
  }

  // Add subcontractor items
  const sendSubcontractorItems = async () => {
    if (!validateCommon()) {
      return
    }

    const items = {
      item_count: itemQuantity,
      work_entry_id: props.work,
      date_start: itemStartDate,
      item_type_id: itemType.type.id,
    }

    await createSubcontractorItemAction(items)

    const isSuccess = itemActionStore.itemActionState === requestState.CREATED

    checkSuccess(
      isSuccess,
      `${itemQuantity} kpl alihankkijan ${itemType.type.name} lisätty`,
      `Alihankkijan laitteita ${itemType.type.name} ei voitu lisätä`
    )

    return props.onClose()
  }

  const handleFormChange = (state) => {
    clearFields()
    setIsVertia(state)
  }

  const checkBoxItems = [
    {
      name: CreateItemCheckBoxTypes.OWN,
      count: null,
      action: () => handleFormChange(true),
    },
    {
      name: CreateItemCheckBoxTypes.SUBCONTRACTOR,
      count: null,
      action: () => handleFormChange(false),
    },
  ]

  return (
    <div className="DesktopAddItemToWorkPrompt">
      <h1 className="DesktopAddItemToWorkPrompt__title">{props.title}</h1>
      <p className="DesktopAddItemToWorkPrompt__body">{props.message}</p>
      <DesktopCheckboxContainer
        labels_and_actions={checkBoxItems}
        radio_group="items_to_work"
        checked={CreateItemCheckBoxTypes.OWN}
      />
      <form className="DesktopAddItemToWorkPrompt__form">
        <SelectInput
          name="Laitetyyppi"
          options={isVertia ? vertiaSelectOptions : subcontractorSelectOptions}
          handleSelect={handleSelect}
          value={itemType}
        />
        {isVertia && (
          <SelectInput
            multi
            name="Laitenumero(t)"
            options={itemNumbersOfType}
            handleSelect={setSelectedItemNumbers}
            value={selectedItemNumbers}
            disabled={!itemType}
          />
        )}
        {!isVertia && (
          <DesktopSearchInput
            name="Määrä"
            type="number"
            required={true}
            value={itemQuantity}
            eventHandler={setItemQuantity}
            min="0"
            max={isVertia && itemType ? itemType.count.toString() : ''}
            disabled={!itemType}
          />
        )}
        <DesktopDateInput
          name="Aloituspäivä"
          eventHandler={setItemStartDate}
          value={itemStartDate}
        />
      </form>
      <div className="DesktopAddItemToWorkPrompt__buttons">
        <button
          className="DesktopPromptButton DesktopPromptButton--positive"
          onClick={() =>
            isVertia ? sendVertiaItems() : sendSubcontractorItems()
          }
          disabled={
            !(
              itemType &&
              ((isVertia && selectedItemNumbers?.length > 0) ||
                (!isVertia && Number(itemQuantity))) &&
              itemStartDate
            )
          }
        >
          Lisää
        </button>
        <button
          className="DesktopPromptButton DesktopPromptButton--negative"
          onClick={props.onClose}
        >
          Peruuta
        </button>
      </div>
    </div>
  )
}

DesktopAddItemToWorkPrompt.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  work: PropTypes.number,
  onClose: PropTypes.func,
}

export default DesktopAddItemToWorkPrompt

import { types, flow } from 'mobx-state-tree'
import { values } from 'mobx'
import { ItemType } from '../models/ItemType'
import { findValue } from '../helpers/filterHelpers'
import axios from 'axios'
import { requestState } from '../helpers/requestState'
import { compareByPropName } from '../utils/sortUtils'
import { API_BASE } from '../helpers/urlHelpers'

export const ItemTypeStore = types
  .model('ItemTypeStore', {
    item_types: types.map(ItemType, {}),
    itemTypeState: types.optional(types.string, requestState.NONE),
  })
  .views((self) => ({
    get allItemTypes() {
      return values(self.item_types)
    },
    get sortedItemTypes() {
      return values(self.item_types).sort(compareByPropName('name'))
    },
    filteredItemTypes(searchWord) {
      return self.sortedItemTypes.filter((item) =>
        findValue(item.name, searchWord)
      )
    },
    getItemTypeById(id) {
      return values(self.item_types).find((i) => i.id === id)
    },
  }))
  .actions((self) => ({
    updateItemTypeName: flow(function* (id, name) {
      // if there are ever more fields than just name, make a more generic update function
      try {
        const res = yield axios.patch(API_BASE + `/item_types/${id}`, {
          id,
          name,
        })

        if (res.status === 200) {
          self.item_types.put(res.data)
          self.itemTypeState = requestState.UPDATED
        }
      } catch (error) {
        self.itemTypeState = requestState.ERROR
        throw error
      }
    }),
    fetchItemTypes: flow(function* () {
      self.itemTypeState = requestState.LOADING

      try {
        const response = yield axios.get(API_BASE + '/item_types')

        if (response.status === 200) {
          response.data.forEach((item_type) => {
            self.item_types.put(item_type)
          })

          self.itemTypeState = requestState.LOADED
        }
      } catch (error) {
        self.itemTypeState = requestState.ERROR
      }
    }),
    createItemType: flow(function* createItemType(itemType) {
      self.itemTypeState = requestState.LOADING

      try {
        const response = yield axios.post(API_BASE + '/item_types', itemType)

        if (response.status === 201) {
          self.item_types.put(response.data)
          self.itemTypeState = requestState.CREATED
        }
      } catch (error) {
        self.itemTypeState = requestState.ERROR
      }
    }),
    getUtilization: flow(function* getUtilization(start_date, end_date) {
      try {
        const response = yield axios.get(
          API_BASE +
            `/utilisation?date_start=${start_date}&date_end=${end_date}`
        )
        if (response.status === 200) {
          return response.data
        }
      } catch (error) {
        throw error
      }
    }),
  }))

import React from 'react'
import DesktopAddItemToWorkPrompt from '../components/Desktop/DesktopAddItemToWorkPrompt'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'

export const createAddItemToWorkPrompt = (title, message, work) => {
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <DesktopAddItemToWorkPrompt
          title={title}
          message={message}
          work={work}
          onClose={onClose}
        />
      )
    },
    closeOnClickOutside: false,
  })
}

import React from 'react'
import PropTypes from 'prop-types'

const DesktopDeviceStatusInformationBox = (props) => {
  if (!props.visible) {
    return null
  }

  return (
    <div className="DesktopDeviceStatusInformationBox">
      <p className="DesktopDeviceStatusInformationBox__title">{props.title}</p>
      <ul className="DesktopDeviceStatusInformationBox__item-list">
        {props.options.map((option) => (
          <li key={option.type}>
            {option.type}: {option.count} kpl
          </li>
        ))}
      </ul>
    </div>
  )
}

DesktopDeviceStatusInformationBox.propTypes = {
  title: PropTypes.string,
  no_result_text: PropTypes.string,
  options: PropTypes.array,
  visible: PropTypes.number,
}

export default DesktopDeviceStatusInformationBox

import React from 'react'
import { useStores } from '../../../hooks/use-stores'
import DesktopFrontPage from './DesktopFrontPage'
import { Route, Switch } from 'react-router-dom'
import DesktopLocationPage from './DesktopLocationPage/DesktopLocationPage'
import DesktopLocationEditPage from './DesktopLocationEditPage/DesktopLocationEditPage'
import DesktopSingleLocationPage from './DesktopSingleLocationPage/DesktopSingleLocationPage'
import DesktopSingleWorkPage from './DesktopSingleWorkPage/DesktopSingleWorkPage'
import DesktopWorkEditPage from './DesktopWorkEditPage/DesktopWorkEditPage'
import DesktopInvoicingPage from './DesktopInvoicingPage/DesktopInvoicingPage'
import DesktopAdminUsersPage from './DesktopAdminUsersPage/DesktopAdminUsersPage'
import DesktopSingleWorkInvoicePage from './DesktopSingleWorkInvoicePage/DesktopSingleWorkInvoicePage'
import DesktopItemTypePage from './DesktopItemTypePage/DesktopItemTypePage'
import DesktopItemPage from './DesktopItemPage'
import DesktopCreateItemPage from './DesktopCreateItemPage/DesktopCreateItemPage'
import DesktopSingleItemPage from './DesktopSingleItemPage'
import DesktopSingleItemEditPage from './DesktopSingleItemEditPage'
import DesktopUtilizationPage from './DesktopUtilizationPage'

const DesktopContentContainer = () => {
  const { locationStore, workStore, itemStore } = useStores()
  const { locationById } = locationStore
  const { workById } = workStore
  const { itemById } = itemStore

  return (
    <div className="DesktopContentContainer">
      <Switch>
        <Route exact path="/" component={DesktopFrontPage}></Route>
        <Route exact path="/locations" component={DesktopLocationPage}></Route>
        <Route
          exact
          path="/locations/:id"
          component={({ match }) => (
            <DesktopSingleLocationPage
              location={locationById(parseInt(match.params.id))}
            />
          )}
        />
        <Route
          exact
          path="/locations/:id/edit"
          component={({ match }) => (
            <DesktopLocationEditPage
              location={locationById(parseInt(match.params.id))}
            />
          )}
        />
        <Route
          exact
          path="/works/:id"
          component={({ match }) => (
            <DesktopSingleWorkPage work={workById(parseInt(match.params.id))} />
          )}
        />
        <Route
          exact
          path="/works/:id/edit"
          component={({ match }) => (
            <DesktopWorkEditPage work={workById(parseInt(match.params.id))} />
          )}
        />
        <Route exact path="/invoicing" component={DesktopInvoicingPage}></Route>
        <Route
          exact
          path="/invoicing/:id"
          component={({ match }) => (
            <DesktopSingleWorkInvoicePage
              work={workById(parseInt(match.params.id))}
            />
          )}
        />
        <Route exact path="/users" component={DesktopAdminUsersPage}></Route>
        <Route exact path="/items" component={DesktopItemPage}></Route>
        <Route
          exact
          path="/items/utilization"
          component={DesktopUtilizationPage}
        ></Route>
        <Route
          exact
          path="/items/types"
          component={DesktopItemTypePage}
        ></Route>
        <Route
          exact
          path="/items/add"
          component={DesktopCreateItemPage}
        ></Route>
        <Route
          exact
          path="/items/:id"
          component={({ match }) => (
            <DesktopSingleItemPage item={itemById(parseInt(match.params.id))} />
          )}
        />
        <Route
          exact
          path="/items/:id/edit"
          component={({ match }) => (
            <DesktopSingleItemEditPage
              item={itemById(parseInt(match.params.id))}
            />
          )}
        />
      </Switch>
    </div>
  )
}

export default DesktopContentContainer

import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import { useStores } from '../../hooks/use-stores'
import { requestState } from '../../helpers/requestState'
import { notifyError } from '../../helpers/notificationHelpers'
import { BrowserRouter as Router } from 'react-router-dom'
import LoadingPage from '../LoadingPage'
import DesktopHeader from './DesktopHeader'
import DesktopNavigation from './DesktopNavigation/DesktopNavigation'
import DesktopContentContainer from './DesktopContentContainer/DesktopContentContainer'

const DesktopContainer = observer(() => {
  const [isLoading, setIsLoading] = useState(true)
  const [loadingText, setLoadingText] = useState('Ladataan...')
  const {
    billingStatusStore,
    itemActionStore,
    itemStore,
    itemStatusStore,
    itemTypeStore,
    locationStore,
    workStore,
    workStatusStore,
    userStore,
  } = useStores()

  useEffect(() => {
    const { fetchItemActions } = itemActionStore
    const { fetchItems } = itemStore
    const { fetchItemStatuses } = itemStatusStore
    const { fetchItemTypes } = itemTypeStore
    const { fetchLocations } = locationStore
    const { fetchWorks } = workStore
    const { fetchWorkStatuses } = workStatusStore
    const { fetchBillingStatuses } = billingStatusStore

    const initializeItems = async () => {
      if (itemStore.itemState === requestState.NONE) {
        await fetchItems()
        setLoadingText('Ladataan laitteita, valmis')
      }

      if (itemStore.itemState === requestState.ERROR) {
        notifyError('Laitteita ei voitu ladata')
      }
    }

    const initializeItemStatuses = async () => {
      if (itemStatusStore.itemStatusState === requestState.NONE) {
        await fetchItemStatuses()
        setLoadingText('Ladataan laitteiden tiloja, valmis')
      }

      if (itemStatusStore.itemStatusState === requestState.ERROR) {
        notifyError('Laitteiden tiloja ei voitu ladata')
      }
    }

    const initializeItemTypes = async () => {
      if (itemTypeStore.itemTypeState === requestState.NONE) {
        await fetchItemTypes()
        setLoadingText('Ladataan laitetyyppejä, valmis')
      }

      if (itemTypeStore.itemTypeState === requestState.ERROR) {
        notifyError('Laitetyyppejä ei voitu ladata')
      }
    }
    const initializeLocations = async () => {
      if (locationStore.locationState === requestState.NONE) {
        await fetchLocations()
        setLoadingText('Ladataan kohteita, valmis')
      }

      if (locationStore.locationState === requestState.ERROR) {
        notifyError('Kohteita ei voitu ladata')
      }
    }

    const initializeWorks = async () => {
      if (workStore.workState === requestState.NONE) {
        await fetchWorks()
        setLoadingText('Ladataan töitä, valmis')
      }

      if (workStore.workState === requestState.ERROR) {
        notifyError('Töitä ei voitu ladata')
      }
    }

    const initializeWorkStatuses = async () => {
      if (workStatusStore.statusState === requestState.NONE) {
        await fetchWorkStatuses()
        setLoadingText('Ladataan töiden statuksia, valmis')
      }

      if (workStatusStore.statusState === requestState.ERROR) {
        notifyError('Töiden statuksia ei voitu ladata')
      }
    }

    const initializeItemActions = async () => {
      if (itemActionStore.itemActionState === requestState.NONE) {
        await fetchItemActions()
        setLoadingText('Ladataan laitetapahtumia, valmis')
      }

      if (itemActionStore.itemActionState === requestState.ERROR) {
        notifyError('Laitetapahtumia ei voitu ladata')
      }
    }

    const initializeBillingStatuses = async () => {
      if (billingStatusStore.billingStatusState === requestState.NONE) {
        await fetchBillingStatuses()
        setLoadingText('Ladataan laskujen statuksia, valmis')
      }

      if (billingStatusStore.billingStatusState === requestState.ERROR) {
        notifyError('Laskujen statuksia ei voitu ladata')
      }
    }

    const initializeUsers = async () => {
      if (userStore.userState === requestState.NONE) {
        if (userStore.user.admin) {
          await userStore.fetchUsers()
        } else {
          userStore.populateUsersWithSelfOnly()
        }
      }

      if (userStore.userState === requestState.ERROR) {
        notifyError('Käyttäjiä ei voitu ladata')
      }
    }

    const fetchData = async () => {
      await Promise.all([
        initializeItemStatuses(),
        initializeItemTypes(),
        initializeLocations(),
        initializeWorks(),
        initializeWorkStatuses(),
        initializeItemActions(),
        initializeBillingStatuses(),
      ])
      await initializeItems()
      await initializeUsers()
      setIsLoading(false)
    }
    fetchData()
  }, [
    billingStatusStore,
    itemActionStore,
    itemStore,
    itemStatusStore,
    itemTypeStore,
    locationStore,
    workStatusStore,
    workStore,
    userStore,
  ])

  return (
    <>
      {isLoading ? (
        <LoadingPage title={loadingText} loading={isLoading} />
      ) : (
        <div className="DesktopContainer">
          <Router>
            <DesktopHeader />
            <div className="DesktopContainer DesktopContainer__items">
              <DesktopNavigation />
              <DesktopContentContainer />
            </div>
          </Router>
        </div>
      )}
    </>
  )
})

export default DesktopContainer

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { useStores } from '../../../../hooks/use-stores'
import { compareByMultiplePropNames } from '../../../../utils/sortUtils'
import { findValue } from '../../../../helpers/filterHelpers'
import DesktopSearchInput from '../../DesktopSearchInput'
import DesktopCheckboxContainer from '../../DesktopCheckboxContainer'
import DesktopSingleWorkItemGroupTableRow from './DesktopSingleWorkItemGroupTableRow'
import { ItemActionCheckBoxTypes } from '../../../../types/ItemActionCheckBoxTypes'
import { TableCellContentTypes } from '../../../../types/TableCellContentTypes'
import DesktopNoResultsMessage from '../../DesktopNoResultMessage'
import * as R from 'ramda'
import DesktopBaseTable from '../../DesktopBaseTable'

const DesktopSingleWorkItemGroupTable = observer((props) => {
  const { itemActionStore } = useStores()
  const {
    itemActionsByWork,
    openItemActionsByWork,
    subcontractorItemActionsByWork,
    closedItemActionsByWork,
  } = itemActionStore
  const [itemSearchWord, setItemSearchWord] = useState('')

  const itemActionsInGroup = (itemActions) => {
    let actions = []
    itemActions.forEach((action) => {
      const actionObject = {
        id: action.id,
        item_type: action.item_id.item_type_id.name,
        item_id: action.item_id.id,
        owner: !action.item_id.is_subcontractor
          ? TableCellContentTypes.OWNER.vertia
          : TableCellContentTypes.OWNER.subcontractor,
        date_start: action.date_start,
        date_end: action.date_end === null ? '' : action.date_end,
      }

      actions.push(actionObject)
    })

    actions.forEach((thisAction) => {
      const similarActions = actions.filter(
        (action) =>
          action.item_type === thisAction.item_type &&
          action.owner === thisAction.owner &&
          action.date_start === thisAction.date_start &&
          action.date_end === thisAction.date_end
      )

      thisAction.count = similarActions.length
      thisAction.id_list = similarActions.map((action) => action.item_id)
      thisAction.item_action_list = similarActions.map((action) => action.id)
    })

    return R.uniqWith(
      R.eqBy(
        R.props(['item_type', 'date_start', 'date_end', 'count', 'owner'])
      ),
      actions
    )
  }

  const allItems = (searchWord) => {
    const itemActions = itemActionsByWork(props.work.id)
    const filteredArray = itemActionsInGroup(itemActions).filter((action) =>
      findValue(action.item_type, searchWord)
    )
    return filteredArray
  }

  const reservedItems = (searchWord) => {
    const itemActions = openItemActionsByWork(props.work.id)
    const filteredArray = itemActionsInGroup(itemActions).filter((action) =>
      findValue(action.item_type, searchWord)
    )
    return filteredArray
  }

  const closedItems = (searchWord) => {
    const itemActions = closedItemActionsByWork(props.work.id)
    const filteredArray = itemActionsInGroup(itemActions).filter((action) =>
      findValue(action.item_type, searchWord)
    )
    return filteredArray
  }

  const subcontractorItems = (searchWord) => {
    const itemActions = subcontractorItemActionsByWork(props.work.id)
    const filteredArray = itemActionsInGroup(itemActions).filter((action) =>
      findValue(action.item_type, searchWord)
    )
    return filteredArray
  }

  const [arrayToFilter, setArrayToFilter] = useState(() => allItems)

  const sortBy = [
    {
      prop: 'date_end',
      direction: 1,
    },
    {
      prop: 'date_start',
      direction: 1,
    },
  ]

  const checkBoxItems = [
    {
      name: ItemActionCheckBoxTypes.ALL,
      count: allItems('').length,
      action: () => setArrayToFilter(() => allItems),
    },
    {
      name: ItemActionCheckBoxTypes.RESERVED,
      count: reservedItems('').length,
      action: () => setArrayToFilter(() => reservedItems),
    },
    {
      name: ItemActionCheckBoxTypes.PREVIOUS,
      count: closedItems('').length,
      action: () => setArrayToFilter(() => closedItems),
    },
    {
      name: ItemActionCheckBoxTypes.SUBCONTRACTOR,
      count: subcontractorItems('').length,
      action: () => setArrayToFilter(() => subcontractorItems),
    },
  ]

  return (
    <div className="DesktopSingleWorkItemGroupTable">
      <div className="DesktopSingleWorkItemGroupTable__search">
        <DesktopSearchInput
          name="Hae laitteen nimellä"
          type="text"
          eventHandler={setItemSearchWord}
          value={itemSearchWord}
          required={false}
        />
        <DesktopCheckboxContainer
          labels_and_actions={checkBoxItems}
          radio_group="actions_by_status"
          checked={ItemActionCheckBoxTypes.ALL}
        />
      </div>
      {arrayToFilter(itemSearchWord).length ? (
        <DesktopBaseTable
          columns={[
            'Laite',
            'Omistaja',
            'Määrä',
            'Aloitus',
            'Lopetus',
            'Käsittele',
            '',
          ]}
        >
          {arrayToFilter(itemSearchWord)
            .sort(compareByMultiplePropNames(sortBy))
            .map((action) => (
              <DesktopSingleWorkItemGroupTableRow
                id={action.id}
                key={action.id}
                id_list={action.id_list}
                item_action_list={action.item_action_list}
                work_id={props.work.id}
                item_type={action.item_type}
                owner={action.owner}
                count={action.count}
                date_start={action.date_start}
                date_end={action.date_end}
              />
            ))}
        </DesktopBaseTable>
      ) : (
        <DesktopNoResultsMessage
          message={`Laitetapahtumia ei löydetty hakuehdoilla ${itemSearchWord}`}
        />
      )}
    </div>
  )
})

DesktopSingleWorkItemGroupTable.propTypes = {
  work: PropTypes.object,
}

export default DesktopSingleWorkItemGroupTable

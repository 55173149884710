import { observer } from 'mobx-react'
import { useStores } from '../../hooks/use-stores'
import LoginForm from './LoginForm'
import React from 'react'

const LoginPage = observer(() => {
  const { userStore } = useStores()

  return (
    <div className="LoginPage">
      <div className="LoginPage_container">
        {!userStore.loggedIn && <LoginForm />}
      </div>
    </div>
  )
})

export default LoginPage

export const ItemStatusCheckBoxTypes = {
  ALL: 'Kaikki',
  ARCHIVED: 'Arkistoitu',
  IN_WAREHOUSE_ALL: 'Varastossa (Kaikki)',
  IN_WAREHOUSE_HEL: 'Varastossa (Helsinki)',
  IN_WAREHOUSE_TUR: 'Varastossa (Turku)',
  REMOVED: 'Poistettu',
  RESERVED: 'Varattu',
  PICKUP: 'Noudettavissa',
  SUBCONTRACTOR: 'Alihankkijan',
}

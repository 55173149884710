import React from 'react'
import PropTypes from 'prop-types'

const DesktopNoResultsMessage = (props) => {
  return <div className="DesktopNoResultsMessage">{props.message}</div>
}

DesktopNoResultsMessage.propTypes = {
  message: PropTypes.string,
}

export default DesktopNoResultsMessage

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useStores } from '../../../../../hooks/use-stores'
import { requestState } from '../../../../../helpers/requestState'
import { BillingStatusTypes } from '../../../../../types/BillingStatusTypes'
import { ItemStatusTypes } from '../../../../../types/ItemStatusTypes'
import { ReleaseButtonTypes } from '../../../../../types/ReleaseButtonTypes'
import {
  notifyError,
  checkSuccess,
} from '../../../../../helpers/notificationHelpers'
import DesktopTableReleaseButton from '../../../DesktopTableReleaseButton'
import { Row, Cell } from 'react-sticky-table'
import { createConfirmationPrompt } from '../../../../../helpers/confirmationPrompt'

const DesktopSingleWorkInvoiceTableRow = (props) => {
  const { itemActionStore } = useStores()
  const { invoiceItemsFinal, invoiceItemsPartial } = itemActionStore

  const itemScannedOut = !!props.date_end
  const itemBilled =
    props.date_end && props.billing_status === BillingStatusTypes.BILLED.id
  const minDate = props.date_end ? props.date_end : props.date_start
  const today = moment(Date.now()).format('yyyy-MM-DD')
  const [endDate, setEndDate] = useState('')

  const validateCommon = (partial = false) => {
    if (props.date_start > today) {
      return notifyError('Lopetuspäivä ei voi olla ennen aloituspäivää')
    }

    const partialInvoiceDate = endDate.length === 0 ? null : endDate

    const item_actions = partial
      ? [
          {
            item_action_id: props.item_action_id,
            partial_billing_date: partialInvoiceDate,
          },
        ]
      : [{ item_action_id: props.item_action_id }]
    return item_actions
  }

  const confirmAndInvoiceItem = () => {
    const item_actions = validateCommon()

    if (!item_actions) {
      return
    }

    createConfirmationPrompt(
      'Laskuta laitteet',
      `Oletko varma, että haluat laskuttaa laitteen ${props.item_type}`,
      'Kyllä',
      () => invoiceItem(item_actions),
      'Ei'
    )
  }

  const invoiceItem = async (item_actions) => {
    await invoiceItemsFinal({ item_actions })
    const isSuccess = itemActionStore.itemActionState === requestState.UPDATED
    return checkSuccess(
      isSuccess,
      'Loppulaskutus: Laite laskutettu onnistuneesti',
      'Loppulaskutus: Laitetta ei voitu merkitä laskutetuksi'
    )
  }

  const confirmAndInvoicePartialItem = () => {
    const item_actions = validateCommon(true)

    if (!item_actions) {
      return
    }

    const todayOrEndDate = endDate.length === 0 ? today : endDate

    const daysToInvoice = moment(todayOrEndDate).diff(
      moment(props.date_start),
      'days'
    )
    const billingPeriodDescription = `Laskutusväli ${moment(
      props.date_start
    ).format('D.M.Y')} – ${moment(todayOrEndDate).format(
      'D.M.Y'
    )} (${daysToInvoice} päivä${daysToInvoice === 1 ? '' : 'ä'})`

    console.log('partial_billing_date single table', endDate)

    createConfirmationPrompt(
      'Laskuta laitteet',
      [
        `Oletko varma, että haluat tehdä välilaskutuksen laitteesta ${props.item_type}?`,
        billingPeriodDescription,
      ],
      'Kyllä',
      //here
      () => invoicePartialItem(item_actions),
      'Ei'
    )
  }

  const invoicePartialItem = async (item_actions) => {
    await invoiceItemsPartial({ item_actions })
    const isSuccess = itemActionStore.itemActionState === requestState.UPDATED
    return checkSuccess(
      isSuccess,
      'Välilaskutus: Laite laskutettu onnistuneesti',
      'Välilaskutus: Laitetta ei voitu merkitä laskutetuksi'
    )
  }

  return (
    <Row>
      <Cell>
        <span
          role="img"
          aria-label="status-label"
          className={`cell-style__status cell-style__status--inv${props.billing_status}`}
        >
          &#9673;
        </span>
        {props.item_type}
      </Cell>
      <Cell>{props.added_by}</Cell>
      <Cell className="cell-collapse">
        {moment(props.date_start).format('D.M.Y')}
      </Cell>
      <Cell className="cell-collapse">
        {itemScannedOut ? (
          moment(props.date_end).format('D.M.Y')
        ) : (
          <input
            type="date"
            className="cell-style__date-input"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            min={minDate}
          />
        )}
      </Cell>
      <Cell className="cell-collapse">
        {itemBilled ? moment(props.updated_at).format('D.M.Y') : ''}
      </Cell>
      <Cell className="cell-collapse">
        {itemScannedOut ? (
          <DesktopTableReleaseButton
            action={confirmAndInvoiceItem}
            item_status={ItemStatusTypes.IN_WAREHOUSE.id}
            text={ReleaseButtonTypes.INVOICE}
            disabled={itemBilled}
          />
        ) : (
          <DesktopTableReleaseButton
            action={confirmAndInvoicePartialItem}
            item_status={ItemStatusTypes.PICKUP.id}
            text={ReleaseButtonTypes.PARTIAL_INVOICE}
          />
        )}
      </Cell>
    </Row>
  )
}

DesktopSingleWorkInvoiceTableRow.propTypes = {
  item_action_id: PropTypes.number,
  billing_status: PropTypes.number,
  item_type: PropTypes.string,
  date_start: PropTypes.string,
  date_end: PropTypes.string,
  added_by: PropTypes.string,
}

export default DesktopSingleWorkInvoiceTableRow

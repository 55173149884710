export const BillingStatusTypes = {
  NOT_BILLED: {
    description: 'Laskuttamatta',
    id: 1,
  },
  BILLED: {
    description: 'Laskutettu',
    id: 2,
  },
}

// For comparing string values
export const compareByPropName = (property, property2) => (a, b) => {
  if (property2) {
    if (
      a[property][property2].toLowerCase() <
      b[property][property2].toLowerCase()
    ) {
      return -1
    }

    if (
      a[property][property2].toLowerCase() >
      b[property][property2].toLowerCase()
    ) {
      return 1
    }

    return 0
  } else {
    if (a[property].toLowerCase() < b[property].toLowerCase()) {
      return -1
    }

    if (a[property].toLowerCase() > b[property].toLowerCase()) {
      return 1
    }

    return 0
  }
}

export const compareByMultiplePropNames = (sortBy) => {
  return (a, b) => {
    let i = 0,
      result = 0
    while (i < sortBy.length && result === 0) {
      const sortA = a[sortBy[i].prop]
        ? a[sortBy[i].prop].toString().toLowerCase()
        : ''
      const sortB = b[sortBy[i].prop]
        ? b[sortBy[i].prop].toString().toLowerCase()
        : ''

      result =
        sortBy[i].direction * (sortA < sortB ? -1 : sortA > sortB ? 1 : 0)
      i++
    }
    return result
  }
}

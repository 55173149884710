import React, { useState } from 'react'
import DesktopPageHeading from '../DesktopPageHeading'
import DesktopDateInput from '../DesktopDateInput'
import DesktopFormButton from '../DesktopFormButton'
import DesktopBaseTable from '../DesktopBaseTable'
import { useStores } from '../../../hooks/use-stores'
import { Row, Cell } from 'react-sticky-table'

const DesktopUtilizationPage = () => {
  const { itemTypeStore } = useStores()

  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [loading, setLoading] = useState(false)
  const [utilizations, setUtilizations] = useState([])
  const [error, setError] = useState('')

  async function getUtilization(e) {
    e.preventDefault()
    if (!startDate || !endDate) return

    setError(false)
    setLoading(true)
    itemTypeStore
      .getUtilization(startDate, endDate)
      .then((itemTypes) => {
        setUtilizations(
          itemTypes.map((itemType) => {
            return {
              item_type_id: itemType.item_type_id,
              item_type_name: itemTypeStore.getItemTypeById(
                itemType.item_type_id
              )?.name,
              utilization_percentage: itemType.utilization,
            }
          })
        )
      })
      .catch(() => {
        setError('Palvelimen virhe: Käyttöasteita ei voitu hakea.')
        // TODO: be more precise if error data provides useful info
      })
      .finally(() => setLoading(false))
  }

  return (
    <div className="DesktopUtilizationPage">
      <DesktopPageHeading
        title="Käyttöaste"
        subtitle="Tarkastele laitetyyppien käyttöasteita"
      />
      <form
        className="DesktopUtilizationPage__form"
        onSubmitCapture={getUtilization}
      >
        <DesktopDateInput
          name="Tarkastelujakso alkaa"
          eventHandler={setStartDate}
          max={endDate}
        />
        <DesktopDateInput
          name="Tarkastelujakso päättyy"
          eventHandler={setEndDate}
          min={startDate}
        />
        <DesktopFormButton title={loading ? 'Odota...' : 'Hae'} />
      </form>
      {utilizations.length > 0 && (
        <DesktopBaseTable columns={['Laitetyyppi', 'Käyttöaste (%)']}>
          {utilizations.map((util) => (
            <Row key={util.item_type_id}>
              <Cell>{util.item_type_name}</Cell>
              <Cell>{util.utilization_percentage}&nbsp;%</Cell>
            </Row>
          ))}
        </DesktopBaseTable>
      )}
      {error && <p className="DesktopUtilizationPage__error">{error}</p>}
    </div>
  )
}

export default DesktopUtilizationPage

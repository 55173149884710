import React from 'react'
import DesktopPageHeading from '../../DesktopPageHeading'
import DesktopInvoiceTable from './DesktopInvoiceTable'

const DesktopInvoicingPage = () => {
  return (
    <div className="DesktopInvoicingPage">
      <DesktopPageHeading
        title="Laskutus"
        subtitle="Selaa laskutettavia töitä"
      />
      <DesktopInvoiceTable />
    </div>
  )
}

export default DesktopInvoicingPage

import React from 'react'
import PropTypes from 'prop-types'

const DesktopPageHeading = (props) => {
  return (
    <div className="DesktopPageHeading">
      <h1 className="DesktopPageHeading__title">{props.title}</h1>
      <h2 className="DesktopPageHeading__subtitle">{props.subtitle}</h2>
    </div>
  )
}

DesktopPageHeading.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
}

export default DesktopPageHeading

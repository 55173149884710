import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

const DesktopCheckboxContainer = (props) => {
  useEffect(() => {
    const firstCheck = document.querySelector(`#${props.checked}`)
    if (!firstCheck) {
      return
    } else {
      firstCheck.setAttribute('checked', 1)
    }
  }, [props.checked])

  return (
    <div className="DesktopCheckboxContainer">
      {props.labels_and_actions
        ? props.labels_and_actions.map((item) => (
            <div key={item.name}>
              <input
                type="radio"
                name={props.radio_group}
                id={item.name}
                onClick={item.action}
              />
              <label htmlFor={item.name}>
                {item.name} {item.count ? <span>({item.count})</span> : null}
              </label>
            </div>
          ))
        : null}
    </div>
  )
}

DesktopCheckboxContainer.propTypes = {
  labels_and_actions: PropTypes.array,
  radio_group: PropTypes.string,
  checked: PropTypes.string,
}

export default DesktopCheckboxContainer

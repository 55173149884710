export const ItemActionCheckBoxTypes = {
  ALL: 'Kaikki',
  BILLED: 'Laskutettu',
  BY_TYPE: 'Ryhmittäin',
  BY_ITEM: 'Yksittäin',
  NOT_BILLED: 'Laskuttamatta',
  RESERVED: 'Käytössä',
  SUBCONTRACTOR: 'Alihankkijan',
  PREVIOUS: 'Menneet',
}

import React from 'react'
import { observer } from 'mobx-react'
import { NavLink, useLocation } from 'react-router-dom'
import DesktopInvoiceIndicatorCounter from './DesktopInvoiceIndicatorCounter'
import { useStores } from '../../../hooks/use-stores'

const DesktopNavigation = observer((props) => {
  const location = useLocation()
  const regexItems = RegExp('^/items')
  const regexLocations = RegExp('^/locations')
  const regexWorks = RegExp('^/works')

  const itemsActive = regexItems.test(location.pathname) ? true : false
  const locationsActive =
    regexLocations.test(location.pathname) || regexWorks.test(location.pathname)
      ? true
      : false

  const { userStore } = useStores()

  return (
    <nav className="DesktopNavigation">
      <NavLink
        to="/"
        className="DesktopNavigation__item"
        exact
        activeClassName="DesktopNavigation__item--active"
      >
        Etusivu
      </NavLink>
      <NavLink
        to="/locations"
        isActive={() => locationsActive}
        className="DesktopNavigation__item"
        activeClassName="DesktopNavigation__item--active"
      >
        Kohteet
      </NavLink>
      <NavLink
        to="/invoicing"
        className="DesktopNavigation__item"
        activeClassName="DesktopNavigation__item--active"
      >
        Laskutus
        <DesktopInvoiceIndicatorCounter />
      </NavLink>
      <NavLink
        to="/users"
        className="DesktopNavigation__item"
        activeClassName="DesktopNavigation__item--active"
      >
        Käyttäjä{userStore.user?.admin ? 't' : ''}
      </NavLink>
      <NavLink
        to="/items"
        className="DesktopNavigation__item"
        activeClassName="DesktopNavigation__item--active"
      >
        Laitteet
      </NavLink>
      {itemsActive ? (
        <div className="DesktopNavigation__submenu">
          <NavLink
            to="/items/utilization"
            className="submenu-item"
            activeClassName="Submenu-item--active"
          >
            Käyttöaste
          </NavLink>
          <NavLink
            to="/items/types"
            className="submenu-item"
            activeClassName="Submenu-item--active"
          >
            Laitetyypit
          </NavLink>
        </div>
      ) : null}
    </nav>
  )
})

export default DesktopNavigation

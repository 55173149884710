import React, { useState } from 'react'
import { values } from 'mobx'
import { useHistory } from 'react-router-dom'
import { useStores } from '../../../../hooks/use-stores'
import { requestState } from '../../../../helpers/requestState'
import { ItemSpecsTypes } from '../../../../types/ItemSpecsTypes'
import {
  notifyError,
  notifySuccess,
} from '../../../../helpers/notificationHelpers'
import { SelectInput } from '../../../SelectInput'
import DesktopLinkButton from '../../DesktopLinkButton'
import DesktopFormButton from '../../DesktopFormButton'

const DesktopCreateSubcontractorItemForm = () => {
  const history = useHistory()

  const { itemTypeStore, itemStore } = useStores()
  const { createItem, subcontractorItemsByType } = itemStore
  const [itemType, setItemType] = useState()

  const handleSelect = (selectedOption) => {
    setItemType(selectedOption.type)
  }

  const types = values(itemTypeStore.item_types).map((type) => ({
    value: type.name,
    label: type.name,
    type,
  }))

  const checkDuplicate = (itemType) => {
    const existingSubcontractorItems = subcontractorItemsByType(itemType)
    return existingSubcontractorItems.length
  }

  const sendItem = async (event) => {
    event.preventDefault()

    if (!itemType) {
      return notifyError('Laitetta ei lisätty: lisää laitetyyppi')
    }

    if (checkDuplicate(itemType.id)) {
      return notifyError(
        'Laitetta ei lisätty: vastaava alihankkijan laite on jo luotu'
      )
    }

    // Check again what options to use for these property values:
    const item = {
      is_subcontractor: true,
      item_type_id: itemType.id,
    }

    await createItem(item)

    if (itemStore.itemState === requestState.CREATED) {
      notifySuccess(`Alihankkijan laite ${itemType.name} lisätty`)
      return history.push('/items')
    } else {
      return notifyError(
        `Alihankkijan laitetta ${itemType.name} ei voitu lisätä`
      )
    }
  }

  return (
    <form className="DesktopCreateSubcontractorItemForm" onSubmit={sendItem}>
      <div className="DesktopCreateSubcontractorItemForm__fields">
        <SelectInput
          name={`${ItemSpecsTypes.item_type}:`}
          options={types}
          handleSelect={handleSelect}
        />
        <div className="DesktopCreateItemForm__buttons">
          <DesktopFormButton title="Luo laite" />
          <DesktopLinkButton title="Peruuta" path="/items" />
        </div>
      </div>
    </form>
  )
}

export default DesktopCreateSubcontractorItemForm

export const WarehouseTypes = {
  HELSINKI: 'Helsinki',
  TURKU: 'Turku',
}

export const warehouseShortName = {
  Helsinki: 'Hki',
  Turku: 'Tku',
}

export const warehouseOptionsSelect = [
  { value: 'Helsinki', label: 'Helsinki' },
  { value: 'Turku', label: 'Turku' },
]

import React from 'react'
import PropTypes from 'prop-types'

const DesktopFormButton = (props) => {
  return (
    <button
      className={`DesktopFormButton DesktopFormButton--${props.class}`}
      disabled={props.disabled}
    >
      {props.title}
    </button>
  )
}

DesktopFormButton.propTypes = {
  class: PropTypes.string,
  title: PropTypes.string,
  disabled: PropTypes.bool,
}

export default DesktopFormButton

export const ItemStatusTypes = {
  RESERVED: {
    description: 'Varattu',
    id: 1,
  },
  PICKUP: {
    description: 'Vapaa',
    id: 2,
  },
  IN_WAREHOUSE: {
    description: 'Varastossa',
    id: 3,
  },
  SUBCONTRACTOR: {
    description: 'alihankkija',
  },
}

import React from 'react'
import DesktopPageHeading from '../DesktopPageHeading'
import DesktopLinkButton from '../DesktopLinkButton'
import DesktopItemTable from '../DesktopItemTable'

const DesktopItemPage = () => {
  return (
    <div className="DesktopItemPage">
      <DesktopPageHeading
        title="Laitteet"
        subtitle="Hallitse ja lisää laitteita"
      />
      <DesktopLinkButton title="Luo laite" path="/items/add" />
      <DesktopItemTable />
    </div>
  )
}

export default DesktopItemPage

import React from 'react'
import PropTypes from 'prop-types'
import DesktopPageHeading from '../DesktopPageHeading'
import DesktopCreateItemForm from '../DesktopCreateItemForm'
import { useHistory } from 'react-router-dom'
import { requestState } from '../../../helpers/requestState'
import {
  notifyError,
  notifySuccess,
} from '../../../helpers/notificationHelpers'
import { useStores } from '../../../hooks/use-stores'

const DesktopSingleItemEditPage = (props) => {
  const history = useHistory()

  const { itemStore } = useStores()
  const { patchItem } = itemStore

  const updateItem = async (item) => {
    await patchItem(item, props.item.id)

    if (itemStore.itemState === requestState.UPDATED) {
      notifySuccess(`Laite ${item.item_model} päivitetty`)
      return history.push('/items')
    } else {
      return notifyError(`Laitetta ${item.item_model} ei voitu päivittää`)
    }
  }

  return (
    <div className="DesktopSingleItemEditPage">
      <DesktopPageHeading
        title={props.item.item_type_id.name}
        subtitle="Muokkaa laitetta"
      />
      <DesktopCreateItemForm
        title="Päivitä laite"
        item={props.item}
        action={updateItem}
      />
    </div>
  )
}

DesktopSingleItemEditPage.propTypes = {
  item: PropTypes.object,
}

export default DesktopSingleItemEditPage

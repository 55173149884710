import React from 'react'
import PropTypes from 'prop-types'

const DesktopDateInput = (props) => {
  return (
    <div className="DesktopDateInput">
      <label className="DesktopDateInput__label" htmlFor={props.name}>
        {props.name}
      </label>
      <input
        className="DesktopDateInput__input"
        type="date"
        placeholder="vvvv-kk-pp"
        max={props.max}
        min={props.min}
        id={props.name}
        value={props.value}
        onChange={(e) => props.eventHandler(e.target.value)}
      />
    </div>
  )
}

DesktopDateInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  max: PropTypes.string,
  min: PropTypes.string,
  eventHandler: PropTypes.func,
}

export default DesktopDateInput

import React from 'react'
import PropTypes from 'prop-types'

const MobileSmallButton = (props) => {
  return (
    <button
      className="MobileSmallButton"
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.children}
    </button>
  )
}

MobileSmallButton.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
}

export default MobileSmallButton

import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { requestState } from '../../../../helpers/requestState'
import {
  notifySuccess,
  notifyError,
} from '../../../../helpers/notificationHelpers'
import DesktopFormButton from '../../DesktopFormButton'
import DesktopSearchInput from '../../DesktopSearchInput'
import { useStores } from '../../../../hooks/use-stores'
import { createConfirmationPrompt } from '../../../../helpers/confirmationPrompt'

const DesktopAdminItemTypeCreate = observer(() => {
  const { itemTypeStore } = useStores()
  const { createItemType } = itemTypeStore
  const [name, setName] = useState('')

  const confirmItemType = (event) => {
    event.preventDefault()

    createConfirmationPrompt(
      'Luo uusi laitetyyppi',
      ['Oletko varma, että haluat luoda uuden laitetyypin:', name],
      'Luo',
      () => sendItemType(),
      'Peruuta'
    )
  }

  const sendItemType = async () => {
    if (!name) {
      return notifyError('Laitetyyppiä ei lisätty: lisää laitetyypin nimi')
    }

    const itemType = {
      name: name,
    }
    await createItemType(itemType)

    if (itemTypeStore.itemTypeState === requestState.CREATED) {
      notifySuccess(`Laitetyyppi ${name} lisätty`)
    } else {
      notifyError(`Laitetyyppi ${name} ei voitu lisätä`)
    }

    setName('')
  }

  return (
    <form className="DesktopAdminUserCreate" onSubmit={confirmItemType}>
      <div className="DesktopAdminUserCreate DesktopAdminUserCreate__create">
        <h3 className="DesktopAdminUserCreate__title">Luo uusi laitetyyppi</h3>
        <DesktopSearchInput
          name="Nimi"
          eventHandler={setName}
          value={name}
          type="text"
          required={true}
        />
        <DesktopFormButton title="Luo uusi laitetyyppi" disabled={!name} />
      </div>
    </form>
  )
})

export default DesktopAdminItemTypeCreate

import { ItemStatusTypes } from '../types/ItemStatusTypes'
import { WorkStatusIndicatorTypes } from '../types/WorkStatusIndicatorTypes'
import { WorkStatusTypes } from '../types/WorkStatusTypes'

export const workStateIndicator = (work, itemStore) => {
  const hasPickups =
    itemStore.itemCountByStatusInWork(work.id, ItemStatusTypes.PICKUP.id) > 0
  const isActive = work.work_status_id.id === WorkStatusTypes.ACTIVE.id
  return isActive
    ? hasPickups
      ? WorkStatusIndicatorTypes.INCOMPLETE_PICKUP
      : WorkStatusIndicatorTypes.INCOMPLETE
    : hasPickups
    ? WorkStatusIndicatorTypes.COMPLETE_PICKUP
    : WorkStatusIndicatorTypes.COMPLETE
}

import React from 'react'
import PropTypes from 'prop-types'
import DesktopPageHeading from '../../DesktopPageHeading'
import DesktopEditLocationForm from './DesktopEditLocationForm'

const DesktopLocationEditPage = (props) => {
  return (
    <div className="DesktopLocationEditPage">
      <DesktopPageHeading
        title={`${props.location.address} - ${props.location.name} `}
        subtitle="Muokkaa kohdetta"
      />
      <DesktopEditLocationForm location={props.location} />
    </div>
  )
}

DesktopLocationEditPage.propTypes = {
  location: PropTypes.object,
}

export default DesktopLocationEditPage

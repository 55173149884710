import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { ItemActionCheckBoxTypes } from '../../../../types/ItemActionCheckBoxTypes'
import { ItemStatusTypes } from '../../../../types/ItemStatusTypes'
import { TableCellContentTypes } from '../../../../types/TableCellContentTypes'
import { compareByMultiplePropNames } from '../../../../utils/sortUtils'
import { findValue } from '../../../../helpers/filterHelpers'
import { useStores } from '../../../../hooks/use-stores'
import DesktopSearchInput from '../../DesktopSearchInput'
import DesktopCheckboxContainer from '../../DesktopCheckboxContainer'
import DesktopSingleWorkItemTableRow from './DesktopSingleWorkItemTableRow'
import DesktopNoResultsMessage from '../../DesktopNoResultMessage'
import DesktopBaseTable from '../../DesktopBaseTable'

const DesktopSingleWorkItemTable = observer((props) => {
  const { itemActionStore } = useStores()
  const {
    itemActionsByWork,
    subcontractorItemActionsByWork,
    itemActionsCountByWork,
    openItemActionsByWork,
    openItemActionsCountByWork,
    closedItemActionsByWork,
    closedItemActionsCountByWork,
  } = itemActionStore

  const [itemSearchWord, setItemSearchWord] = useState('')

  const allItems = (searchWord) => {
    const filteredArray = itemActionsByWork(props.work.id).filter((action) =>
      findValue(action.item_id.item_type_id.name, searchWord)
    )
    return filteredArray
  }

  const reservedItems = (searchWord) => {
    const filteredArray = openItemActionsByWork(
      props.work.id
    ).filter((action) =>
      findValue(action.item_id.item_type_id.name, searchWord)
    )
    return filteredArray
  }

  const closedItems = (searchWord) => {
    const filteredArray = closedItemActionsByWork(
      props.work.id
    ).filter((action) =>
      findValue(action.item_id.item_type_id.name, searchWord)
    )
    return filteredArray
  }

  const subcontractorItems = (searchWord) => {
    const filteredArray = subcontractorItemActionsByWork(
      props.work.id
    ).filter((action) =>
      findValue(action.item_id.item_type_id.name, searchWord)
    )
    return filteredArray
  }

  const [arrayToFilter, setArrayToFilter] = useState(() => allItems)

  const sortBy = [
    {
      prop: 'date_end',
      direction: 1,
    },
    {
      prop: 'date_start',
      direction: 1,
    },
  ]

  const checkBoxItems = [
    {
      name: ItemActionCheckBoxTypes.ALL,
      count: itemActionsCountByWork(props.work.id),
      action: () => setArrayToFilter(() => allItems),
    },
    {
      name: ItemActionCheckBoxTypes.RESERVED,
      count: openItemActionsCountByWork(props.work.id),
      action: () => setArrayToFilter(() => reservedItems),
    },
    {
      name: ItemActionCheckBoxTypes.PREVIOUS,
      count: closedItemActionsCountByWork(props.work.id),
      action: () => setArrayToFilter(() => closedItems),
    },
    {
      name: ItemActionCheckBoxTypes.SUBCONTRACTOR,
      count: subcontractorItemActionsByWork(props.work.id).length,
      action: () => setArrayToFilter(() => subcontractorItems),
    },
  ]

  return (
    <div className="DesktopSingleWorkItemTable">
      <div className="DesktopSingleWorkItemTable__search">
        <DesktopSearchInput
          name="Hae laitteen nimellä"
          type="text"
          eventHandler={setItemSearchWord}
          value={itemSearchWord}
          required={false}
        />
        <DesktopCheckboxContainer
          labels_and_actions={checkBoxItems}
          radio_group="actions_by_status"
          checked={ItemActionCheckBoxTypes.ALL}
        />
      </div>
      {arrayToFilter(itemSearchWord).length ? (
        <DesktopBaseTable
          columns={['Laite', 'Numero', 'Omistaja', 'Aloitus', 'Lopetus', '']}
        >
          {arrayToFilter(itemSearchWord)
            .sort(compareByMultiplePropNames(sortBy))
            .map((action) => (
              <DesktopSingleWorkItemTableRow
                key={action.id}
                item_action={action.id}
                item={action.item_id.item_type_id.name}
                owner={
                  !action.item_id.is_subcontractor
                    ? TableCellContentTypes.OWNER.vertia
                    : TableCellContentTypes.OWNER.subcontractor
                }
                item_id={action.item_id.id}
                item_number={
                  !action.item_id.is_subcontractor
                    ? action.item_id.item_number
                    : ItemStatusTypes.SUBCONTRACTOR.description
                }
                work_id={props.work.id}
                date_start={action.date_start}
                date_end={action.date_end}
              />
            ))}
        </DesktopBaseTable>
      ) : (
        <DesktopNoResultsMessage
          message={`Laitetapahtumia ei löydetty hakuehdoilla ${itemSearchWord}`}
        />
      )}
    </div>
  )
})

DesktopSingleWorkItemTable.propTypes = {
  work: PropTypes.object,
}

export default DesktopSingleWorkItemTable

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import DesktopSearchInput from './DesktopSearchInput'
import { useStores } from '../../hooks/use-stores'
import { requestState } from '../../helpers/requestState'
import { notifyError, notifySuccess } from '../../helpers/notificationHelpers'

const DesktopNewWorkPrompt = (props) => {
  const { workStore } = useStores()
  const { createWork } = workStore

  const [workName, setWorkName] = useState('')

  const sendWork = async (event) => {
    event.preventDefault()

    if (!workName) {
      return notifyError('Työtä ei lisätty: lisää tarkenne')
    }

    const work = {
      location_id: props.location,
      work_status_id: 1,
      name: workName,
    }
    const newWorkId = await createWork(work)

    if (workStore.workState === requestState.CREATED) {
      notifySuccess(`Työ ${workName} lisätty`)
      setWorkName('')
      props.onClose(newWorkId)
    } else {
      return notifyError(`Työtä ${workName} ei voitu lisätä`)
    }
  }

  return (
    <div className="DesktopNewWorkPrompt">
      <h1 className="DesktopNewWorkPrompt__title">{props.title}</h1>
      <p className="DesktopNewWorkPrompt__body">{props.message}</p>
      <form className="DesktopNewWorkPrompt__form" onSubmit={sendWork}>
        <DesktopSearchInput
          name="Tarkenne:"
          type="text"
          eventHandler={setWorkName}
          value={workName}
          required={true}
        />
      </form>
      <div className="DesktopNewWorkPrompt__buttons">
        <button
          className="DesktopPromptButton DesktopPromptButton--positive"
          disabled={!workName}
          type="submit"
          onClick={sendWork}
        >
          Lisää
        </button>
        <button
          className="DesktopPromptButton DesktopPromptButton--negative"
          onClick={() => props.onClose(null)}
        >
          Peruuta
        </button>
      </div>
    </div>
  )
}

DesktopNewWorkPrompt.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  location: PropTypes.number,
  user: PropTypes.number,
  onClose: PropTypes.func,
}

export default DesktopNewWorkPrompt

import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useStores } from '../../../../hooks/use-stores'
import { ItemStatusTypes } from '../../../../types/ItemStatusTypes'
import { TableCellContentTypes } from '../../../../types/TableCellContentTypes'
import { ReleaseButtonTypes } from '../../../../types/ReleaseButtonTypes'
import { requestState } from '../../../../helpers/requestState'
import {
  notifyError,
  checkSuccess,
} from '../../../../helpers/notificationHelpers'
import { Row, Cell } from 'react-sticky-table'
import DesktopTableReleaseButton from '../../DesktopTableReleaseButton'
import { createConfirmationPrompt } from '../../../../helpers/confirmationPrompt'
import {
  createHandlePrompt,
  createScanoutPrompt,
} from '../../../../helpers/adminHandlePrompt'

const DesktopSingleWorkItemTableRow = (props) => {
  const { itemActionStore } = useStores()
  const { adminRelease, adminScanout, scanOutSubcontractor } = itemActionStore
  const today = moment(Date.now()).format('yyyy-MM-DD')
  const disabled = props.date_end ? true : false
  const isSubcontractor =
    props.owner === TableCellContentTypes.OWNER.subcontractor ? true : false

  const items = [
    {
      item_id: props.item_id,
      work_entry_id: props.work_id,
    },
  ]

  const subContractorItems = {
    items: [{ item_action_id: props.item_action }],
    date_end: today,
  }

  const validateCommon = () => {
    if (props.date_start > today) {
      return notifyError('Lopetuspäivä ei voi olla ennen aloituspäivää')
    }

    return true
  }

  const releaseItem = async () => {
    if (!validateCommon()) {
      return
    }

    await adminRelease({ items })
    const isSuccess = itemActionStore.itemActionState === requestState.UPDATED
    return checkSuccess(
      isSuccess,
      'Laite merkitty noudettavaksi',
      'Laitetta ei voitu merkitä noudettavaksi'
    )
  }

  const confirmReleaseItem = () => {
    if (!validateCommon()) {
      return
    }

    // Timeouts handle the synchronous prompt action
    setTimeout(() => {
      createConfirmationPrompt(
        'Merkitse noudettavaksi',
        `${props.item} -laite`,
        'Kyllä',
        () => releaseItem(),
        'Peruuta'
      )
    })
  }

  const confirmScanoutItem = () => {
    if (!validateCommon()) {
      return
    }

    // Timeouts handle the synchronous prompt action
    setTimeout(() => {
      createScanoutPrompt(
        'Merkitse varastoon',
        `${props.item} -laite`,
        'Kyllä',
        (adminEndDate) => scanoutItem(adminEndDate),
        'Peruuta',
        props.date_start
      )
    })
  }

  const confirmHandleItem = () => {
    createHandlePrompt(
      'Merkitse noudettavaksi',
      `${props.item} -laite`,
      'Merkitse noudettavaksi',
      () => confirmReleaseItem(),
      'Määrittele lopetuspäivä',
      () => confirmScanoutItem()
    )
    return
  }

  // adminScanout sets item to warehouse state.
  // adminRelease sets item to released state to be retrieved.
  const scanoutItem = async (date_end) => {
    if (!validateCommon()) {
      return
    }

    await adminScanout({ items }, date_end)
    const isSuccess = itemActionStore.itemActionState === requestState.UPDATED
    return checkSuccess(
      isSuccess,
      'Laite käsitelty',
      'Laitetta ei voitu merkitä vapautetuksi'
    )
  }

  const scanOutSubcontractorItem = async () => {
    if (!validateCommon()) {
      return
    }

    await scanOutSubcontractor(subContractorItems)
    const isSuccess = itemActionStore.itemActionState === requestState.UPDATED
    return checkSuccess(
      isSuccess,
      'Alihankkijan laite poistettu kohteesta',
      'Alihankkijan laitetta ei voitu poistaa'
    )
  }

  const confirmScanOutSubcontractorItem = () => {
    if (!validateCommon()) {
      return
    }

    createConfirmationPrompt(
      'Merkitse varastoon',
      `${props.item} -alihankkijalaite`,
      'Kyllä',
      () => scanOutSubcontractorItem(),
      'Peruuta'
    )
  }

  return (
    <Row>
      <Cell>{props.item}</Cell>
      <Cell className="cell-collapse cell-style__monospace">
        {props.item_number}
      </Cell>
      <Cell className="cell-collapse">{props.owner}</Cell>
      <Cell>{moment(props.date_start).format('D.M.Y')}</Cell>
      <Cell>
        {props.date_end ? moment(props.date_end).format('D.M.Y') : ''}
      </Cell>
      <Cell className="cell-collapse">
        {isSubcontractor ? (
          <DesktopTableReleaseButton
            action={confirmScanOutSubcontractorItem}
            item_status={ItemStatusTypes.IN_WAREHOUSE.id}
            text={ReleaseButtonTypes.SCAN_OUT}
            disabled={disabled}
          />
        ) : (
          <DesktopTableReleaseButton
            action={confirmHandleItem}
            item_status={ItemStatusTypes.PICKUP.id}
            text={ReleaseButtonTypes.ADMIN_HANDLE}
            disabled={disabled}
          />
        )}
      </Cell>
    </Row>
  )
}

DesktopSingleWorkItemTableRow.propTypes = {
  item: PropTypes.string,
  item_action: PropTypes.number,
  owner: PropTypes.string,
  item_id: PropTypes.number,
  work_id: PropTypes.number,
  date_start: PropTypes.string,
  date_end: PropTypes.string,
}

export default DesktopSingleWorkItemTableRow

import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { useStores } from '../../../../hooks/use-stores'
import { findValue } from '../../../../helpers/filterHelpers'
import { WorkStatusTypes } from '../../../../types/WorkStatusTypes'
import { ItemStatusTypes } from '../../../../types/ItemStatusTypes'
import { LocationCheckBoxTypes } from '../../../../types/LocationCheckBoxTypes'
import { compareByPropName } from '../../../../utils/sortUtils'
import DesktopSearchInput from '../../DesktopSearchInput'
import DesktopCheckboxContainer from '../../DesktopCheckboxContainer'
import DesktopNoResultsMessage from '../../DesktopNoResultMessage'
import DesktopBaseTable from '../../DesktopBaseTable'
import DesktopTableArchiveCell from '../../DesktopTableArchiveCell'
import { Row, Cell } from 'react-sticky-table'
import { Link } from 'react-router-dom'
import DesktopSingleLocationWorkTableStatusCell from './DesktopSingleLocationWorkTableStatusCell'
import { WorkStatusIndicatorTypes } from '../../../../types/WorkStatusIndicatorTypes'

const DesktopSingleLocationWorkTable = observer((props) => {
  const { workStore, itemStore } = useStores()
  const { worksByStatusInLocation, worksByLocation } = workStore
  const { itemCountByStatusInWork, worksByItemStatus } = itemStore
  const currentLocation = props.location
  const worksInLocation = worksByLocation(currentLocation.id)
  const [workSearchWord, setWorkSearchWord] = useState('')

  // 2nd parameter in worksByLocation is false by default
  const allWorks = (searchWord) => {
    const filteredArray = worksByLocation(
      currentLocation.id,
      false
    ).filter((work) => findValue(work.name, searchWord))
    return filteredArray.sort(compareByPropName('name'))
  }

  const allWorksCount = worksByLocation(currentLocation.id, false).length

  // 2nd parameter in worksByLocation is false by default
  const archivedWorks = (searchWord) => {
    const filteredArray = worksByLocation(
      currentLocation.id,
      true
    ).filter((work) => findValue(work.name, searchWord))
    return filteredArray.sort(compareByPropName('name'))
  }

  const archivedWorksCount = worksByLocation(currentLocation.id, true).length

  // 3rd parameter in worksByStatusInLocation is false by default
  const activeWorks = (searchWord) => {
    const filteredArray = worksByStatusInLocation(
      currentLocation.id,
      WorkStatusTypes.ACTIVE.id,
      false
    ).filter((work) => findValue(work.name, searchWord))
    return filteredArray.sort(compareByPropName('name'))
  }

  const activeWorkCount = worksByStatusInLocation(
    currentLocation.id,
    WorkStatusTypes.ACTIVE.id,
    false
  ).length

  // 3rd parameter in worksByStatusInLocation is false by default
  const inActiveWorks = (searchWord) => {
    const filteredArray = worksByStatusInLocation(
      currentLocation.id,
      WorkStatusTypes.INACTIVE.id,
      false
    ).filter((work) => findValue(work.name, searchWord))
    return filteredArray.sort(compareByPropName('name'))
  }

  const inActiveWorkCount = worksByStatusInLocation(
    currentLocation.id,
    WorkStatusTypes.INACTIVE.id,
    false
  ).length

  // 3rd parameter in worksByItemStatus is false by default
  const pickUpWorks = (searchWord) => {
    const filteredArray = worksByItemStatus(
      worksInLocation,
      ItemStatusTypes.PICKUP.id,
      false
    ).filter((work) => findValue(work.name, searchWord))
    return filteredArray.sort(compareByPropName('name'))
  }

  const pickUpWorksCount = worksByItemStatus(
    worksInLocation,
    ItemStatusTypes.PICKUP.id,
    false
  ).length

  const [arrayToFilter, setArrayToFilter] = useState(() => allWorks)

  const checkBoxItems = [
    {
      name: WorkStatusIndicatorTypes.INCOMPLETE.text,
      count: activeWorkCount,
      action: () => setArrayToFilter(() => activeWorks),
    },
    {
      name: WorkStatusIndicatorTypes.COMPLETE.text,
      count: inActiveWorkCount,
      action: () => setArrayToFilter(() => inActiveWorks),
    },
    {
      name: LocationCheckBoxTypes.ALL,
      count: allWorksCount,
      action: () => setArrayToFilter(() => allWorks),
    },
    {
      name: LocationCheckBoxTypes.PICKUP,
      count: pickUpWorksCount,
      action: () => setArrayToFilter(() => pickUpWorks),
    },
    {
      name: LocationCheckBoxTypes.ARCHIVED,
      count: archivedWorksCount,
      action: () => setArrayToFilter(() => archivedWorks),
    },
  ]

  return (
    <div className="DesktopSingleLocationWorkTable">
      <div className="DesktopSingleLocationWorkTable__search">
        <DesktopSearchInput
          name="Haen työn nimellä"
          type="text"
          eventHandler={setWorkSearchWord}
          value={workSearchWord}
          required={false}
        />
        <DesktopCheckboxContainer
          labels_and_actions={checkBoxItems}
          checked={LocationCheckBoxTypes.ALL}
          radio_group="works_by_status"
        />
      </div>

      {arrayToFilter(workSearchWord).length ? (
        <DesktopBaseTable
          columns={['Työ', 'Tila', 'Varatut laitteet', 'Noudettavat laitteet']}
        >
          {arrayToFilter(workSearchWord).map((work) => (
            <Row key={work.id}>
              <Cell>
                <Link to={`/works/${work.id}`}>{work.name}</Link>
              </Cell>
              {!work.archived ? (
                <DesktopSingleLocationWorkTableStatusCell work={work} />
              ) : (
                <DesktopTableArchiveCell text="Arkistoitu" />
              )}
              <Cell className="cell-collapse">
                {itemCountByStatusInWork(work.id, ItemStatusTypes.RESERVED.id)}
              </Cell>
              <Cell className="cell-collapse">
                {itemCountByStatusInWork(work.id, ItemStatusTypes.PICKUP.id)}
              </Cell>
            </Row>
          ))}
        </DesktopBaseTable>
      ) : (
        <DesktopNoResultsMessage
          message={`Töitä ei löydetty hakuehdoilla ${workSearchWord}`}
        />
      )}
    </div>
  )
})

export default DesktopSingleLocationWorkTable

import { types } from 'mobx-state-tree'
import { User } from './User'
import { Work } from './Work'
import { ItemType } from './ItemType'
import { ItemStatus } from './ItemStatus'

export const Item = types.model('Item', {
  id: types.identifierNumber,
  is_subcontractor: types.boolean,
  item_number: types.string,
  item_serial: types.string,
  item_model: types.string,
  archived: types.maybeNull(types.string),
  buy_date: types.maybeNull(types.string),
  buy_price: types.maybeNull(types.string),
  user_id: types.reference(types.late(() => User)),
  item_type_id: types.reference(types.late(() => ItemType)),
  item_status_id: types.reference(types.late(() => ItemStatus)),
  work_entry_id: types.union(
    types.maybeNull(types.reference(types.late(() => Work))),
    types.array(types.reference(types.late(() => Work)))
  ),
  power_consume: types.string,
  power_source: types.number,
  warehouse: types.string,
})

import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import { useStores } from '../../hooks/use-stores'
import { requestState } from '../../helpers/requestState'
import { notifyError } from '../../helpers/notificationHelpers'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import MobileHeader from './MobileHeader'
import MobileHome from './MobileHome/MobileHome'
import MobileScanInForm from './MobileScanInForm'
import MobileScanOutForm from './MobileScanOutForm'
import LoadingPage from '../LoadingPage'

export const MobileContainer = observer(() => {
  const [isLoading, setIsLoading] = useState(true)
  const [loadingText, setLoadingText] = useState('Ladataan...')
  const {
    locationStore,
    workStore,
    workStatusStore,
    itemStore,
    itemTypeStore,
    itemStatusStore,
    userStore,
  } = useStores()

  useEffect(() => {
    const { fetchLocations } = locationStore
    const { fetchWorks } = workStore
    const { fetchWorkStatuses } = workStatusStore
    const { fetchItems } = itemStore
    const { fetchItemTypes } = itemTypeStore
    const { fetchItemStatuses } = itemStatusStore

    const initializeItems = async () => {
      if (itemStore.itemState === requestState.NONE) {
        setLoadingText('Ladataan laitteita...')
        await fetchItems()
        setLoadingText('Ladataan laitteita, valmis')
      }

      if (itemStore.itemState === requestState.ERROR) {
        notifyError('Laitteita ei voitu ladata')
      }
    }

    const initializeItemTypes = async () => {
      if (itemTypeStore.itemTypeState === requestState.NONE) {
        await fetchItemTypes()
        setLoadingText('Ladataan laitetyyppejä, valmis')
      }

      if (itemTypeStore.itemTypeState === requestState.ERROR) {
        notifyError('Laitetyyppejä ei voitu ladata')
      }
    }

    const initializeItemStatuses = async () => {
      if (itemStatusStore.itemStatusState === requestState.NONE) {
        await fetchItemStatuses()
        setLoadingText('Ladataan laitteiden tiloja, valmis')
      }

      if (itemStatusStore.itemStatusState === requestState.ERROR) {
        notifyError('Laitteiden tiloja ei voitu ladata')
      }
    }

    const initializeLocations = async () => {
      if (locationStore.locationState === requestState.NONE) {
        await fetchLocations()
        setLoadingText('Ladataan kohteita, valmis')
      }

      if (locationStore.locationState === requestState.ERROR) {
        notifyError('Kohteita ei voitu ladata')
      }
    }

    const initializeWorks = async () => {
      if (workStore.workState === requestState.NONE) {
        await fetchWorks()
        setLoadingText('Ladataan töitä, valmis')
      }

      if (workStore.workState === requestState.ERROR) {
        notifyError('Töitä ei voitu ladata')
      }
    }

    const initializeWorkStatuses = async () => {
      if (workStatusStore.statusState === requestState.NONE) {
        await fetchWorkStatuses()
        setLoadingText('Ladataan töiden statuksia, valmis')
      }

      if (workStatusStore.statusState === requestState.ERROR) {
        notifyError('Töiden statuksia ei voitu ladata')
      }
    }

    const initializeUsers = async () => {
      if (userStore.userState === requestState.NONE) {
        if (userStore.user.admin) {
          await userStore.fetchUsers()
        } else {
          userStore.populateUsersWithSelfOnly()
        }
      }

      if (userStore.userState === requestState.ERROR) {
        notifyError('Käyttäjiä ei voitu ladata')
      }
    }

    const fetchData = async () => {
      await Promise.all([
        initializeItemTypes(),
        initializeItemStatuses(),
        initializeLocations(),
        initializeWorks(),
        initializeWorkStatuses(),
      ])
      await initializeItems()
      await initializeUsers()
      setIsLoading(false)
    }
    fetchData()
  }, [
    locationStore,
    workStatusStore,
    workStore,
    itemStore,
    itemTypeStore,
    itemStatusStore,
    userStore,
  ])

  return (
    <>
      {isLoading ? (
        <LoadingPage title={loadingText} loading={isLoading} />
      ) : (
        <div className="MobileContainer">
          <Router>
            <MobileHeader />
            <Switch>
              <Route exact path="/" component={MobileHome}></Route>
              <Route
                exact
                path="/scan-in/"
                component={MobileScanInForm}
              ></Route>
              <Route
                exact
                path="/scan-out/"
                component={MobileScanOutForm}
              ></Route>
            </Switch>
          </Router>
        </div>
      )}
    </>
  )
})
